import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { GetUserRoleList } from "../../redux/action/RoleandPermissionAction";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllNotifications,
  SendNotifications,
} from "../../redux/action/NotificationActions";
import { IconButton, Typography } from "@mui/material";
import { CloseIcon } from "../../assets/icons/Icons";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SendNotification = ({ setOpenSend, openSend, selectedrole }) => {
  const dispatch = useDispatch();

  const [selectedRoles, setSelectedRoles] = useState([]);

  const handleClose = () => {
    setOpenSend(false);
  };

  const roles = useSelector(
    (state) => state?.RoleandPermissionReducer?.roleList || []
  );

  useEffect(() => {
    dispatch(GetUserRoleList());
  }, [dispatch]);

  const handleNotificationSend = async () => {
    const roleIds = selectedRoles.map((role) => role._id);

    const data = {
      role_id: roleIds,
      notification_id: selectedrole?._id,
    };

    dispatch(SendNotifications(data));
    dispatch(GetAllNotifications("", ""));
    setSelectedRoles([]);
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={openSend}
        onClose={false}
        disableBackdropClick={true} // Prevent closing when clicking outside
        disableEscapeKeyDown={true}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ color: "#000 !important" }}>
            Notification
          </Typography>

          <IconButton sx={{
            color: "black", "&:hover": {
              backgroundColor: "white",
              color: "#153e80"
            }, "&:focus": {
              backgroundColor: "white",
              color: "#153e80",
              border: "none"
            }
          }}>
            <CloseIcon onClick={handleClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={roles?.roleData}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.role}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.role}
              </li>
            )}
            style={{ width: 500 }}
            value={selectedRoles}
            onChange={(event, newValue) => {
              setSelectedRoles(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Roles"
                placeholder="Select Roles"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleNotificationSend}
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              border: "0",
              ":hover": { bgcolor: "#1976d2", border: "0" },
              backgroundColor: "#153e80",
              marginLeft: {
                xs: "0px",
                sm: "15px",
                md: "15px",
                lg: "15px",
              },
              color: "#fff",
              textTransform: "none",
              minWidth: "100px",
            }}
          >
            Send
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              border: "0",
              ":hover": { bgcolor: "#1976d2", border: "0" },
              backgroundColor: "#gray",
              marginLeft: {
                xs: "0px",
                sm: "15px",
                md: "15px",
                lg: "15px",
              },
              color: "black",
              textTransform: "none",
              minWidth: "100px",
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SendNotification;
