import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Link as MuiLink,
} from "@mui/material";

const BaseBlogCard = ({ item }) => {
  const isVideo = (url) => {
    const videoExtensions = ["mp4", "webm", "ogg"];
    const extension = url.split(".").pop();
    return videoExtensions.includes(extension);
  };

  const removeHtmlTags = (str) => {
    if (!str) return "";
    return str.replace(/<[^>]*>/g, "");
  };

  function subStr(txt, len) {
    let acceptedText =
      txt.length < len ? txt : txt.substring(0, len - 1) + "...";
    return acceptedText;
  }

  return (
    // <Box sx={{ flex: "0 0 auto", width: {xs:"100%",sm:"33%"}, p: 1, boxShadow: 'none' }} >
      <div className="blogCss">
        
        {/*IMAGE*/}
        <Link
          // component={Link}
          to={`/blog/` + item?._id}
          // sx={{ textDecoration: "none", border: '1px solid', maxHeight: '250px', maxWeight: '490px', objectFit: 'contain' }}
        >
          {isVideo(item?.blog_images[0]) ? (
            <CardMedia
              component="video"
              src={item?.blog_images[0]}
              sx={{ height: 300, objectFit: "cover" }}
            />
          ) : (
            <>
                {/* // <CardMedia */}
              {/* //   component="img"
              //   image={item?.blog_images[0]}
              //   alt="product 1"
              //   sx={{ height: 300, objectFit: "cover" }}
              // /> */}
              <div className="serviceImg mt-07">
                <img src={item?.blog_images[0]} className="serImg allImg" style={{objectFit: 'cover'}} />
              </div>
            </>
          )}
        </Link>

        {/*DESCRIPTION*/}
        <div className="px-1 mb-08">
            <Link
              // component={Link}
              to={`/blog/` + item?._id}
              // sx={{ textDecoration: "none", color: "inherit" }}
              style={{textDecoration: 'none'}}
            >
              <h4 className="w-9 text-000 pointer mt-08" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{item?.title}</h4>
            </Link>
            
          <Typography
            variant="body2"
            sx={{ fontSize: 16, letterSpacing: "0.025em", mb: 1, height: '70px', overflow: 'hidden', }}
          >
            {subStr(removeHtmlTags(item?.description), 150)}
          </Typography>
          
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {moment(item?.createdAt).format("MMMM Do YYYY")} by
            </Typography>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {item?.user_name}
            </Typography>
          </Box>
        </div>
      </div>
    // </Box>
  );
};

export default BaseBlogCard;
