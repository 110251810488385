import React from "react";
import Header from "../header/Header";
import { Outlet } from "react-router-dom";
import { MyProSidebarProvider } from "../sidebar/sidebarContext";

function Layout() {
  return (
    <div className="hide-scrollbars">
      <MyProSidebarProvider>
        <div style={{ height: "100%", width: "100%" }}>
          <main>
            <Header />
            <Outlet />
          </main>
        </div>
      </MyProSidebarProvider>
    </div>
  );
}

export default Layout;
