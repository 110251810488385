import React, { useEffect, useState } from "react";
import {
    Box,
    IconButton,
    Pagination,
    Typography,
    FormControl,
    Select,
    MenuItem,
    Drawer,
    Stack,
    Divider,
    Button,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../common/Input";
import PageHeader from "../components/pageHeader/PageHeader";
import EnhancedTable from "../components/table/EnhancedTable.js";
import { CloseIcon, DeleteIcon, EditIcon } from "../assets/icons/Icons";
import "../components/table/table.css";

import { useForm } from "../common/useForms.js";
import FormDialog from "../components/dialog/dialog.js";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "../common/permissions.js";
import { permissions } from "../common/constants.js";
import DeleteDialog from "../components/dialog/deletedialog.js";
import Loader from "../components/loader/Loader.js";
import { base_Url } from "../web/utils/common.js";
import axios from "axios";
import { snackBar } from "../redux/common.js";



const columns = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
    },
    {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email",
    },
    {
        id: "subject",
        numeric: false,
        disablePadding: true,
        label: "Subject",
    },
    {
        id: "message",
        numeric: false,
        disablePadding: true,
        label: "Message",
    },
].filter((el) => el !== null);

const initialValues = {
    contact_name: "",
    email: "",
    subject: "",
    message: "",
};

const RecordPerPage = [
    { id: 5, title: "5" },
    { id: 10, title: "10" },
    { id: 15, title: "15" },
    { id: 20, title: "20" },
];
const useStyles = makeStyles((theme) => ({
    filterMain: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "#fff",
    },
    mainFilterBox: {
        padding: "10px 20px",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            width: "200px",
        },

        [theme.breakpoints.up("md")]: {
            width: "300px",
        },
        [theme.breakpoints.up("lg")]: {
            width: "240px !important",
        },
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
    },

    stackFilter: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },

    typographyFilter: {
        fontWeight: "bold !important",
        color: "#000 !important",
        fontSize: "30px !important",
    },
    buttonFilter: {
        flexDirection: "row !important",
        marginTop: "8px",
        gap: "8px",
    },
}));


const CommentList = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [order, setOrder] = useState("");
    const [orderBy, setOrderBy] = useState("");
    const [selected, setSelected] = useState([]);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [selectedPage, setSelectedPage] = useState(null);
    const [DeleteId, SetDeleteId] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [popupName, setPopupName] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageLoading, setPageLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [commentList, setCommentList] = useState()
    const [commentListPagination, setCommentListPagination] = useState()
    const [filter, setFilter] = useState(false);
    const [filterData, setFilterData] = useState(true);
    const [filterDatas, setFilterDatas] = useState(initialValues);
    const [isFieldFilled, setIsFieldFilled] = useState();

    function subStr(txt, len) {
        let acceptedText =
            txt.length < len ? txt : txt.substring(0, len - 1) + "...";
        return acceptedText;
    }

    const getAllCommentList = async (page, rowsPerPage, name = "", email = "", subject = "", message = "") => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_Url}comment/all-comment?limit=${rowsPerPage}&page=${page}`)
            if (res.data.status) {
                setCommentList(res.data.data.connectRequestData)
                setCommentListPagination(res.data.data)
                setLoading(false)
            } else {
                setLoading(false)
                snackBar(res.data.message, res.data.status)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        getAllCommentList(page, rowsPerPage)        
    }, [page, rowsPerPage])


    useEffect(() => {
        if (filter) {
            const anyFieldHasValue = Object.values(filterDatas).some(
                (val) => val !== ""
            );
            setIsFieldFilled(anyFieldHasValue);
        }
    }, [filter, filterDatas]);

    const [isNotValidEmail, setIsNotValidEmail] = useState()
    const [isValidEmail, setIsValidEmail] = useState(true)

    const handleChange = (e) => {
        const { name, value } = e.target;
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i

        if (name === "email" && value && !emailRegex.test(value)) {
            setIsNotValidEmail("Please enter valid email")
            setIsValidEmail(false)
          } else {
            setIsNotValidEmail()
            setIsValidEmail(true)
          }


        setFilterDatas({
            ...filterDatas,
            [name]: value,
        });
    };


    const handleSubmitFilter = async (e) => {
        e.preventDefault();
        const name = filterDatas.contact_name;
        const email = filterDatas.email;
        const subject = filterDatas.subject;
        const message = filterDatas.message;
        setPage(1);
        setRowsPerPage(10)
        setLoading(true)
        try {
            const res = await axios.get(`${base_Url}comment/all-comment?limit=${rowsPerPage}&page=${page}&name=${name}&email=${email}&subject=${subject}&message=${message}`)
            if (res.data.status) {
                setCommentList(res.data.data.connectRequestData)
                snackBar(res.data.message, res.data.status)
                setLoading(false)
            } else {
                setLoading(false)
                snackBar(res.data.message, res.data.status)
            }
        } catch (error) {
            setLoading(false)
        }
        closeFilterDrawer1();
    };
    const resetFormFilter = async () => {
        setFilterDatas(initialValues);
        setPage(1);
        setRowsPerPage(10)
        setLoading(true)
        try {
            const res = await axios.get(`${base_Url}comment/all-comment?limit=${rowsPerPage}&page=${page}`)
            if (res.data.status) {
                setCommentList(res.data.data.connectRequestData)
                snackBar(res.data.message, res.data.status)
                setLoading(false)
            } else {
                setLoading(false)
                snackBar(res.data.message, res.data.status)
            }
        } catch (error) {
            setLoading(false)
        }
        closeFilterDrawer();
    };
    const closeFilterDrawer = () => {
        setFilter(false);
        setFilterData(!filterData);
    };

    const closeFilterDrawer1 = () => {
        setFilter(false);
    };
    const handleChangeRow = (event) => {
        const newRowPerPage = event.target.value;
        const newPageNumber = 1;
        setRowsPerPage(event.target.value);
        setPage(newPageNumber);

    };

    const tableTitle = "Page list";

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleClickOpen = () => {
        setPageLoading(false)
        setPopupName("Create Page");
        setOpen(true);
    };

    const handleClose = () => {
        setPageLoading(false)
        setOpen(false);
        resetForm();
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if (popupName === "Edit Banner") {
            if ("page_name" in fieldValues) {
                if (
                    fieldValues.page_name === "" ||
                    fieldValues.page_name === undefined ||
                    fieldValues.page_name === null
                ) {
                    temp.page_name = "Please write name of the page";
                } else {
                    temp.page_name = "";
                }
            }
        } else {
            if ("page_name" in fieldValues) {
                if (
                    fieldValues.page_name === "" ||
                    fieldValues.page_name === undefined ||
                    fieldValues.page_name === null
                ) {
                    temp.page_name = "Please write name of the page";
                } else {
                    temp.page_name = "";
                }
            }
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);


    const fields = [
        {
            id: "page_name",
            label: "Page name",
            type: "text",
            value: values.page_name,
            maxLength: 20,
            name: "page_name",
            errors: errors.page_name,
        },
    ];


    const rows =
        commentList && Array.isArray(commentList)
            ? commentList?.map((data) => {
                return {
                    _id: data._id,
                    name: subStr(data.name, 25) || "-",
                    email: data.email || "-",
                    subject: (
                        <div style={{ overflowWrap: "break-word", /* Modern equivalent of word-wrap */
                            wordBreak: "break-word", /* Breaks words at arbitrary points if necessary */
                            whiteSpace: "normal"}}>
                        {data.subject}
                        </div> || "-"
                    ),
                    message: (
                            <div style={{ overflowWrap: "break-word", /* Modern equivalent of word-wrap */
                                wordBreak: "break-word", /* Breaks words at arbitrary points if necessary */
                                 whiteSpace: "normal"}}>
                                    {data.message}
                            </div>) || "-",
                };
            })
            : [];



    const handleSubmit = async (e) => {
        // e.preventDefault();
        if (validate()) {
            setPageLoading(true)
            const formData = new FormData();
            formData.append("page_name", values.page_name);


        }
    };

    const openFilterDrawer = () => {
        setFilter(true);
    };

    return (
        <Box>
            <PageHeader
                title="Comment List"
                onFilterClick={openFilterDrawer}
            />

            {loading ? (
                <Loader />
            ) : (
                <EnhancedTable
                    rowsPerPage={rowsPerPage}
                    key={rows._id}
                    columns={columns}
                    rows={rows}
                    order={order}
                    orderBy={orderBy}
                    selected={selected}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    title={tableTitle}
                    tableList={commentList}
                />
            )}
            

            <Box
                sx={{
                    display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
                    marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
                    mt: 2,
                    mb: 2,
                }}
            >
                <Box
                    sx={{
                        fontSize: "14px",
                        marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                        gap: 2,
                        width: { sm: "100%", md: "50%" },
                    }}
                    display="flex"
                    alignItems="center"
                >
                    <Box>
                        {" "}
                        Showing {commentListPagination?.currentPage} of {commentListPagination?.totalPages} Results
                    </Box>
                    <Box display="flex" sx={{ gap: 1 }} alignItems="center">
                        <Typography>Records per page</Typography>

                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                id="limites"
                                name="limites"
                                value={rowsPerPage}
                                onChange={handleChangeRow}
                                displayEmpty
                                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                {RecordPerPage?.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name || item.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Pagination
                    count={commentListPagination?.totalPages}
                    page={page}
                    onChange={handleChangePage}
                    variant="outlined"
                    shape="rounded"
                    sx={{
                        "& .MuiButtonBase-root ": {
                            color: "#000 !important",
                        },
                        "& .Mui-selected ": {
                            background: "#4b4b4e70 !important",
                        },
                        "& .Mui-disabled ": {
                            background: "#6c696966 !important",
                            color: "#000000 !important",
                            opacity: 1,
                        },
                    }}
                />
            </Box>
            <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
                <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
                    <Box className={classes.mainFilterBox}>
                        <Stack className={classes.stackFilter}>
                            <Typography className={classes.typographyFilter}>
                                Filter
                            </Typography>

                            <CloseIcon
                                className="close-button"
                                onClick={closeFilterDrawer1}
                            />
                        </Stack>
                        <Box
                            sx={{
                                marginTop: "5px",
                            }}
                        >
                            <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
                        </Box>

                        <div>
                            <Box>
                                <Input
                                    variant="outlined"
                                    color="primary"
                                    label="Name"
                                    name="contact_name"
                                    value={filterDatas.contact_name}
                                    onChange={handleChange}
                                    maxLength={25}
                                />
                                <Input
                                    variant="outlined"
                                    color="primary"
                                    label="Email"
                                    name="email"
                                    value={filterDatas.email}
                                    onChange={handleChange}
                                    maxLength={50}
                                />
                                <p style={{ marginLeft: "10px", marginTop: "0px", marginBottom: "0px" }} className="err-msg err-msg-font">{isNotValidEmail}</p>
                                <Input
                                    variant="outlined"
                                    color="primary"
                                    label="Subject"
                                    name="subject"
                                    value={filterDatas.subject}
                                    onChange={handleChange}
                                    maxLength={20}
                                />
                                <Input
                                    variant="outlined"
                                    color="primary"
                                    label="Message"
                                    name="message"
                                    value={filterDatas.message}
                                    onChange={handleChange}
                                    maxLength={20}
                                />
                            </Box>
                            <Stack className={classes.buttonFilter}>
                                <Button
                                    disabled={!isFieldFilled}
                                    color="secondary"
                                    size="large"
                                    variant="contained"
                                    className="cancel-filter"
                                    onClick={resetFormFilter}
                                >
                                    Reset
                                </Button>
                                <Button
                                    disabled={!isFieldFilled}
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    className="order-filter-button"
                                    type="submit"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Filter
                                </Button>
                            </Stack>
                        </div>
                    </Box>
                </form>
            </Drawer>
        </Box>

    );
};

export default CommentList