import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Badge,
  Dialog,
  MenuItem,
  Menu,
  Grid,
  Button
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import "./header.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/action/AuthAction";
import { GetUserProfile } from "../../redux/action/UserManageAction";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  height: "220px",
  borderRadius: "20px",
}));

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [openLog, setOpenLog] = React.useState(false);
  const userData = localStorage.getItem("user_data");
  const userObj = JSON.parse(userData);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const UserProfile = useSelector(
    (state) => state?.UserManageReducer?.userProfileData?.data
  );

  React.useEffect(() => {
    dispatch(GetUserProfile());
  }, []);



  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleClickOpenLog = () => {
    setOpenLog(true);
  };

  const handleCloseLog = () => {
    setOpenLog(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const [logoutLoading, setLogoutLoading] = React.useState(false)

  const handleLogout = async () => {
    setLogoutLoading(true)
    const res = await dispatch(logoutUser(navigate));
    if(res?.data?.status){
      setLogoutLoading(false)
    } else {
      setLogoutLoading(false)
    }
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      sx={{ color: "#fff" }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          navigate("/admin-profile");
          handleMenuClose();
        }}
      >
        Profile
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClickOpenLog();
          handleMenuClose();
        }}
      >
        Log Out
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      sx={{ color: "#fff" }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => navigate("/admin-profile")}>Profile</MenuItem>
      <MenuItem onClick={handleClickOpenLog}>Log Out</MenuItem>
    </Menu>
  );

  return (
    <div className="header-main">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "#d1d1d17a", color: "#000" }}
        >
          <Toolbar sx={{ paddingTop: { xs: '36px', sm: '36px', md: '36px' }}}>
            <Typography variant="h5" component="div">
              {UserProfile && (
                <span>
                  Logged in as <b>{UserProfile && UserProfile?.role}</b>
                  {/* userObj?.user?.user_role?.role */}
                </span>
              )}
              {/* {userObj?.user?.user_role?.role && (
                <span>
                  Logged in as <b>{UserProfile && UserProfile?.role}</b>
                  userObj?.user?.user_role?.role
                </span>
              )} */}
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Typography
                sx={{
                  marginTop: "10px",
                  fontSize: "17px",
                  fontWeight: "600",
                }}
              >
                <span
                  onClick={() => navigate("/admin-profile")}
                  style={{ cursor: "pointer" }}
                >
                  {userObj?.user?.first_name} {userObj?.user?.last_name}
                </span>
              </Typography>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
      <BootstrapDialog
        onClose={handleCloseLog}
        aria-labelledby="customized-dialog-title"
        open={openLog}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div>
          <Box
            sx={{
              width: "320px",
              marginBottom: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography style={{ marginTop: "27px", padding: "13px" }}>
              Are you sure you want to logout?
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseLog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogActions>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Button
                    disabled={logoutLoading}
                    onClick={handleLogout}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      border: "0",
                      ":hover": { bgcolor: "#1976d2", border: "0" },
                      backgroundColor: "#153e80",
                      marginLeft: {
                        xs: "0px",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                      },
                      color: "#fff",
                      textTransform: "none",
                      minWidth: "100px",
                    }}
                  >
                    Yes
                  </Button>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      ":hover": { bgcolor: "#1976d2", border: "0" },
                      marginLeft: {
                        xs: "0px",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                      },
                      textTransform: "none",
                      minWidth: "100px",
                    }}
                    onClick={handleCloseLog}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Box>
        </div>
      </BootstrapDialog>
    </div>
  );
}
