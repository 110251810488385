import React, { useEffect, useState } from "react";
// import logo from "../../assets/logo-home.png";
import { Typography, makeStyles } from "@material-ui/core";
import logo from "../../assets/image/logo-home.svg";
import Login from "../../pages/auth/Login";
import {
  Box,
  Button,
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DropdownMenuItem from "../DropdownMenuItem/DropdownMenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import instaIcon from "../../GGC_LendingPage/svg/colorINSTA.svg";
import waIcon from "../../GGC_LendingPage/svg/whatsapp.svg";
import uTIcon from "../../GGC_LendingPage/svg/uTube.svg";
import arrow from "../../GGC_LendingPage/svg/arrowLeft.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  height: "220px",
  borderRadius: "20px",
}));

//NavBar Data
const pages = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Be a Partner",
    path: "/be-partner",
  },
  {
    title: "Be a Member",
    path: "/be-member",
  },
  {
    title: "More",
    children: [
      {
        title: "About",
        path: "/about-us",
      },
      {
        title: "Blog",
        path: "/blog",
      },
      {
        title: "Contact",
        path: "/contact-us",
      },
    ],
  },
];

//Icons
const icons = [
  {
    title: "Whatsapp",
    img: waIcon,
    link: "https://api.whatsapp.com/send?phone=916354917511&text=Hello%20Team%20Global%20Garner%20%2C%20I%20have%20seen%20Partner%20Program%20on%20your%20website.%20I%27m%20interested%20in%20participating%20with%20GG%20Community.%20Please%20guide%20me%20for%20the%20next%20step.%20Thank%20You.",
  },
  {
    title: "Instagram",
    img: instaIcon,
    link: "https://www.instagram.com/myggcommunity/?igsh=cWR4Znc3NjBmcmht",
  },
  {
    title: "Youtube",
    img: uTIcon,
    link: "https://www.youtube.com/@GlobalGarnerOfficial?si=pLbPk5lraRX0Zog6",
  },
];

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "12rem",
    // marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "8rem",
    },
    marginTop: "0.2em",
  },
}));

const headerBackgroundWhiteOnPath = ["about-us", "contact-us", "event"];
const textWhiteOnPath = [""];

const Navbar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const history = useLocation();
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(
    location && location.state && location.state.formName === "Reset password"
      ? true
      : false
  );
  const [anchorElNav, setAnchorElNav] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [show, setShow] = useState(true);
  const [openLog, setOpenLog] = useState(false);

  const [rotateArw, setRotateArw] = useState(false);

  const isActive = location.pathname;

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.formName === "Reset password"
    ) {
      window.history.replaceState({}, "");
    }
  }, [location]);

  const handleClickOpenLog = () => {
    setOpenLog(true);
  };
  const handleCloseLog = () => {
    setOpenLog(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos < prevScrollPos) {
        setShow(true);
      } else {
        setShow(false);
      }
      setIsTop(currentScrollPos <= 0);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const token = localStorage.getItem("access_token");

  const handleOpenNavMenu = () => {
    setAnchorElNav(true);
  };

  const handleNavigate = (path) => {
    handleCloseNavMenu();
    navigate(path);
    setAnchorElNav(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_data");
    localStorage.removeItem("userData");
    window.location.reload();
  };

  return (
    <>
      <header
        id="header"
        className="main-header header-sticky header-sticky-smart header-style-04 header-float header-light text-uppercase header-position-change"
        style={{
          position: "sticky",
          top: 0,
          left: 0,
          zIndex: 999,
          width: "100%",
          transition: "all 0.3s ease-in-out",
          backgroundColor: "#fff",
          boxShadow: isTop || show ? "none" : "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <section className="w-full">
          {/*Social Media*/}
          <section
            className="topbar responsive d-flex"
            style={{
              marginTop: "8px",
              paddingLeft: "10px",
              paddingRight: "18px",
              gap: "8px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {/*E-mail*/}
            <div className="pointer">
              <i className="fal fa-envelope" style={{ color: "#707070" }}></i>
              &#x2002;
              <a className="emailText" target="_blank" href="mailto:info@globalgarner.community">
                <span className="emailText">
                info@globalgarner.community
                </span>
              </a>
            </div>
            <p style={{ color: "#d1d1d1", fontSize: "30px", margin: "auto 0" }}>
              |
            </p>

            {/*ICONS*/}
            {icons?.map((icon, key) => (
              <a
                target="_blank"
                href={icon.link}
                key={key}
                style={{ margin: "auto 0" }}
              >
                <img
                  src={icon.img}
                  alt={icon.title}
                  width="30px"
                  height="30px"
                />
              </a>
            ))}
          </section>

          <nav
            className="navbar responsive navbar-expand-xl"
            style={{ margin: "-21px auto" }}
          >
            <div className="responsive d-flex d-xl-none flex-fill justify-content-between align-items-center">
              <Box>
                <MenuIcon
                  onClick={handleOpenNavMenu}
                  sx={{ display: { md: "block", xs: "block" } }}
                />
              </Box>
              <a className="navbar-brand navbar-brand-mobile" href="/">
                <img
                  className={"logo-image " + classes.logo}
                  src={logo}
                  alt="GGC"
                />
              </a>

              <Box
                sx={{
                  flexGrow: { xs: 1, md: 1 },
                }}
              >
                <SwipeableDrawer
                  sx={{ display: { xs: "flex", md: "flex" } }}
                  anchor="top"
                  open={anchorElNav}
                  onClose={handleCloseNavMenu}
                  onOpen={handleCloseNavMenu}
                >
                  <Box
                    role="presentation"
                    // onClick={handleCloseNavMenu}
                    onKeyDown={handleCloseNavMenu}
                    sx={{
                      width: "fit-content",
                      marginTop: 2,
                      minWidth: "11rem",
                    }}
                  >
                    <List>
                      {pages.map((page, index) => (
                        <ListItem
                          key={index}
                          disablePadding
                          sx={{
                            fontSize: 15,
                            fontWeight: "bold",
                            marginLeft: "12px",
                            // ...(hoverStyles),
                          }}
                        >
                          {Array.isArray(page.children) ? (
                            <DropdownMenuItem
                              handleCloseNavMenu={handleCloseNavMenu}
                              title={page.title}
                              items={page.children}
                            />
                          ) : (
                            <ListItemButton
                              onClick={() => handleNavigate(page.path)}
                            >
                              <ListItemText
                                primary={page.title}
                                className="title"
                              />
                            </ListItemButton>
                          )}
                        </ListItem>
                      ))}
                      {token && (
                        <ListItemButton
                          onClick={() => handleNavigate("/admin/dashboard")}
                          sx={{
                            fontSize: 15,
                            fontWeight: "bold",
                            marginLeft: "12px",
                          }}
                        >
                          <ListItemText primary={"Dashboard"} />
                        </ListItemButton>
                      )}
                    </List>
                  </Box>
                </SwipeableDrawer>
              </Box>

              <a
                className="mobile-button-search"
                href="#search-popup"
                data-gtf-mfp="true"
                data-mfp-options='{"type":"inline","mainClass":"mfp-move-from-top mfp-align-top search-popup-bg","closeOnBgClick":false,"showCloseBtn":false}'
              >
                {!token ? (
                  <svg
                    style={{marginRight: '20px', width: '30px', height: '30px'}}
                    onClick={handleClickOpen}
                    className="icon icon-user-circle-o"
                  >
                    <use xlinkHref="#icon-user-circle-o"></use>
                  </svg>
                ) : (
                  <LogoutIcon onClick={handleClickOpenLog} style={{marginRight: '15px', width: '30px', height: '30px'}} />
                )}
              </a>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <a className="navbar-brand d-none d-xl-block mr-auto" href="/">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                >
                  <img src={logo} alt="GGC" className={classes.logo} style={{height: '70px', width: '80%'}} />
                </Typography>
              </a>

              <div className="navHide">
                <ul style={{ display: "flex", gap: "18px", margin: "auto 0" }}>
                  <li style={{ listStyleType: "none" }}>
                    <Link
                      to="/"
                      className={isActive === "/" ? "navChange" : "navLink"}
                    >
                      Home
                    </Link>
                  </li>
                  <li style={{ listStyleType: "none" }}>
                    <Link
                      to="/service"
                      className={
                        isActive === "/service" ? "navChange" : "navLink"
                      }
                    >
                      Services
                    </Link>
                  </li>
                  <li style={{ listStyleType: "none" }}>
                    <Link
                      to="/events"
                      className={
                        isActive === "/events" ? "navChange" : "navLink"
                      }
                    >
                      Events
                    </Link>
                  </li>
                  <li style={{ listStyleType: "none" }}>
                    <Link
                      to="/be-partner"
                      className={
                        isActive === "/be-partner" ? "navChange" : "navLink"
                      }
                    >
                      Be a Partner
                    </Link>
                  </li>
                  <li style={{ listStyleType: "none" }}>
                    <Link
                      to="/be-member"
                      className={
                        isActive === "/be-member" ? "navChange" : "navLink"
                      }
                    >
                      Be a Member
                    </Link>
                  </li>
                  <li
                    className="main"
                    style={{ listStyleType: "none", position: "relative" }}
                  >
                    <Link
                      className="navLink"
                      to="/"
                      // onClick={() => setRotateArw(!rotateArw) }
                      style={{ display: "flex", gap: "6px" }}
                    >
                      More
                      <img
                        className="moreDt"
                        src={arrow}
                        alt="Arrow"
                        style={{ rotate: "90deg" }}
                      />
                    </Link>
                    <ul className="moreDetails">
                      <li style={{ listStyleType: "none" }}>
                        <Link className="navLink" to="/about-us">
                          About Us
                        </Link>
                      </li>
                      <li style={{ listStyleType: "none" }}>
                        <Link className="navLink" to="/blog">
                          Blog
                        </Link>
                      </li>
                      <li style={{ listStyleType: "none" }}>
                        <Link className="navLink" to="/contact-us">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {token && (
                    <li style={{ listStyleType: "none" }}>
                      <Link
                        to="/admin/dashboard"
                        className={
                          isActive === "/admin/dashboard"
                            ? "navChange"
                            : "navLink"
                        }
                      >
                        Dashboard
                      </Link>
                    </li>
                  )}
                </ul>
              </div>

              {/* <div>
                  <button type='button' className='btnAll'>
                  <a
                            // className="nav-link active"
                            // id="login-tab"
                            className="btnAll"
                            data-toggle="tab"
                            href="#login"
                            role="tab"
                            aria-controls="login"
                            aria-selected="true"
                          >
                            <h3>Log In</h3>
                          </a>
                  </button> 
                </div> */}

              <div className="navHide" style={{ marginLeft: "25px" }}>
                {token ? (
                  <button type="button" className="btnAll">
                    <a
                      onClick={handleClickOpenLog}
                      className="link"
                      data-gtf-mfp="true"
                      data-mfp-options='{"type":"inline"}'
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <LogoutIcon />
                      Logout
                    </a>
                  </button>
                ) : (
                  <button type="button" className="btnAll">
                    <a
                      href="#"
                      onClick={handleClickOpen}
                      className="link"
                      data-gtf-mfp="true"
                      data-mfp-options='{"type":"inline"}'
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <svg
                        className="icon icon-user-circle-o"
                        style={{ marginRight: "6px" }}
                      >
                        <use xlinkHref="#icon-user-circle-o"></use>
                      </svg>
                      Login
                    </a>
                  </button>
                )}
              </div>
            </div>
          </nav>
        </section>

        {/*Are u sure u want to LOGOUT*/}
        <BootstrapDialog
          onClose={handleCloseLog}
          aria-labelledby="customized-dialog-title"
          open={openLog}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div>
            <Box
              sx={{
                width: "320px",
                marginBottom: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography style={{ marginTop: "27px", padding: "13px" }}>
                Are you sure you want to logout?
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseLog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>

              {/*Are U sure!*/}
              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6}>
                    {/*Yes Btn*/}
                    <Button
                      onClick={handleLogOut}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        border: "0",
                        ":hover": { bgcolor: "#0f2b5a", border: "0", color: '#fff' },
                        backgroundColor: "#153e80", 
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        color: "#fff",
                        textTransform: "none",
                        minWidth: "100px",
                      }}
                    >
                      Yes
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    {/*No Btn*/}
                    <Button
                      variant="outlined"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        ":hover": { color: '#0f2b5a' },
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        textTransform: "none",
                        minWidth: "100px",
                      }}
                      onClick={handleCloseLog}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Box>
          </div>
        </BootstrapDialog>
      </header>
      {location &&
      location.state &&
      location.state.formName === "Reset password" ? (
        <Login toNav={true} open={open} handleClose={handleClose} />
      ) : (
        <Login toNav={true} open={open} handleClose={handleClose} />
      )}
    </>
  );
};

export default Navbar;
