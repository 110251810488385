import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../pageHeader/PageHeader";
import EnhancedTable from "../table/EnhancedTable.js";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "../dialog/deletedialog";
import SelectBox from "../../common/SelectBox";
import { GetAllCountry } from "../../redux/action/CountryAction";
import { GetAllStates, cleareAllStates } from "../../redux/action/StateAction";
import { GetAllCities, cleareAllCities } from "../../redux/action/CityAction";
import {
  CreatePostal,
  DeletePostal,
  GetPostalCode,
  UpdatePostal,
  clearePostalCodes,
} from "../../redux/action/PostalCodeAction";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import axios from "axios";
import { ApiURL } from "../../redux/common";
import Loader from "../loader/Loader";
import useUserRolePermissions from "../../common/UserPermissions";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const initialValues = {
  country: "",
  state: "",
  city: "",
  postalcode: "",
  is_active: "",
};
const initialFilterValues = {
  postalcode: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const PostalCodeList = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectPostal, setselectPostal] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFilterClicked, setIsFilterClicked] = useState(false);

  const [downLoadData, setDownLoadData] = useState("");
  const role = useUserRolePermissions();

  const [enableForDownload, setEnableForDownload] = useState(true)

  const DownLoadPdf = async () => {
    const postalcode = filterDatas.postalcode;
    try {
      const res = await axios.get(`${ApiURL}postalCode/download-data?search=${postalcode}`);
      setEnableForDownload(false)
      setDownLoadData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching role data:", error);
      return {};
    }
  };

  console.log("downLoadData_17171717",downLoadData)

  useEffect(() => {
    DownLoadPdf();
  }, [isFilterClicked]);

  const columns = [
    {
      id: "postal_code",
      numeric: false,
      disablePadding: true,
      label: "Postal Code",
    },
    {
      id: "city_name",
      numeric: false,
      disablePadding: true,
      label: "City",
    },
    {
      id: "state_name",
      numeric: false,
      disablePadding: true,
      label: "State",
    },
    {
      id: "country_name",
      numeric: false,
      disablePadding: true,
      label: "Country",
    },
    {
      id: "is_active",
      numeric: true,
      disablePadding: false,
      label: "Is Active",
    },
    !!getPermissions(permissions.postalcode, "is_edit") ||
      !!getPermissions(permissions.postalcode, "is_delete")
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const handleChangeRow = (event) => {
    setRowsPerPage(event.target.value)
    setPage(1);
    const postal_code = filterDatas.postalcode;
    dispatch(GetPostalCode(1, postal_code, event.target.value));
  };

  const classes = useStyles();
  const dispatch = useDispatch();

  const { allStateData } = useSelector((state) => state.StateReducer);
  const { allCountryData } = useSelector((state) => state.CountryReducer);
  const { allCitiesData } = useSelector((state) => state.CityReducer);
  const {
    loading,
    updateLoading,
    createLoading,
    deleteLoading,
    postalCodeData,
  } = useSelector((state) => state.PostalCodeReducer);

  const tableTitle = "PostalCodeList";

  useEffect(() => {
    dispatch(GetAllCountry(true));
    // dispatch(GetAllStates());
    // dispatch(GetAllCities());
  }, []);

  useEffect(() => {
    if (!open) {
      const postal_code = filterDatas.postalcode;
      dispatch(GetPostalCode(page, postal_code, rowsPerPage));
    }
  }, [page, open]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setDialogTitle("Add PostalCode");
    setValues(initialValues);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if (dialogTitle === "Update PostalCode") {
      if ("country" in fieldValues) {
        if (
          fieldValues.country === "" ||
          fieldValues.country === undefined ||
          fieldValues.country === null
        ) {
          temp.country = "Please select country";
        } else {
          temp.country = "";
        }
      }
      if ("state" in fieldValues) {
        if (
          fieldValues.state === "" ||
          fieldValues.state === undefined ||
          fieldValues.state === null
        ) {
          temp.state = "Please select state";
        } else {
          temp.state = "";
        }
      }
      if ("city" in fieldValues) {
        if (
          fieldValues.city === "" ||
          fieldValues.city === undefined ||
          fieldValues.city === null
        ) {
          temp.city = "Please select city";
        } else {
          temp.city = "";
        }
      }
      if ("postalcode" in fieldValues) {
        if (
          fieldValues.postalcode === "" ||
          fieldValues.postalcode === undefined ||
          fieldValues.postalcode === null ||
          !/^\d+$/.test(fieldValues.postalcode)
        ) {
          temp.postalcode = "Please enter a valid postal code (numbers only)";
        } else {
          temp.postalcode = "";
        }
      }
      if ("is_active" in fieldValues) {
        if (
          fieldValues.is_active === "" ||
          fieldValues.is_active === undefined ||
          fieldValues.is_active === null
        ) {
          temp.is_active = "This field is required.";
        } else {
          temp.is_active = "";
        }
      }
    } else {
      if ("country" in fieldValues) {
        if (
          fieldValues.country === "" ||
          fieldValues.country === undefined ||
          fieldValues.country === null
        ) {
          temp.country = "Please select country";
        } else {
          temp.country = "";
        }
      }
      if ("state" in fieldValues) {
        if (
          fieldValues.state === "" ||
          fieldValues.state === undefined ||
          fieldValues.state === null
        ) {
          temp.state = "Please select state";
        } else {
          temp.state = "";
        }
      }
      if ("city" in fieldValues) {
        if (
          fieldValues.city === "" ||
          fieldValues.city === undefined ||
          fieldValues.city === null
        ) {
          temp.city = "Please select city";
        } else {
          temp.city = "";
        }
      }
      if ("postalcode" in fieldValues) {
        if (
          fieldValues.postalcode === "" ||
          fieldValues.postalcode === undefined ||
          fieldValues.postalcode === null ||
          !/^\d+$/.test(fieldValues.postalcode)
        ) {
          temp.postalcode = "Please enter a valid postal code (numbers only)";
        } else {
          temp.postalcode = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  useEffect(() => {
    if (values?.country?._id) {
      dispatch(GetAllStates(values?.country?._id, true));
      dispatch(cleareAllStates());
      dispatch(cleareAllCities());
      // setValues({ ...values, 'state': '','city': ''})
    }
  }, [values?.country]);

  useEffect(() => {
    if (values?.country?._id && values?.state?._id) {
      dispatch(GetAllCities(values?.state?._id, true));
      dispatch(cleareAllCities());
      //   setValues({ ...values, 'city': '' })
    }
  }, [values?.state]);

  const handleEditClick = (postal) => {
    setselectPostal(postal);
    setDialogTitle("Update PostalCode");
    setValues({
      ...values,
      postalcode: postal.postal_code || "",
      country: { _id: postal.country_id, country_name: postal.country_name },
      state: { _id: postal.state_id, state_name: postal.state_name },
      city: { _id: postal.city_id, city_name: postal.city_name },
      is_active: postal.is_active ? "true" : "false" || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (postal) => {
    setOpenDelete(true);
    SetDeleteId(postal._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeletePostal(DeleteId, handleCloseDelete)).then((res) => {
      if (res && res.data.status === true) {
        dispatch(GetPostalCode(page, "", rowsPerPage));
      }
    });
  };

  const fields = [
    {
      id: "country",
      label: "Country",
      type: "autocomplete",
      autoCompleteData: allCountryData?.countryData,
      value: values.country,
      name: "country",
      errors: errors.country,
    },
    {
      id: "state",
      label: "State",
      type: "autocomplete",
      autoCompleteData: allStateData,
      value: values.state,
      labelAccess: "state_name",
      name: "state",
      errors: errors.state,
    },
    {
      id: "city",
      label: "City",
      type: "autocomplete",
      autoCompleteData: allCitiesData,
      labelAccess: "city_name",
      value: values.city,
      name: "city",
      errors: errors.city,
    },
    {
      id: "postalcode",
      label: "Postal Code",
      type: "number",
      value: values.postalcode,
      name: "postalcode",
      errors: errors.postalcode,
      maxLength: 6,
    },
    {
      id: "is_active",
      label: "Is Active",
      type: "select",
      options: [
        { value: "true", label: "ACTIVE" },
        { value: "false", label: "INACTIVE" },
      ],
      value: values.is_active,
      name: "is_active",
      errors: errors.is_active,
    },
  ];

  //filter
  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setIsFilterClicked(false)
    setPage(1);
    dispatch(GetPostalCode(1, "", rowsPerPage));
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(initialFilterValues);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;

    if (name === "postalcode" && value && !numericRegex.test(value)) {
      return;
    }

    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setIsFilterClicked(true)
    const postal_code = filterDatas.postalcode;
    setPage(1);
    dispatch(GetPostalCode(page, postal_code, rowsPerPage));
    closeFilterDrawer1();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let body = {};
      if (dialogTitle === "Update PostalCode") {
        body = {
          country_id: values.country._id,
          state_id: values.state._id,
          city_id: values.city._id,
          is_active: values.is_active,
          postal_code: +values.postalcode,
        };
      } else {
        body = {
          country_id: values.country._id,
          state_id: values.state._id,
          city_id: values.city._id,
          postal_code: +values.postalcode,
        };
      }

      if (dialogTitle === "Update PostalCode") {
        dispatch(UpdatePostal(selectPostal._id, body, handleClose));
      } else {
        dispatch(CreatePostal(body, handleClose));
      }
    }
  };

  const rows =
    postalCodeData && Array.isArray(postalCodeData?.codeData)
      ? postalCodeData?.codeData.map((postal) => {
        const selected = postal === selectPostal;
        return {
          _id: postal._id,
          postal_code: postal.postal_code || "-",
          city_name: postal.city_name || "-",
          state_name: postal.state_name || "-",
          country_name: postal.country_name || "-",
          is_active: postal.is_active ? "Active" : "inActive",
          action: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {!!getPermissions(permissions.postalcode, "is_edit") && (
                <IconButton
                  onClick={() => handleEditClick(postal)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              {!!getPermissions(permissions.postalcode, "is_delete") && (
                <IconButton
                  onClick={() => handleClickOpenDelete(postal)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          ),
          selected: selected,
        };
      })
      : [];

  const isAnyFieldFilled = Object.values(filterDatas).some((value) =>
    Boolean(value)
  );

  return (
    <Box>
      <PageHeader
        title="Postal Codes"
        isShowAdd={!!getPermissions(permissions.postalcode, "is_add")}
        onAddClick={handleClickOpen}
        onFilterClick={openFilterDrawer}
        enableForDownload={enableForDownload}
        loading={loading}
        href={downLoadData}
      />
      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowsPerPage={rowsPerPage}
          title={tableTitle}
          tableList={postalCodeData?.codeData}
        />
      )}

      <FormDialog
        open={open}
        dialogTitle={dialogTitle}
        fields={
          dialogTitle === "Add PostalCode"
            ? fields.filter((el) =>
              ["country", "state", "city", "postalcode"].includes(el.name)
            )
            : fields
        }
        loading={
          dialogTitle === "Update PostalCode" ? updateLoading : createLoading
        }
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
      />
      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {postalCodeData?.currentPage} of{" "}
            {postalCodeData?.totalPages} Pages
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={postalCodeData?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  variant="outlined"
                  color="primary"
                  label="Postal Code"
                  name="postalcode"
                  type='text'
                  value={filterDatas.postalcode}
                  onChange={handleChange}
                  maxLength={20}
                />
              </Box>
              {/* <SelectBox
                                disabled={isAnyFieldFilled && !filterDatas.type}
                                options={filterMenuItem}
                                label={"Type"}
                                value={filterDatas.type}
                                name="type"
                                onChange={handleChange}
                            ></SelectBox> */}
              <Stack className={classes.buttonFilter}>
                <Button
                  disabled={filterDatas.postalcode ? false : true}
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                >
                  Reset
                </Button>
                <Button
                  disabled={filterDatas.postalcode ? false : true}
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
    </Box>
  );
};

export default PostalCodeList;
