import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";
import { logoutUser } from "./AuthAction";

const axiosApiCall = () =>
  axios.create({
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

export const GetUserRole = () => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING",
      payload: true,
    });

    const res = await axios.get(`${ApiURL}role/all-roles`);

    if (res.data.status === true) {
      dispatch({
        type: "LOADING",
        payload: false,
      });
      dispatch({
        type: "GET_ROLE_SUCCESS",
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: "LOADING",
      payload: false,
    });
  }
};

export const GetAllUser =
  (...rest) =>
  async (dispatch) => {
    let page = rest[0];
    let email = rest[1];
    let mobile_number = rest[2];
    let chapterId_refferalType = rest[3];
    let role_id = rest[4];
    let gender = rest[5];
    let approval_status = rest[6];
    let business_category_id = rest[7];
    let country_id = rest[8];
    let state_id = rest[9];
    let city_id = rest[10];
    let postal_code = rest[11];
    let is_active = rest[12] || "";
    let membership_id = rest[13] || "";
    let referral_code = rest[14] || "";
    let startDate = rest[15] || "";
    let endDate = rest[16] || "";
    let name = rest[17] || "";
    let subscriber = rest[13] ?? "";
    let rowsPerPage = rest[rest.length - 1];

    try {
      dispatch({
        type: "LOADING_USERMANAGE",
        payload: true,
      });

      const res = await axios.get(
        `${ApiURL}user/all-users?limit=${
          rowsPerPage || 10
        }&page=${page}&subscriber=${subscriber}&name=${name}&email=${email}&mobile_number=${mobile_number}&chapterId_refferalType=${chapterId_refferalType}&role_id=${role_id}&gender=${gender}&approval_status=${approval_status}&business_category_id=${business_category_id}&country_id=${country_id}&state_id=${state_id}&city_id=${city_id}&postal_code=${postal_code}&is_active=${is_active}&membership_id=${membership_id}&referral_code=${referral_code}&start_date=${startDate}&end_date=${endDate}`
      );

      if (res.data.status === true) {
        dispatch({
          type: "LOADING_USERMANAGE",
          payload: false,
        });

        const responseData =
          res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "USERMANAGE_SUCCESS",
          payload: responseData,
        });
        return res;
      } else {
        dispatch({
          type: "LOADING_USERMANAGE",
          payload: false,
        });
        dispatch({
          type: "USERMANAGE_SUCCESS",
          payload: [],
        });
        return res;
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "LOADING_USERMANAGE",
        payload: false,
      });
    }
  };

export const UpdateUserManage = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_LOADING_USERMANAGE",
      payload: true,
    });

    const res = await axiosApiCall().put(
      `${ApiURL}user/update/${id}`,
      formData
    );

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_USERMANAGE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error("err", err);
  } finally {
    dispatch({
      type: "UPDATE_LOADING_USERMANAGE",
      payload: false,
    });
  }
};

export const CreateUserManage = (formData, success) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_LOADING_USERMANAGE",
      payload: true,
    });
    dispatch({
      type: "",
      payload: true,
    });
    const res = await axiosApiCall().post(
      `${ApiURL}user/create-user`,
      formData
    );

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_USERMANAGE_SUCCESS",
        payload: res.data.data,
      });
      if (res?.data?.message && res?.data?.status) {
        snackBar(res?.data?.message, res?.data?.status);
      }
      if (success) {
        success();
      }
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "CREATE_LOADING_USERMANAGE",
      payload: false,
    });
  }
};

export const DeleteUserManage = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_LOADING_USERMANAGE",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}user/delete/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_USERMANAGE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "DELETE_LOADING_USERMANAGE",
      payload: false,
    });
  }
};

export const GetUserProfile = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_PROFILE", payload: true });

    const res = await axios.get(`${ApiURL}user/user-details`);

    if (res.data.status === true) {
      const responseData = res.data || [];
      // localstorage
      const userDataString = localStorage.getItem("user_data");
      const userData = JSON.parse(userDataString);
      if (userData && userData?.user) {
        userData.user.approval_status = responseData?.data?.approval_status;
        localStorage.setItem("user_data", JSON.stringify(userData));
        localStorage.setItem("userData", JSON.stringify(userData));
      }
      // localstorage
      dispatch({ type: "PROFILE_SUCCESS", payload: responseData });
    } else {
      dispatch(logoutUser(navigate));
      dispatch({ type: "PROFILE_SUCCESS", payload: [] });
    }

    dispatch({ type: "LOADING_PROFILE", payload: false });
  } catch (err) {
    // console.error(err);
    dispatch(logoutUser(navigate));
    dispatch({ type: "LOADING_PROFILE", payload: false });
  }
};

export const UpdateUserProfile = (data, handleClose) => async (dispatch) => {
  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);
  let url = `user/update/${userData?.user?._id}`;
  if (
    userData?.user?.user_role?.role == "Community Super Admin" ||
    userData?.user?.user_role?.role == "Community Admin"
  ) {
    url = "admin/update-admin";
  }
  try {
    dispatch({
      type: "UPDATE_LOADING_PROFILE",
      payload: true,
    });

    const res = await axiosApiCall().put(`${ApiURL}${url}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_PROFILE_SUCCESS",
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_LOADING_PROFILE",
      payload: false,
    });
  }
};

export const UpdateUserBusinessManage = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_LOADING_USERBUSINESSMANAGE",
      payload: true,
    });

    const res = await axiosApiCall().put(
      `${ApiURL}business/update/${id}`,
      formData
    );



    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_USERBUSINESSMANAGE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    } else {
      return res;
    }

  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error("err", err);
  } finally {
    dispatch({
      type: "UPDATE_LOADING_USERBUSINESSMANAGE",
      payload: false,
    });
  }
};

export const clearUserData = () => {
  return {
    type: "CLEAR_USER_DATA",
  };
};
