import React, { useState, useMemo } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import EnhancedTableToolbar from "./EnhancedTableToolbar ";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableRow from "./EnhancedTableRow";
import { stableSort, getComparator } from "./sortingUtilities";
import { TableBody, TableCell, TableRow } from "@mui/material";
import "./table.css";
import { useProSidebar } from "react-pro-sidebar";

const EnhancedTable = ({
  rows,
  columns,
  tableList,
  rowsPerPage: rowsInPage,
  handleChipClick,
  loading,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(rowsInPage || 10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (row) => selected.indexOf(row._id) !== -1;

  const visibleRows = useMemo(() => {
    if (!tableList || tableList?.length === 0) {
      return [];
    }
    return stableSort(rows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [order, orderBy, page, rowsPerPage, tableList]);

  const { collapsed, broken } = useProSidebar();
  return (
    <div
      className="mui-table"
      style={{
        width: broken
          ? "100vw"
          : collapsed
          ? "calc(100vw - 80px)"
          : "calc(100vw - 250px)",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          color: "black !important",
          backgroundColor: "red !important",
          overflow: "hidden",
          // marginLeft: "25px",
          "&::-webkit-scrollbar": {
            height: "50px !important", // Set your desired height
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888", // Color of the scroll thumb
            borderRadius: "10px", // Rounded corners for the scroll thumb
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555", // Color of the scroll thumb on hover
          },
          // For Firefox
          "& html": {
            scrollbarWidth: "thin",
            scrollbarColor: "#888 #ffffff",
          },
          // For Edge and Internet Explorer
          "& body": {
            "-ms-overflow-style": "scrollbar",
          },
        }}
      >
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}

        <TableContainer
          component={Paper}
          sx={{ maxHeight: "auto", width: "100%" }}
          className="custom-scrollbar"
        >
          <Table
            aria-label="sticky table"
            sx={{
              minWidth: 750,
              backgroundColor: "#fff",
              color: "#000",
            }}
            size={dense ? "small" : "medium"}
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              columns={columns}
            />
            {tableList?.length ? (
              <TableBody>
                {visibleRows.map((row, index) => (
                  <EnhancedTableRow
                    key={row._id}
                    row={row}
                    columns={columns}
                    isSelected={isSelected(row)}
                    handleClick={handleChipClick}
                    labelId={`enhanced-table-checkbox-${index}`}
                    tableList={tableList}
                  />
                ))}
              </TableBody>
            ) : (
              <TableRow>
                <TableCell align="center" colSpan="8">
                  Data Not Found
                </TableCell>
              </TableRow>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default EnhancedTable;
