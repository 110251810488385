import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable.js";
import { DeleteIcon, EditIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import DeleteDialog from "../../components/dialog/deletedialog";
import Loader from "../../components/loader/Loader";
import {
  CreatePage,
  DeletePage,
  GetAllPages,
  UpdatePage,
} from "../../redux/action/PagesAction";

const columns = [
  {
    id: "page_name",
    numeric: false,
    disablePadding: true,
    label: "Page Name",
  },

  !!getPermissions(permissions.page_banners, "is_edit") ||
    !!getPermissions(permissions.page_banners, "is_delete")
    ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
    : null,
].filter((el) => el !== null);

const initialValues = {
  page_name: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
];

const AdminPages = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedPage, setSelectedPage] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageLoading,setPageLoading] = useState(false)

  const pagesData = useSelector((state) => state.PagesReducer?.pages?.pageList);

  const { loading, pages, createLoading, updateLoading, deleteLoading } =
    useSelector((state) => state.PagesReducer);

  useEffect(() => {
    dispatch(GetAllPages(page, rowsPerPage));
  }, [page, dispatch]);

  const handleChangeRow = (event) => {
    const newRowPerPage = event.target.value;
    const newPageNumber = 1;
    setRowsPerPage(event.target.value);
    setPage(newPageNumber);

    dispatch(GetAllPages(newPageNumber, newRowPerPage));
  };

  const tableTitle = "Page list";

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPageLoading(false)
    setPopupName("Create Page");
    setOpen(true);
  };
  
  const handleClose = () => {
    setPageLoading(false)
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if (popupName === "Edit Banner") {
      if ("page_name" in fieldValues) {
        if (
          fieldValues.page_name === "" ||
          fieldValues.page_name === undefined ||
          fieldValues.page_name === null
        ) {
          temp.page_name = "Please write name of the page";
        } else {
          temp.page_name = "";
        }
      }
    } else {
      if ("page_name" in fieldValues) {
        if (
          fieldValues.page_name === "" ||
          fieldValues.page_name === undefined ||
          fieldValues.page_name === null
        ) {
          temp.page_name = "Please write name of the page";
        } else {
          temp.page_name = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = async (data) => {
    setSelectedPage(data);
    setErrors({});
    setPopupName("Edit Page");
    setValues({
      ...values,
      page_name: data.page_name || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeletePage(DeleteId, handleCloseDelete)).then((res) => {
      if (res && res.data.status === true) {
        dispatch(GetAllPages(page, rowsPerPage));
      }
    });
  };

  const fields = [
    {
      id: "page_name",
      label: "Page name",
      type: "text",
      value: values.page_name,
      maxLength:20,
      name: "page_name",
      errors: errors.page_name,
    },
  ];

  const rows =
    pagesData && Array.isArray(pagesData)
      ? pagesData?.map((data) => {
        const selected = data === selectedPage;
        return {
          _id: data._id,
          page_name: data.page_name || "-",

          // status: data.is_active ? "approved" : 'pending',
          action: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {!!getPermissions(permissions.page_banners, "is_edit") && (
                <IconButton
                  onClick={() => handleEditClick(data)}
                  sx={{
                    color: "black", "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80"
                    }, "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none"
                    }
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              {!!getPermissions(permissions.page_banners, "is_delete") && (
                <IconButton
                  onClick={() => handleClickOpenDelete(data)}
                  sx={{
                    color: "black", "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80"
                    }, "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none"
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          ),
          selected: selected,
        };
      })
      : [];



  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (validate()) {
      setPageLoading(true)
      const formData = new FormData();
      formData.append("page_name", values.page_name);

      if (popupName === "Edit Page") {
        const res = await dispatch(UpdatePage(selectedPage._id, formData));
        if(res?.data?.status){
          setPageLoading(false)
          handleClose();
          await dispatch(GetAllPages(page, rowsPerPage));
        } else {
          setPageLoading(false)
        }
      } else {
        const res = await dispatch(CreatePage(formData));
        if(res?.data?.status){
          setPageLoading(false)
          handleClose();
          await dispatch(GetAllPages(page, rowsPerPage));
        } else {
          setPageLoading(false)
        }
      }
    }
  };

  return (
    <Box>
      <PageHeader
        title="Pages"
        onAddClick={handleClickOpen}
        isShowAdd={!!getPermissions(permissions.page_banners, "is_add")}
      />

      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          rowsPerPage={rowsPerPage}
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={pagesData}
        />
      )}
      <FormDialog
        open={open}
        disableButton={pageLoading}
        dialogTitle={popupName}
        fields={
          popupName === "Edit Banner"
            ? fields.filter((el, index) => ["page_name"].includes(el.name))
            : fields.filter((el) => !["status"].includes(el.name))
        }
        loading={popupName === "Edit Banner" ? updateLoading : createLoading}
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
      // isAnyFieldNotEmpty={isAnyFieldNotEmpty}
      />

      <DeleteDialog
        open={openDelete}
        isDisable={deleteLoading}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {pages?.currentPage} of {pages?.totalPages} Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={pages?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default AdminPages;
