import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable.js";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateChapter,
  DeleteChapter,
  GetAllChapter,
  UpdateChapter,
} from "../../redux/action/ChapterAction";
import DeleteDialog from "../dialog/deletedialog";
import { GetAllCountry } from "../../redux/action/CountryAction";
import axios from "axios";
import { ApiURL } from "../../redux/common";
import { GetAllStates, cleareAllStates } from "../../redux/action/StateAction";
import { GetAllCities, cleareAllCities } from "../../redux/action/CityAction";
import {
  GetAllPostalCodes,
  clearePostalCodes,
} from "../../redux/action/PostalCodeAction";
import { useNavigate } from "react-router-dom";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import Loader from "../loader/Loader";
import useUserRolePermissions from "../../common/UserPermissions";
import SelectBox from "../../common/SelectBox.js";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const initialValues = {
  chapter_name: "",
  country: "",
  state: "",
  city: "",
  postalcode: "",
  chapter_desc: "",
  is_active: "",
  chapter_image: "",
};
const initialFilterValues = {
  chapter_name: "",
  postalcode: "",
  country_id: "" ,
  state_id: "",
  city_id: "",
  is_active: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
];

const ChapterList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [tempPass, setTempPass] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editImageValue, setEditImageValue] = useState();
  const [isFilterClicked, setIsFilterClicked] = useState(false);

  const [downLoadData, setDownLoadData] = useState("");
  const role = useUserRolePermissions();

  const isFilterFormFilled =
    filterDatas.chapter_name.trim().length !== 0 ||
    filterDatas.postalcode.length !== 0 ||
    filterDatas.city_id ||
    filterDatas.country_id ||
    filterDatas.state_id ||
    filterDatas.is_active;

  const columns = [
    {
      id: "chapter_name",
      numeric: false,
      disablePadding: true,
      label: "Chapter Name",
    },
    {
      id: "postalcode",
      numeric: true,
      disablePadding: false,
      label: "Postal Code",
    },
    {
      id: "city",
      numeric: true,
      disablePadding: false,
      label: "City",
    },
    {
      id: "state",
      numeric: true,
      disablePadding: false,
      label: "State",
    },
    {
      id: "country",
      numeric: true,
      disablePadding: false,
      label: "Country",
    },
    {
      id: "chapter_image",
      numeric: true,
      disablePadding: false,
      label: "Chapter Image",
    },
    {
      id: "is_active",
      numeric: true,
      disablePadding: false,
      label: "Status",
    },
    !!getPermissions(permissions.chapter, "is_edit") ||
    !!getPermissions(permissions.chapter, "is_delete")
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const DownLoadPdf = async () => {
    const chapter_name = filterDatas.chapter_name
      ? filterDatas.chapter_name
      : "";
    const postalcode = filterDatas.postalcode ? filterDatas.postalcode : "";
    try {
      const res = await axios.get(
        `${ApiURL}chapter/download-data?chapter_name=${chapter_name}&postalcode=${postalcode}`
      );
      setDownLoadData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching role data:", error);
      return {};
    }
  };

  useEffect(() => {
    DownLoadPdf();
  }, [isFilterClicked]);

  const statusOptions = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    const chapter_name = filterDatas.chapter_name
      ? filterDatas.chapter_name
      : "";
    const postalcode = filterDatas.postalcode ? filterDatas.postalcode : "";
    const country_id = filterDatas.country_id ? filterDatas.country_id : "";
    const state_id = filterDatas.state_id ? filterDatas.state_id : "";
    const city_id = filterDatas.city_id ? filterDatas.city_id : "";
    const is_active = filterDatas.is_active ? filterDatas.is_active : "";
    setPage(1);
    dispatch(
      GetAllChapter(
        page,
        chapter_name,
        postalcode,
        rowPerPage,
        "",
        is_active,
        country_id,
        state_id,
        city_id
      )
    );
  };

  const { loading, chapter, createLoading, updateLoading, deleteLoading } =
    useSelector((state) => state.ChapterReducer);
  const { allStateData } = useSelector((state) => state.StateReducer);
  const { allCountryData } = useSelector((state) => state.CountryReducer);
  const { allCitiesData } = useSelector((state) => state.CityReducer);
  const { allPostalData } = useSelector((state) => state.PostalCodeReducer);
  const { country, state, city } = useSelector((state) => state.CountryReducer);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let fileTypeArray =
      fieldValues.chapter_image && fieldValues.chapter_image.split(".");
    let fileType = fileTypeArray && fileTypeArray[fileTypeArray.length - 1];
    if (fileTypeArray && fileTypeArray.length >= 1) {
      fileType = fileTypeArray && fileTypeArray[fileTypeArray.length - 1];
    }

    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "image/avif",
      "image/avchd",
      "image/f4v",
      "image/swf",
    ];

    if (popupName === "Edit Chapter") {
      if ("chapter_name" in fieldValues) {
        if (
          fieldValues.chapter_name === "" ||
          fieldValues.chapter_name === undefined ||
          fieldValues.chapter_name === null ||
          fieldValues.chapter_name?.trim() === ""
        ) {
          temp.chapter_name = "Please enter chapter name";
        } else {
          temp.chapter_name = "";
        }
      }
      if ("chapter_desc" in fieldValues) {
        if (
          fieldValues.chapter_desc === "" ||
          fieldValues.chapter_desc === undefined ||
          fieldValues.chapter_desc === null ||
          fieldValues.chapter_desc?.trim() === ""
        ) {
          temp.chapter_desc = "Please enter chapter description";
        } else {
          temp.chapter_desc = "";
        }
      }

      if ("country" in fieldValues) {
        if (
          fieldValues.country === "" ||
          fieldValues.country === undefined ||
          fieldValues.country === null
        ) {
          temp.country = "Please select country";
        } else {
          temp.country = "";
        }
      }

      if ("state" in fieldValues) {
        if (
          fieldValues.state === "" ||
          fieldValues.state === undefined ||
          fieldValues.state === null
        ) {
          temp.state = "Please select state";
        } else {
          temp.state = "";
        }
      }

      if ("city" in fieldValues) {
        if (
          fieldValues.city === "" ||
          fieldValues.city === undefined ||
          fieldValues.city === null
        ) {
          temp.city = "Please select city";
        } else {
          temp.city = "";
        }
      }

      if ("postalcode" in fieldValues) {
        if (
          fieldValues.postalcode === "" ||
          fieldValues.postalcode === undefined ||
          fieldValues.postalcode === null
        ) {
          temp.postalcode = "Please select postal code";
        } else if (/^-?\d+$/.test(fieldValues.postalcode)) {
          temp.postalcode = "Please enter valid postal code";
        } else {
          temp.postalcode = "";
        }
      }
      if ("chapter_image" in fieldValues) {
        if (fileType && !allowedTypes.includes(fileType)) {
          temp.chapter_image = "Please select valid image file";
        } else {
          temp.chapter_image = "";
        }
      }
    } else {
      if ("chapter_name" in fieldValues) {
        if (
          fieldValues.chapter_name === "" ||
          fieldValues.chapter_name === undefined ||
          fieldValues.chapter_name === null ||
          fieldValues.chapter_name?.trim() === ""
        ) {
          temp.chapter_name = "Please enter chapter name";
        } else {
          temp.chapter_name = "";
        }
      }
      if ("chapter_desc" in fieldValues) {
        if (
          fieldValues.chapter_desc === "" ||
          fieldValues.chapter_desc === undefined ||
          fieldValues.chapter_desc === null ||
          fieldValues.chapter_desc?.trim() === ""
        ) {
          temp.chapter_desc = "Please enter chapter description";
        } else {
          temp.chapter_desc = "";
        }
      }

      if ("country" in fieldValues) {
        if (
          fieldValues.country === "" ||
          fieldValues.country === undefined ||
          fieldValues.country === null
        ) {
          temp.country = "Please select country";
        } else {
          temp.country = "";
        }
      }

      if ("state" in fieldValues) {
        if (
          fieldValues.state === "" ||
          fieldValues.state === undefined ||
          fieldValues.state === null
        ) {
          temp.state = "Please select state";
        } else {
          temp.state = "";
        }
      }

      if ("city" in fieldValues) {
        if (
          fieldValues.city === "" ||
          fieldValues.city === undefined ||
          fieldValues.city === null
        ) {
          temp.city = "Please select city";
        } else {
          temp.city = "";
        }
      }

      if ("postalcode" in fieldValues) {
        if (
          fieldValues.postalcode === "" ||
          fieldValues.postalcode === undefined ||
          fieldValues.postalcode === null
        ) {
          temp.postalcode = "Please select postal code";
        } else if (/^-?\d+$/.test(fieldValues.postalcode)) {
          temp.postalcode = "Please enter valid postal code";
        } else {
          temp.postalcode = "";
        }
      }

      if ("chapter_image" in fieldValues) {
        if (
          fieldValues.chapter_image === "" ||
          fieldValues.chapter_image === undefined ||
          fieldValues.chapter_image === null
        ) {
          temp.chapter_image = "This field is required.";
        } else if (fileType && !allowedTypes.includes(fileType)) {
          temp.chapter_image = "Please select valid image file";
        } else {
          temp.chapter_image = "";
        }
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setFieldValues,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  useEffect(() => {
    if (!open) {
      dispatch(GetAllCountry(page, "", rowsPerPage));
    }
  }, [page, dispatch, rowsPerPage, open]);

  useEffect(() => {
    const allValuesSet = Object.values(filterDatas).every(
      (value) => value !== undefined && value !== ""
    );
    if (allValuesSet) {
      const chapter_name = filterDatas.chapter_name
        ? filterDatas.chapter_name
        : "";
      const postalcode = filterDatas.postalcode ? filterDatas.postalcode : "";
      dispatch(GetAllChapter(page, chapter_name, postalcode, rowsPerPage,"","","","",""));
    } else {
      fetchAllChapter();
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (values?.country?._id) {
      dispatch(GetAllStates(values?.country?._id, true));
      dispatch(cleareAllStates());
      dispatch(cleareAllCities());
      dispatch(clearePostalCodes());
      setValues({ ...values, state: "", city: "", postal: "" });
    }
  }, [values?.country]);

  useEffect(() => {
    if (values?.country?._id) {
      dispatch(GetAllStates(values?.country?._id));
    }
  }, [values?.country]);

  useEffect(() => {
    if (values?.country?._id && values?.state?._id) {
      dispatch(GetAllCities(values?.state?._id, true));
      dispatch(cleareAllCities());
      setValues({ ...values, city: "" });
      dispatch(clearePostalCodes());
      setValues({ ...values, postal: "" });
    }
  }, [values?.state]);

  useEffect(() => {
    if (values?.country?._id && values?.state?._id && values?.city?._id) {
      dispatch(GetAllPostalCodes(values?.city?._id, true));
      dispatch(clearePostalCodes());
      setValues({ ...values, postal: "" });
    }
  }, [values?.city]);

  const chapter_name = filterDatas.chapter_name ? filterDatas.chapter_name : "";
  const postalcode = filterDatas.postalcode ? filterDatas.postalcode : "";
  const is_active = filterDatas.is_active ? filterDatas.is_active : "";
  const country_id = filterDatas.country_id ? filterDatas.country_id._id : "";
  const state_id = filterDatas.state_id ? filterDatas.state_id._id : "";
  const city_id = filterDatas.city_id ? filterDatas.city_id._id : "";
  const fetchAllChapter = async () => {
    dispatch(
      GetAllChapter(
        page,
        chapter_name,
        postalcode,
        rowsPerPage,
        "",
        is_active,
        country_id,
        state_id,
        city_id
      )
    );
  };

  const classes = useStyles();

  const tableTitle = "SubscriptionList";

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPopupName("Create Chapter");
    setOpen(true);
    setValues({ ...values, chapter_image: "" });
    setEditImageValue();
  };

  const handleBinClick = () => {
    navigate("/admin-chapter-bin");
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleEditClick = async (data) => {
    setSelectedChapter(data);
    setErrors({});
    setPopupName("Edit Chapter");
    if (data?.chapter_image) {
      setEditImageValue(data?.chapter_image);
    }
    setValues({
      ...values,
      chapter_name: data?.chapter_name || "",
      chapter_desc: data?.chapter_desc || "",
      is_active: data?.is_active,
      country:
        { _id: data?.country_id, country_name: data?.country_name } || "",
      state: { _id: data?.state_id, state_name: data?.state_name } || "",
      city: { _id: data?.city_id, city_name: data?.city_name } || "",
      postalcode:
        { _id: data?.postalcode_id, postal_code: data?.postalcode } || "",
      // chapter_image: data.chapter_image || null,
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    dispatch(GetAllChapter(page, "", "", rowsPerPage, ""));
  };

  const [singleDeleteLoading, setSingleDeleteLoading] = useState(false);

  const handleSingleDelete = async (id) => {
    setSingleDeleteLoading(true);
    try {
      const res = await dispatch(DeleteChapter(id, handleCloseDelete));
      if (res?.data?.status) {
        if (chapter.chapterData.length === 1 && page > 1) {
          setPage((prevPage) => prevPage - 1);
        }
        // setPage(1);
        fetchAllChapter();
      }
      handleClose();
      handleCloseDelete();
      setSingleDeleteLoading(false);
    } catch (error) {
      console.error("Error occurred while deleting business category:", error);
    } finally {
      handleClose();
      handleCloseDelete();
    }
  };

  const fields = [
    {
      id: "chapter_name",
      label: "Chapter Name",
      type: "text",
      maxLength: 20,
      value: values.chapter_name,
      name: "chapter_name",
      errors: errors.chapter_name,
    },
    {
      id: "chapter_desc",
      label: "Chapter Description",
      type: "text",
      maxLength: 150,
      value: values.chapter_desc,
      name: "chapter_desc",
      errors: errors.chapter_desc,
    },
    {
      id: "country",
      label: "Country",
      type: "autocomplete",
      autoCompleteData: allCountryData?.countryData,
      value: values.country,
      name: "country",
      errors: errors.country,
      maxLength: 100,
    },
    {
      id: "state",
      label: "State",
      type: "autocomplete",
      autoCompleteData: allStateData,
      value: values.state,
      labelAccess: "state_name",
      name: "state",
      errors: errors.state,
    },
    {
      id: "city",
      label: "City",
      type: "autocomplete",
      autoCompleteData: allCitiesData,
      labelAccess: "city_name",
      value: values.city,
      name: "city",
      errors: errors.city,
    },
    {
      id: "postalcode",
      label: "Postal Code",
      type: "autocomplete",
      autoCompleteData: allPostalData,
      labelAccess: "postal_code",
      value: values.postalcode,
      name: "postalcode",
      errors: errors.postalcode,
    },
    {
      id: "is_active",
      label: "Status",
      type: "select",
      options: [
        { value: "true", label: "ACTIVE" },
        { value: "false", label: "INACTIVE" },
      ],
      value: values.is_active,
      name: "is_active",
      errors: errors.is_active,
    },
    {
      id: "chapter_image",
      type: "file",
      labelAccess: "chapter_image",
      value: values.chapter_image,
      src: values.file ? values.file : editImageValue,
      name: "chapter_image",
      errors: errors.chapter_image,
    },
  ];

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
     setIsFilterClicked((prev) => !prev);
    setPage(1);
    dispatch(GetAllChapter(1, "", "", rowsPerPage, "", "", "", "", ""));
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };

  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };

  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleLocationChange = (name, value) => {
    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
   setIsFilterClicked((prev) => !prev);
    const body = {
      chapter_name: filterDatas.chapter_name,
      postalcode: filterDatas.postalcode,
      country_id: filterDatas.country_id ? filterDatas.country_id : "",
      state_id: filterDatas.state_id ? filterDatas.state_id : "",
      city_id: filterDatas.city_id ? filterDatas.city_id : "",
      is_active: filterDatas.is_active ? filterDatas.is_active : "",
    };
    setFilterDatas(body);
    const chapter_name = filterDatas.chapter_name
      ? filterDatas.chapter_name
      : "";
    const postalcode = filterDatas.postalcode ? filterDatas.postalcode : "";
    const country_id = filterDatas.country_id ? filterDatas.country_id._id : "";
    const state_id = filterDatas.state_id ? filterDatas.state_id._id : "";
    const city_id = filterDatas.city_id ? filterDatas.city_id._id : "";
    const is_active = filterDatas.is_active ? filterDatas.is_active : "";
    setPage(1);
    dispatch(
      GetAllChapter(
        page,
        chapter_name,
        postalcode,
        rowsPerPage,
        "",
        is_active,
        country_id,
        state_id,
        city_id
      )
    );
    closeFilterDrawer1();
  };

  const rows =
    chapter && Array.isArray(chapter?.chapterData)
      ? chapter?.chapterData.map((data) => {
          const selected = data === selectedChapter;
          return {
            _id: data._id,
            chapter_name: data.chapter_name,
            postalcode: data.postalcode,
            city: data.city_name,
            state: data.state_name,
            country: data.country_name,
            chapter_image: (
              <a target="_blank" href={data.chapter_image} rel="noreferrer">
                <img
                  style={{
                    width: "70px",
                    maxHeight: "70px",
                    objectFit: "cover",
                  }}
                  src={data.chapter_image}
                  alt="GGC"
                  className={classes.logo}
                />
              </a>
            ),
            is_active: data?.is_active ? "Active" : "inActive",
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {!!getPermissions(permissions.chapter, "is_edit") && (
                  <IconButton
                    onClick={() => handleEditClick(data)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {!!getPermissions(permissions.chapter, "is_delete") && (
                  <IconButton
                    onClick={() => handleClickOpenDelete(data)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const formData = new FormData();
      if (popupName === "Edit Chapter") {
        formData.append("is_active", values.is_active);
      }
      formData.append(
        "chapter_image",
        values.file ? values.file : editImageValue
      );
      formData.append("chapter_name", values.chapter_name);
      formData.append("chapter_desc", values.chapter_desc);
      formData.append("country_id", values.country._id);
      formData.append("state_id", values.state._id);
      formData.append("city_id", values.city._id);
      formData.append("postalcode_id", values.postalcode._id);

      if (popupName === "Edit Chapter") {
        dispatch(UpdateChapter(selectedChapter._id, formData, handleClose));
        setPage(1);
        dispatch(GetAllChapter(1, "", "", rowsPerPage, ""));
      } else {
        dispatch(CreateChapter(formData, handleClose));
        setPage(1);
        // dispatch(GetAllChapter(1, "", "", rowsPerPage, ""));
      }
    }
  };

  const isAnyFieldFilled = Object.values(filterDatas).some((value) =>
    Boolean(value)
  );

  return (
    <Box>
      <PageHeader
        title="Chapters"
        onAddClick={handleClickOpen}
        btnText={"Chapter Bin"}
        onBtnClick={handleBinClick}
        onFilterClick={openFilterDrawer}
        isShowAdd={!!getPermissions(permissions.chapter, "is_add")}
        loading={loading}
        href={downLoadData}
      />

      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          rowsPerPage={rowsPerPage}
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={chapter?.chapterData}
        />
      )}
      <FormDialog
        open={open}
        dialogTitle={popupName}
        fields={
          popupName === "Edit Chapter"
            ? fields.filter((el, index) =>
                [
                  "chapter_name",
                  "chapter_desc",
                  "country",
                  "state",
                  "city",
                  "postalcode",
                  "is_active",
                  "chapter_image",
                ].includes(el.name)
              )
            : fields.filter((el) => !["is_active"].includes(el.name))
        }
        loading={popupName === "Edit Chapter" ? updateLoading : createLoading}
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
      />

      <DeleteDialog
        open={openDelete}
        loading={singleDeleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {chapter?.currentPage} of {chapter?.totalPages} Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={chapter?.totalPages === null ? 1 : chapter?.totalPages}
          page={page === null ? 1 : page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  disabled={isAnyFieldFilled && !filterDatas.chapter_name}
                  variant="outlined"
                  color="primary"
                  label="Chapter Name"
                  name="chapter_name"
                  value={filterDatas.chapter_name}
                  onChange={handleChange}
                  maxLength={200}
                />
              </Box>
              <Box>
                <Input
                  disabled={isAnyFieldFilled && !filterDatas.postalcode}
                  variant="outlined"
                  color="primary"
                  label="Postal Code"
                  name="postalcode"
                  value={filterDatas.postalcode}
                  onChange={handleChange}
                  maxLength={20}
                  type="number"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
              </Box>
              <Box>
                <Autocomplete
                  id="country"
                  name="country_id"
                  value={filterDatas?.country_id || null}
                  // classes={customStyles}
                  onChange={(e, value) => {
                    handleLocationChange("country_id", value);
                    if (value) {
                      dispatch(GetAllStates(value ? value?._id : "", true));
                      dispatch(cleareAllStates());
                    }
                  }}
                  options={allCountryData?.countryData || []}
                  autoHighlight
                  // onBlur={formik.handleBlur}
                  getOptionLabel={(option) => option.country_name}
                  sx={{ marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="Country"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
              </Box>
              <Box>
                <Autocomplete
                  id="state"
                  name="state_id"
                  value={filterDatas.state_id || null}
                  onChange={(e, value) => {
                    handleLocationChange("state_id", value);
                    if (value) {
                      dispatch(GetAllCities(value ? value?._id : "", true));
                      dispatch(cleareAllCities());
                    }
                  }}
                  options={allStateData || []}
                  autoHighlight
                  getOptionLabel={(option) => option.state_name}
                  sx={{ marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="State"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
              </Box>
              <Box>
                <Autocomplete
                  id="city"
                  name="city_id"
                  value={filterDatas.city_id || null}
                  onChange={(e, value) => {
                    handleLocationChange("city_id", value);
                    if (value) {
                      dispatch(
                        GetAllPostalCodes(value ? value?._id : "", true)
                      );
                      dispatch(clearePostalCodes());
                    }
                  }}
                  options={allCitiesData || []}
                  autoHighlight
                  getOptionLabel={(option) => option.city_name}
                  sx={{ marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#000" }}
                      {...params}
                      label="City"
                      inputProps={{
                        ...params.inputProps,
                        style: { color: "#000" },
                      }}
                    />
                  )}
                />
              </Box>
              <Box>
                <SelectBox
                  name="is_active"
                  label="Status"
                  placeholder="status"
                  ClassName="status-select-filter"
                  value={filterDatas.is_active}
                  onChange={handleChange}
                  options={[
                    { label: "ACTIVE", value: "true" },
                    { label: "INACTIVE", value: "false" },
                  ]}
                />
              </Box>
              <Stack className={classes.buttonFilter}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                  disabled={!isFilterFormFilled}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  disabled={!isFilterFormFilled}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
    </Box>
  );
};

export default ChapterList;
