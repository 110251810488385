import axios from "axios";

function PaymentGateway({ children, afterPayment, enablePayementProcess, amount = 1 }) {
  const data =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const pay = async (amount) => {
    const response = await axios.get(
      "https://globalgarner.community:5000/api/v1/fetch-razorpay-cred"
    );
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: response.data.data.RAZORPAY_KEY_ID, // This is Api key. you will get it from razorpay dashboard > account and settings > API keys
      // key: process.env.REACT_APP_PAYMENT_GATEWAY_KEY, // This is Api key. you will get it from razorpay dashboard > account and settings > API keys
      amount: parseInt(amount * 100),
      currency: "INR", // your 3 letter currency code
      // name: "GGCOMMUNITY", // project or transaction name
      description: "",
      image: "",
      handler: function (response) {
        afterPayment(response); // after payment completes on stripe this function will be called and you can do your stuff
      },
      prefill: {
        name: data?.admin?.name || data?.user?.name || "",
        email: data?.admin?.email || data?.user?.email || "",
        contact: data?.admin?.mobile_number || data?.user?.mobile_number || "",
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#158993",
      },
      modal: {
        ondismiss: function () {
          enablePayementProcess()
          // Additional logic after modal is closed
        }
      }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div
      onClick={() => {
        pay(amount);
      }}
    >
      {children || <button className="button_pay">Pay with Razorpay</button>}
    </div>
  );
}

export default PaymentGateway;
