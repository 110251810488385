import React, { useEffect, useState } from "react";
import HomeOurDirectory from "../../components/slides/HomeOurDirectory/HomeOurDirectory";
import HomeArticles from "../../components/slides/HomeArticles";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ClientReview from "../../components/clientReview/ClientReview";
import HomeCarousel from "../../components/homeTopSlider/HomeCarousel";
import SearchComponent from "../../components/search/SerachComponent";
// import Image1 from "../../assets/image/Be_Partner/Image_11.png";

import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  // CardActionArea,
  CardContent,
  // CardMedia,
  Divider,
  Grid,
  // IconButton,
  Typography,
} from "@mui/material";
import Home3 from "../../assets/image/home/Be_a_member.png";
import Home1 from "../../assets/image/home/Be_a_Partner.png";
// import Home2 from "../../assets/image/home/Business.png";
import { useNavigate } from "react-router-dom";
import JoinWithUs from "../../components/joinWithUs/JoinWithUs";
import BePartnerPageNavigation from "../../components/BePartnerPageNavigation/BePartnerPageNavigation";
import PeopleIcon from "@mui/icons-material/People";
import { api } from "../../config/api";
import { ApiURL } from "../../../redux/common";
import axios from "axios";
import Slider from "react-slick";
import Loader from "../../components/loader/Loader";


import QuickSteps from "../SeeHowItWorks/QuickStart";
import {Banner} from '../Banners/Banner';
import ServiceDirectory from "../../components/slides/HomeOurDirectory/ServiceDirectory";
import EventDisplay from "../../components/slides/HomeOurDirectory/EventDisplay";


const joinWithUs = [
  {
    img: Home3,
    title: "Be a Member",
    desc: "At our community business, we understand the importance of providing comprehensive support for entrepreneurs and small business owners like you. We offer a range of services to help you thrive in today's competitive market. By becoming a member of our community, you'll gain access to all essential resources in one convenient place, giving you a significant advantage in achieving your entrepreneurial goals.",
    btn_name: "Be a Member",
  },
  {
    img: Home1,
    title: "Be a Partner",
    desc: "Are you ready to elevate your business to new heights? Join the Global Garner Community, where local meets global, and success knows no bounds. As the proud initiative of Global Garner Sales Service Limited, a trailblazer that has achieved a remarkable 6650 crore valuation in just 7 years, we bring you a unique platform that transcends geographical boundaries and propels your business towards unprecedented growth.",
    btn_name: "Be a Partner",
  },
];

const Home = () => {
  // const navigate = useNavigate();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });
  const nav = useNavigate();

  const [buttonStates, setButtonStates] = useState({
    monthly: true,
    yearly: false,
  });
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    className: "hero-banner-arrow",
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  const [chapterAchiever, setChapterAchiever] = useState({});
  const [tendingMember, setTendingMember] = useState({});
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);

  const getBanners = async () => {
    setLoading(true)
    const response = await axios.get(
      `${ApiURL}page-banner/get-all-page-banners?page_name=home`
    );
    
    if (response?.data?.data?.bannerList) {
      setLoading(false)
      setBanners(response?.data?.data?.bannerList);
    }else{
      setLoading(false)
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  const getchapterAchiever = async () => {
    try {
      const res = await api.get(`${ApiURL}user/get-chapter-achiever-info`);
      setChapterAchiever(res.data);
    } catch (error) {
      // throw error;
    }
  };

  useEffect(() => {
    if (token) {
      getchapterAchiever();
    }
  }, []);

  const getTending = async () => {
    try {
      const queryParams = {
        monthly: buttonStates.monthly,
        yearly: buttonStates.yearly,
      };

      const res = await api.get(`${ApiURL}user/chapter-trending-member`, {
        params: queryParams,
      });

      setTendingMember(res.data);
    } catch (error) {
      throw new Error("Error in tending member api", error.message);
    }
  };

  useEffect(() => {
    if (token) {
      getTending();
    }
  }, [buttonStates]);

  // const handleButtonClick = (buttonName) => {
  //   setButtonStates((prevStates) => ({
  //     ...prevStates,
  //     [buttonName]: !prevStates[buttonName],
  //   }));
  // };

  const handleButtonClick = (type) => {
    if (type === "monthly") {
      setButtonStates({
        monthly: true,
        yearly: false,
      });
    } else if (type === "yearly") {
      setButtonStates({
        monthly: false,
        yearly: true,
      });
    }
  };

  const cardVariants = {
    initial: { opacity: 0, y: -150 },
    animate: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  const containerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const handleBeaMemeber = () => {
    nav("/be-member");
  };
  const handleBeaPartner = () => {
    nav("/be-partner");
  };

  const findLocalChapter = () => {
    nav("/find-chapter");
  };

  const token = localStorage.getItem("access_token");

  return (
    <>
      <div className="mt-4 mb-3 home-slider relative z-0">
        <HomeCarousel />
      </div>

      <div className="content-wrap">
        {/*See how it works*/}
        <section className="responsive ma-5" id="section-01" >
          <SearchComponent title="Getting Started is Easy" func={findLocalChapter} />

          <div className="w-full">
              <h3 className="mb-5" >
                <span>See </span>
                <span>How It Works</span>
              </h3>
            <QuickSteps />

            <div className="responsive homeBanner w-full">
              <Banner bannerTitle="Leader Ship" />   
            </div>     
          </div>
        </section>

        {/*Chapter & Member/Partner*/}
        <section className="w-full mb--3" >
          <Grid container>
            {token && (
              <React.Fragment>
                {/*Chapters*/}
                <div className="chapterDiv responsive ma-38">
                  {/*LEFT*/}
                  <Grid className="leftChap">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: '100%',
                        border: '1px solid #d1d1d1',
                        borderRadius: '10px',
                        padding: '10px 0 -8px'
                      }}
                    >
                      <Card
                        sx={{
                          minWidth: "100%",
                          borderRadius: '10px',
                          boxShadow: 'none',
                        }}
                      >

                        {/*CHAPTER*/}
                        <CardContent>
                          <Typography sx={{ fontSize: 20, fontWeight: "800", marginBottom: '8px', padding: '5px 0' }}>
                            My Chapter Achiever's Board
                          </Typography>
                          <Divider />
                       
                           {/*GIVER*/}
                          <Box sx={{ flexGrow: 1, marginTop: "25px" }}>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 12, sm: 12, md: 12 }}
                              sx={{ justifyContent: "center" }}
                            >
                               {/*Title*/}
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                sx={{
                                  justifyContent: "center",
                                  display: "flex",
                                  marginTop: { xs: "20px", md: "42px" },
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: 20, fontWeight: "600" }}
                                >
                                  Givers
                                </Typography>
                              </Grid>

                              {/*Business*/}
                              <Grid item xs={12} sm={4} md={4}>
                                <Card
                                  md={12}
                                  xs={12}
                                  sm={12}
                                  sx={{
                                    backgroundColor: "#e5e5e5",
                                    borderRadius: "15px",
                                    height: "100px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    lineHeight: "40px",
                                    width: "100%", // Adjusted width for responsiveness
                                    marginBottom: "20px", // Added margin for spacing
                                    boxShadow: 'none',
                                  }}
                                >
                                  <Box>
                                    <PeopleIcon
                                      sx={{ fontSize: "30px", color: "#ff6c6c" }}
                                    />
                                  </Box>
                                  <Box sx={{ marginLeft: "10px" }}>
                                    <Badge
                                      badgeContent={
                                        chapterAchiever?.referral_giver_business
                                      }
                                      showZero
                                      color="secondary"
                                      sx={{
                                        "& .MuiBadge-badge": {
                                          backgroundColor: "#0b4078",
                                          top: "-7px",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "600",
                                          fontSize: { xs: "20px" },
                                        }}
                                      >
                                        Business
                                      </Typography>
                                    </Badge>
                                  </Box>
                                </Card>
                              </Grid>

                              {/*Referrals*/}
                              <Grid item xs={12} sm={4} md={4}>
                                <Card
                                  md={12}
                                  xs={12}
                                  sm={12}
                                  sx={{
                                    backgroundColor: "#e5e5e5",
                                    borderRadius: "15px",
                                    height: "100px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    lineHeight: "40px",
                                    width: "100%", // Adjusted width for responsiveness
                                    marginBottom: "20px", // Added margin for spacing
                                    boxShadow: 'none',
                                  }}
                                >
                                  <Box>
                                    <PeopleIcon
                                      sx={{ fontSize: "30px", color: "#ff6c6c" }}
                                    />
                                  </Box>
                                  <Box sx={{ marginLeft: "10px" }}>
                                    <Badge
                                      badgeContent={
                                        chapterAchiever?.referral_giver_count
                                      }
                                      showZero
                                      color="secondary"
                                      sx={{
                                        "& .MuiBadge-badge": {
                                          backgroundColor: "#0b4078",
                                          top: "-7px",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "600",
                                          fontSize: "20px",
                                        }}
                                      >
                                        Referral
                                      </Typography>
                                    </Badge>
                                  </Box>
                                </Card>
                              </Grid>
                            </Grid>
                          </Box>
                          <Divider sx={{ marginTop: "12px" }} />

                           {/*RECIEVER*/}
                          <Box sx={{ flexGrow: 1, marginTop: "25px", marginBottom: '-13px' }}>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 12, sm: 12, md: 12 }}
                              sx={{ justifyContent: "center" }}
                            >
                              {/*Title*/}
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                sx={{
                                  justifyContent: "center",
                                  display: "flex",
                                  marginTop: { xs: "20px", md: "42px" },
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: 20, fontWeight: "600" }}
                                >
                                  Receivers
                                </Typography>
                              </Grid>

                              {/*Business*/}
                              <Grid item xs={12} sm={4} md={4}>
                                <Card
                                  md={12}
                                  xs={12}
                                  sm={12}
                                  sx={{
                                    backgroundColor: "#e5e5e5",
                                    borderRadius: "15px",
                                    height: "100px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    lineHeight: "40px",
                                    width: "100%", // Adjusted width for responsiveness
                                    marginBottom: "20px", // Added margin for spacing
                                    boxShadow: 'none',
                                  }}
                                >
                                  <Box>
                                    <PeopleIcon
                                      sx={{
                                        fontSize: "30px",
                                        color: "#ff6c6c",
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Badge
                                      badgeContent={
                                        chapterAchiever?.referral_receiver_business
                                      }
                                      color="secondary"
                                      showZero
                                      sx={{
                                        "& .MuiBadge-badge": {
                                          backgroundColor: "#0b4078",
                                          top: "-7px",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "600",
                                          fontSize: "20px",
                                        }}
                                      >
                                        Business
                                      </Typography>
                                    </Badge>
                                  </Box>
                                </Card>
                              </Grid>

                              {/*Referrals*/}
                              <Grid item xs={12} sm={4} md={4}>
                                <Card
                                  md={12}
                                  xs={12}
                                  sm={12}
                                  sx={{
                                    backgroundColor: "#e5e5e5",
                                    borderRadius: "15px",
                                    height: "100px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    lineHeight: "40px",
                                    width: "100%", // Adjusted width for responsiveness
                                    marginBottom: "20px", // Added margin for spacing
                                    boxShadow: 'none',
                                  }}
                                >
                                  <Box>
                                    <PeopleIcon
                                      sx={{
                                        fontSize: "30px",
                                        color: "#ff6c6c",
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Badge
                                      badgeContent={
                                        chapterAchiever?.referral_receiver_count
                                      }
                                      showZero
                                      color="secondary"
                                      sx={{
                                        "& .MuiBadge-badge": {
                                          backgroundColor: "#0b4078",
                                          top: "-7px",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "600",
                                          fontSize: "20px",
                                        }}
                                      >
                                        Referral
                                      </Typography>
                                    </Badge>
                                  </Box>
                                </Card>
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>

                  {/*RIGHT*/}
                  <Grid className="rightChap">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: '1px solid #d1d1d1',
                        borderRadius: '10px',
                        padding: '10px 0',
                      }}
                    >
                      <Card
                        sx={{
                          minWidth: "100%",
                          height: {xs:"100%",sm:"100%",md:"100%",lg:"358px"},
                          borderRadius: '10px',
                          boxShadow: 'none'
                        }}
                      >
                        <CardContent>
                          {/*Header*/}
                          <div className="rightChapHead">
                            {/* <div> */}
                              <Typography
                                sx={{
                                  fontSize: { xs: 20, md: 20, lg: 20, xl: 20 },
                                  fontWeight: "800", margin: '-4px 0 4px',
                                }}
                              >
                                Trending Member of Chapter
                              </Typography>
                            {/* </div> */}

                            {/*Monthly/Yearly Button*/}
                            <div>
                              <ButtonGroup
                                variant="contained"
                                aria-label="Basic button group"
                                style={{boxShadow: 'none', marginTop: '-10px', width: '100%'}}
                              >
                                 {/*Monthly Button*/}
                                <Button
                                  variant={
                                    buttonStates.monthly
                                      ? "contained"
                                      : "outlined"
                                  }
                                  sx={{
                                    color: buttonStates.monthly
                                      ? "white"
                                      : "default",
                                    backgroundColor: buttonStates.monthly
                                      ? "#153e80"
                                      : "default",
                                    "&:hover": {
                                      backgroundColor: buttonStates.monthly
                                        ? "#153e80"
                                        : "default.light",
                                      color: buttonStates.monthly ? 'white' : '#153e80',
                                    },
                                  }}
                                  style={{boxShadow: 'none',}}
                                  onClick={() => handleButtonClick("monthly")}
                                >
                                  Monthly
                                </Button>  

                                {/*Yearly Button*/}
                                <Button
                                  variant={
                                    buttonStates.yearly ? "contained" : "outlined"
                                  }
                                  sx={{
                                    color: buttonStates.yearly
                                      ? "white"
                                      : "default",
                                    backgroundColor: buttonStates.yearly
                                      ? "#153e80"
                                      : "default",
                                    "&:hover": {
                                      backgroundColor: buttonStates.yearly
                                        ? "#153e80"
                                        : "default.light",
                                      color: buttonStates.yearly ? 'white' : '#153e80',
                                    },
                                  }}
                                  style={{boxShadow: 'none',}}
                                  onClick={() => handleButtonClick("yearly")}
                                >
                                  Yearly
                                </Button>
                              </ButtonGroup>
                            </div>
                          </div>

                          <Divider />

                          {/*Body according to Plan*/}
                          <Box sx={{ flexGrow: 1, marginTop: "38px", height: "100%" }}>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              <Grid item xs={12} sm={4} md={4} sx={{ display: "flex", justifyContent: "center" }}>
                                {tendingMember?.business_giver?.map((item) => {
                                  return (
                                    <Card
                                      sx={{
                                        margin: "10px",
                                        width: { xs: "100%", sm: "100%", md: "158px", lg: "158px", xl: "158px" },
                                        height: "200px", boxShadow: 'none', border: '1px solid #d1d1d1', borderRadius: '10px',
                                        padding: '7px'
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          marginTop: "15px",
                                          
                                        }}
                                      >
                                        <Avatar
                                          alt="Remy Sharp"
                                          src={
                                            item?.user_details?.profile_picture
                                          }
                                          sx={{ width: 100, height: 100 }}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "flex",
                                          marginTop: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div>
                                          <Typography
                                            sx={{
                                              fontSize: "15px",
                                              fontWeight: "800",
                                              display: "block",
                                              textAlign: 'center',
                                            }}
                                          >
                                            {item?.user_details?.name}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: "15px",

                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            Business Givers
                                          </Typography>
                                        </div>
                                      </Box>
                                    </Card>
                                  );
                                })}
                              </Grid>

                              <Grid item xs={12} sm={4} md={4} sx={{ display: "flex", justifyContent: "center", }}>
                                {tendingMember?.ref_giver?.map((item) => {
                                  return (
                                    <Card
                                      sx={{
                                        margin: "10px",
                                        width: { xs: "100%", sm: "100%", md: "158px", lg: "158px", xl: "158px" },
                                        height: "200px", boxShadow: 'none', border: '1px solid #d1d1d1', borderRadius: '10px',
                                        padding: '7px'
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          marginTop: "15px",
                                        }}
                                      >
                                        <Avatar
                                          alt="Remy Sharp"
                                          src={
                                            item?.user_details?.profile_picture
                                          }
                                          sx={{ width: 100, height: 100 }}
                                        />
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          marginTop: "10px",
                                          justifyContent: "center",
                                          textAlign: 'center',
                                        }}
                                      >
                                        <div>
                                          <Typography
                                            sx={{
                                              fontSize: "15px",
                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            {item?.user_details?.name}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: "15px",

                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            Referral Givers
                                          </Typography>
                                        </div>
                                      </Box>
                                    </Card>
                                  );
                                })}
                              </Grid>

                              <Grid item xs={12} sm={4} md={4} sx={{ display: "flex", justifyContent: "center", }}>
                                {tendingMember?.ref_receiver?.map((item) => {
                                  return (
                                    <Card
                                      sx={{
                                        margin: "10px",
                                        width: { xs: "100%", sm: "100%", md: "158px", lg: "158px", xl: "158px" },
                                        height: "200px", boxShadow: 'none', border: '1px solid #d1d1d1', borderRadius: '10px',
                                        padding: '7px'
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          marginTop: "15px",
                                        }}
                                      >
                                        <Avatar
                                          alt="Remy Sharp"
                                          src={
                                            item?.user_details?.profile_picture
                                          }
                                          sx={{ width: 100, height: 100 }}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "flex",
                                          marginTop: "10px",
                                          justifyContent: "center",
                                          textAlign: 'center',
                                        }}
                                      >
                                        <div>
                                          <Typography
                                            sx={{
                                              fontSize: "15px",

                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            {item?.user_details?.name}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: "15px",
                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            Business Givers
                                          </Typography>
                                        </div>
                                      </Box>
                                    </Card>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </div>

                {/*Be a Member/Partner*/}
                <JoinWithUs
                joinWithUs={joinWithUs}
                navigateToMember={handleBeaMemeber}
                navigateToPartner={handleBeaPartner}
                />

                <div className="responsive ma-6">
                  <Banner bannerTitle="Rectangle" />
                </div>
              </React.Fragment>
            )}
          </Grid>
        </section>
        
        {/*EVENTS*/}
        <HomeOurDirectory />

        {/*SERVICES*/}
        <BePartnerPageNavigation
          text="Services"
          link="/service"
          buttonTitle="Service"
        />

        {/*Client Review*/}
        <section className="mb-5">
          <ClientReview
            cardsPerScreen={2}
            title={
              <h2 className="mb-3">
                <span className="font-weight-bold">Client Reviews </span>
              </h2>
            }
          />
        </section>

        {/*Tips/Articles*/}
        <HomeArticles />
      </div>
    </>
  );
};

export default Home;
