const initialState = {
  loading: false,
  faqcategory: [],
  createFAQCategoryLoading: false,
  updateFAQCategoryLoading: false,
  deleteFAQCategoryLoading: false,
};

function FAQCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case "FAQ_GET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };

    case "FAQ_GET_SUCCESS":
      return {
        ...state,
        faqcategory: action.payload,
        loading: false,
      };

    case "CREATE_LOADING_FAQ":
      return {
        ...state,
        createFAQCategoryLoading: action.payload,
      };

    case "CREATE_FAQ_SUCCESS":
      return {
        ...state,
      };

    case "UPDATE_LOADING_FAQ":
      return {
        ...state,
        updateFAQCategoryLoading: action.payload,
      };
    case "UPDATE_FAQ_SUCCESS":
      return {
        ...state,
      };

    case "DELETE_FAQ_LOADING":
      return {
        ...state,
        deleteFAQCategoryLoading: action.payload,
      };

    case "DELETE_FAQ_SUCCESS":
      return {
        ...state,
      };

    default:
      return state;
  }
}

export default FAQCategoryReducer;
