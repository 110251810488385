import React from "react";

import stemEdu from "../../assets/GG_C_Events/stemEdu.png";
import summit from "../../assets/GG_C_Events/summit.png";
import { Link } from "react-router-dom";

export const Deals = ({ title, handleBtn }) => {
  return (
    <section className="w-full mb-08 mt-5" style={{backgroundColor: '#f1f5f7'}}>
      {/*Head*/}
      <div
        style={{
          borderRadius: "5px",
          padding: "12px 16px",
        }}
        className="flex items-center bg-f1f5f7 justify-between"
      >
        <h4 style={{margin:'auto 0'}}>{title}</h4>

              <div className="pointer">
                <span
                  onClick={handleBtn}
                  className="font-size-md d-inline-block mr-1 text-12 decoration-none"
                  style={{color: '#252525'}}
                >
                  View all
                </span>
                <span>
                  <i className="fal fa-chevron-right" />
                </span>
              </div>
      </div>
    </section>
  );
};
