import axios from "axios";
import { ApiURL, snackBar } from "../common";

export const getWalletDetails =
  (
    page,
    rowPerPage,
    email,
    mobile_number,
    transaction_type,
    user_name,
    month_year,
    transactionId
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_WALLET_LOADING",
        payload: true,
      });

      await axios
        .get(
          `${ApiURL}wallet/get-wallet-transaction?limit=${rowPerPage}&page=${page}&email=${email}&mobile_number=${mobile_number}&transaction_type=${transaction_type}&user_name=${user_name}&month_year=${month_year}&transactionId=${transactionId}`
        )
        .then((res) => {
          if (res.data.status) {
            dispatch({
              type: "GET_WALLET_LOADING",
              payload: false,
            });
            dispatch({
              type: "GET_WALLET_SUCCESS",
              payload: res.data.data,
            });
          } else {
            dispatch({
              type: "GET_WALLET_LOADING",
              payload: false,
            });
            dispatch({
              type: "GET_WALLET_FAIL",
              payload: [],
            });
            // snackBar(res.data.message, res.data.status)
          }
        });
    } catch (error) {
      dispatch({
        type: "GET_WALLET_LOADING",
        payload: false,
      });
      dispatch({
        type: "GET_WALLET_FAIL",
        payload: error,
      });
    }
  };
