import React from "react";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
//import Grid from "@mui/material/Grid";
//import { Typography } from "@mui/material";

import cloudLeft from "../../GGC_LendingPage/svg/cloudLeft.svg";
import cloudRight from "../../GGC_LendingPage/svg/cloudRight.svg";

const ButtonContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "1rem", // Smaller margin top for mobile view
});

const FindChapterBtn = styled(Link)(({ theme }) => ({
  backgroundColor: "#153e80",
  color: "#fff",
  borderRadius: "5px",
  textDecoration: "none",
  transition: "background-color 0.3s ease",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
  "&:hover": {
    backgroundColor: "#0f2b5a",
    color: "white !important",
  },
  fontSize: "80%", // Smaller font size for mobile view
  [theme.breakpoints.up("md")]: {
    fontSize: "90%", // Adjusted font size for larger screens
  },
}));

const StyledContainer = styled("div")({
  background: "#f1f5f6", // Gradient background
  width: "100%", // Full width
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  // marginTop: "0px !important",
  margin: "85px auto",
  padding: "30px 0",
  position: "relative",
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
});

const data = [
  {
    title: "Getting Started is Easy",
    subTitle: "It's fantastic that you want to grow your business using proven networking strategies.",
    btnText: "Find a Local Chapter",
  },
  {
    title: "Be a Member",
    btnText: "Apply Here",
  },
  {
    title: "Be a Partner",
    btnText: "Apply Here",
  },
  {
    title: "Are you a local service professional?",
    subTitle: "Learn how GG COMMUNITY can help you double your business",
    btnText: 'Contact Now'
  },
  {
    title: "Have a question? Contact us now!",
    subTitle: "We are here to help. Checkout our FAQs, send us an email or call us at 1 (800) 555-5555",
    btnText: 'Contact Us'
  },
];

const SearchComponent = ({title, func}) => {
  const navigate = useNavigate();

  return (
    <>
      <StyledContainer>
        {data?.map((data, key) => (
          data?.title === title ? (
            <section key={key}>
            <h3 className="text-center font-weight-bold">
              {data?.title}
            </h3>
            {data?.subTitle && (
              <p className="cloudSubT">{data?.subTitle}</p>
            )}
            <ButtonContainer>
              {/* <FindChapterBtn
          to="/find-chapter"
          tabIndex="0"
          onClick={handleButtonClick}
          sx={{
            paddingTop: ["0.4em", "0.4rem", "0.4rem", "0.4rem"], // Responsive top padding values
            paddingBottom: ["0.4em", "0.4rem", "0.4rem", "0.4rem"], // Responsive bottom padding values
            paddingLeft: ["0.4em", "0.4rem", "0.4rem", "1rem"], // Responsive left padding values
            paddingRight: ["0.4em", "0.4rem", "0.4rem", "1rem"], // Responsive right padding values
            textAlign: {
              xs: "center", // Adjusted font size for mobile view
            },
          }}
        >
              Find a Local Chapter
        </FindChapterBtn> */}
              <button
                type="button"
                className="btnAll"
                onClick={func}
              >
                {data?.btnText}
              </button>
            </ButtonContainer>

            <img
              src={cloudLeft}
              alt="Cloud Design"
              className="hideCloud absolute b-0 l-0"
            />
            <img
              src={cloudRight}
              alt="Cloud Design"
              className="hideCloud absolute b-0 r-0"
            />
          </section>
          ) : null 
        ))}
      </StyledContainer>
    </>
  );
};

export default SearchComponent;
