import React from 'react';
import leaderShip from '../../GGC_LendingPage/png/LeadershipBanner.png';
import serviceBanner from '../../assets/GG_C_Services/servicesBanner.png';
import aboutBanner from '../../assets/GG_C_About/aboutBanner.png';
import blogBanner from '../../assets/GG_C_Blog/blogBanner.png';
import eventBanner from '../../assets/GG_C_Events/eventBanner.png';
import rectBanner from '../../assets/GG_C_Events/RectangleBanner.png';
import faqBanner from '../../assets/GG_C_About/faqBanner.jpg';

const bannerData = [
    {
        title : "Leader Ship",
        img: leaderShip,
    },
    {
        title : "Services",
        img: serviceBanner,
    },
    {
        title : "About Us",
        img: aboutBanner,
    },
    {
        title : "Events",
        img: eventBanner,
    },
    {
        title : "Some Tips & Articles",
        img: blogBanner,
    },
    {
        title : "Rectangle",
        img: rectBanner,
    },
    {
        title : "Frequently asked question",
        img: faqBanner,
    },
];

export const Banner = ({bannerTitle}) => {
    return(
        <section className='ha'>
           {bannerData?.map((data, index) => (
              <figure key={index} className='ha'>
                {data.title === bannerTitle ? (
                    <img src={data?.img} alt={data?.title} className='w-full object-cover'/>
                ) : null }
              </figure>
            ))}
        </section>
    )
};