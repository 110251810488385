import React from "react";
import ellipse from "../../GGC_LendingPage/svg/Ellipse.svg";

const steps = [
      {
        number: "01.",
        title: "Submission",
        description: "Verify your membership eligibility by submitting application",
      },
      {
        number: "02.",
        title: "Activation",
        description: "Activate your membership and get Dedicated RM appointment",
      },
      {
        number: "03.",
        title: "Acceleration",
        description: "Enjoy your membership offerings with community Networking",
      },
];


const QuickSteps = () => {
  return (
    <>
      <section className="w-full ma-289 grid-3">
        {steps?.map((d, i) => {
          return (
            <section key={i}>
              <p className="p-0 text-center text-larger font-weight-bold" style={{color: '#252525'}}>{d?.number}</p>
              <section className="w-full flex relative items-center justify-center mb--25" style={{height: '80px'}}>
                <p
                  className="w-full relative"
                  style={{borderTop: '2px dashed lightgray',}}>
                  <p></p>
                </p>
                <figure className="absolute z-10 t-12" style={{width: '14%', height: '14%'}}>
                  <img
                    src={ellipse}
                    alt=""
                    className="w-full h-full object-contain"
                  />
                </figure>
                <p 
                  className="hidden w-full relative"
                  style={{borderTop: '2px dashed lightgray',}}>
                  <p></p>
                </p>
              </section>
              <p className="p-0 text-center font-weight-bold" style={{color: '#252525'}}>
                {d?.title}
              </p>

              {/* added height */}
              <p className="ma-0 text-medium text-center" style={{height: '60px', color: '#626161', width: '75%'}}>
                {d?.description}
              </p>
            </section>
          );
        })}
      </section>
    </>
  );
};

export default QuickSteps;
