import { api } from "../config/api";


export const GetChapterAll = async () => {
  try {
    const response = await api.get(`chapter/all-chapter`);
    const responseData = response;
    return responseData;
  } catch (error) {
    throw error;
  }
};


export const GetBusinessCategoryAll = async () => {
  try {
    const response = await api.get(`business-category/get-all-category?is_active=${true}`);
    const responseData = response;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const FindSearch = async (business_category_id = '', chapterId_refferalType = '') => {
  try {
    const response = await api.get(`user/all-users?business_category_id=${business_category_id}&chapterId_refferalType=${chapterId_refferalType}`);
    const responseData = response;
    return responseData;
  } catch (error) {
    throw error;
  }
};
