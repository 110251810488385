import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable.js";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateUserManage,
  DeleteUserManage,
  UpdateUserManage,
} from "../../redux/action/UserManageAction";
import {
  CreateChapter,
  RestoreChapter,
  GetBinChapter,
  UpdateChapter,
  FinalDeleteChapter,
} from "../../redux/action/ChapterAction";
import { useSnackbar } from "notistack";
import DeleteDialog from "../dialog/deletedialog";
import {
  GetAllCountry,
  GetCityByState,
  GetCountry,
  GetStateByCountry,
} from "../../redux/action/CountryAction";
import axios from "axios";
import { ApiURL } from "../../redux/common";
import { GetAllStates, cleareAllStates } from "../../redux/action/StateAction";
import { GetAllCities, cleareAllCities } from "../../redux/action/CityAction";
import {
  GetAllPostalCodes,
  clearePostalCodes,
} from "../../redux/action/PostalCodeAction";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));



const initialValues = {
  chapter_name: "",
  country: "",
  state: "",
  city: "",
  postalcode: "",
  chapter_desc: "",
  is_active: "",
  chapter_image: "",
};
const initialFilterValues = {
  chapter_name: "",
  postalcode: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const ChapterBinList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [ChapterId, setChapterId] = useState(null);
  const [openRestore, setOpenRestore] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [DeleteId, SetDeleteId] = useState(null);
  const [popupName, setPopupName] = useState("");
  const [tempPass, setTempPass] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const statusOptions = [
    { value: "approved", label: "Approved" },
    { value: "pending", label: "Pending" },
    { value: "rejected", label: "Rejected" },
  ];
  const [downLoadData, setDownLoadData] = useState("");

  const DownLoadPdf = async () => {
    const chapter_name = filterDatas.chapter_name
      ? filterDatas.chapter_name
      : "";
    const postalcode = filterDatas.postalcode ? filterDatas.postalcode : "";
    try {
      const res = await axios.get(`${ApiURL}chapter/download-data?chapter_name=${chapter_name}&postalcode=${postalcode}`);
      setDownLoadData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching role data:", error);
      return {};
    }
  };

  console.log("downLoadData_19191",downLoadData)

  useEffect(() => {
    DownLoadPdf();
  }, [isFilterClicked]);
  const {
    binLoading,
    binChapter,
    createLoading,
    finalDeleteLoading,
    restoreLoading,
  } = useSelector((state) => state.ChapterReducer);
  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    const search = filterDatas.chapter_name
      ? filterDatas.chapter_name
      : filterDatas.postalcode || "";
      setPage(1)
    dispatch(GetBinChapter(page, search, "", rowPerPage));
  };

  useEffect(() => {
    dispatch(GetBinChapter(page, "", "", rowsPerPage));
  }, [rowsPerPage, page]);

  useEffect(() => {
    dispatch(GetAllCountry(true));
    // dispatch(GetAllStates())
    // dispatch(GetAllCities())
    // dispatch(GetAllPostalCodes())
  }, []);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const tableTitle = "SubscriptionList";

  useEffect(() => {
    if (!open) {
      const search = filterDatas.chapter_name
        ? filterDatas.chapter_name
        : filterDatas.postalcode || "";
      dispatch(GetBinChapter(page, search, "", rowsPerPage));
    }
  }, [page, open, dispatch]);

  useEffect(() => {
    dispatch(GetCountry(page, "", rowsPerPage));
  }, [page]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if (popupName === "Edit Chapter") {
      if ("chapter_name" in fieldValues) {
        if (
          fieldValues.chapter_name === "" ||
          fieldValues.chapter_name === undefined ||
          fieldValues.chapter_name === null ||
          fieldValues.chapter_name?.trim() === ""
        ) {
          temp.chapter_name = "Please enter binChapter name";
        } else {
          temp.chapter_name = "";
        }
      }
    } else {
      if ("chapter_name" in fieldValues) {
        if (
          fieldValues.chapter_name === "" ||
          fieldValues.chapter_name === undefined ||
          fieldValues.chapter_name === null ||
          fieldValues.chapter_name?.trim() === ""
        ) {
          temp.chapter_name = "Please enter binChapter name";
        } else {
          temp.chapter_name = "";
        }
      }
      if ("chapter_desc" in fieldValues) {
        if (
          fieldValues.chapter_desc === "" ||
          fieldValues.chapter_desc === undefined ||
          fieldValues.chapter_desc === null ||
          fieldValues.chapter_desc?.trim() === ""
        ) {
          temp.chapter_desc = "Please enter binChapter description";
        } else {
          temp.chapter_desc = "";
        }
      }

      if ("country" in fieldValues) {
        if (
          fieldValues.country === "" ||
          fieldValues.country === undefined ||
          fieldValues.country === null
        ) {
          temp.country = "Please select country";
        } else {
          temp.country = "";
        }
      }

      if ("state" in fieldValues) {
        if (
          fieldValues.state === "" ||
          fieldValues.state === undefined ||
          fieldValues.state === null
        ) {
          temp.state = "Please select state";
        } else {
          temp.state = "";
        }
      }

      if ("city" in fieldValues) {
        if (
          fieldValues.city === "" ||
          fieldValues.city === undefined ||
          fieldValues.city === null
        ) {
          temp.city = "Please select city";
        } else {
          temp.city = "";
        }
      }

      if ("postalcode" in fieldValues) {
        if (
          fieldValues.postalcode === "" ||
          fieldValues.postalcode === undefined ||
          fieldValues.postalcode === null
        ) {
          temp.postalcode = "Please select postal code";
        } else {
          temp.postalcode = "";
        }
      }

      if ("chapter_image" in fieldValues) {
        if (
          fieldValues.chapter_image === "" ||
          fieldValues.chapter_image === undefined ||
          fieldValues.chapter_image === null
        ) {
          temp.chapter_image = "This field is required.";
        } else {
          temp.chapter_image = "";
        }
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const {
    values,
    setFieldValues,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  const handleEditClick = async (data) => {
    setSelectedChapter(data);
    setErrors({});
    setPopupName("Edit Chapter");
    // const { country, state, city } = await fetchSelectedStateCountry(data?.country, data?.state, data?.city)
    setValues({
      ...values,
      chapter_name: data?.chapter_name || "",
      chapter_desc: data?.chapter_desc || "",
      is_active: data?.is_active || "",
      country:
        { _id: data?.country_id, country_name: data?.country_name } || "",
      state: { _id: data?.state_id, state_name: data?.state_name } || "",
      city: { _id: data?.city_id, city_name: data?.city_name } || "",
      postalcode:
        { _id: data?.postalcode_id, postal_code: data?.postalcode } || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenRestore = (data) => {
    setOpenRestore(true);
    setChapterId(data._id);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    dispatch(GetBinChapter(page, "", "", rowsPerPage));
  };

  const handleCloseRestore = () => {
    setOpenRestore(false);
    dispatch(GetBinChapter(page, "", "", rowsPerPage));
  };

  const handleRestoreChapter = async () => {
    handleCloseRestore();
    dispatch(RestoreChapter(ChapterId, handleCloseRestore));
  };

  const handleSingleDelete = async () => {
    dispatch(FinalDeleteChapter(DeleteId, handleCloseDelete));
  };

  //filter

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(GetBinChapter(1, "", "", rowsPerPage));
    setIsFilterClicked(false)
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const body = {
      chapter_name: filterDatas.chapter_name,
      postalcode: filterDatas.postalcode,
    };
    setIsFilterClicked(true)
    setFilterDatas(body);
    // const search = filterDatas.chapter_name
    //   ? filterDatas.chapter_name
    //   : filterDatas.postalcode || "";

    const chapter_name = filterDatas.chapter_name
      ? filterDatas.chapter_name
      : "";
    const postalcode = filterDatas.postalcode ? filterDatas.postalcode : "";

    setPage(1);
    dispatch(GetBinChapter(page, chapter_name, postalcode, rowsPerPage));
    closeFilterDrawer1();
  };
  const columns = [
    {
      id: "chapter_name",
      numeric: false,
      disablePadding: true,
      label: "Chapter Name",
    },
    {
      id: "postalcode",
      numeric: true,
      disablePadding: false,
      label: "Postal Code",
    },
    {
      id: "city",
      numeric: true,
      disablePadding: false,
      label: "City",
    },
    {
      id: "state",
      numeric: true,
      disablePadding: false,
      label: "State",
    },
    {
      id: "country",
      numeric: true,
      disablePadding: false,
      label: "Country",
    },
    {
      id: "chapter_image",
      numeric: true,
      disablePadding: false,
      label: "Chapter Image",
    },
    {
      id: "is_active",
      numeric: true,
      disablePadding: false,
      label: "Status",
    },
    { id: "action", numeric: true, disablePadding: false, label: "Action" },
  ];
  const rows =
    binChapter && Array.isArray(binChapter?.chapterData)
      ? binChapter?.chapterData.map((data) => {
          // const selected = data === selectedChapter;
          return {
            _id: data._id,
            chapter_name: data.chapter_name,
            postalcode: data.postalcode,
            city: data.city_name,
            state: data.state_name,
            country: data.country_name,
            chapter_image: (
              <img
                style={{ width: "70px" }}
                src={data.chapter_image}
                alt="GGC"
                className={classes.logo}
              />
            ),
            // is_active: data.is_active ? "approved" : 'pending',
            is_active: data?.is_active ? "Active" : "inActive",
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IconButton
                  onClick={() => handleClickOpenRestore(data)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <RestoreOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleClickOpenDelete(data)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (popupName === "Edit Chapter") {
      formData.append("is_active", values.is_active);
    }
    formData.append("chapter_image", values.file);
    formData.append("chapter_name", values.chapter_name);
    formData.append("chapter_desc", values.chapter_desc);
    formData.append("country_id", values.country._id);
    formData.append("state_id", values.state._id);
    formData.append("city_id", values.city._id);
    formData.append("postalcode_id", values.postalcode._id);

    if (validate()) {
      if (popupName === "Edit Chapter") {
        dispatch(UpdateChapter(selectedChapter._id, formData, handleClose));
      } else {
        dispatch(CreateChapter(formData, handleClose));
      }
    }
  };

  const isAnyFieldFilled = Object.values(filterDatas).some((value) =>
    Boolean(value)
  );

  const handleBinClick = () => {
    navigate("/admin-chapter");
  };

  return (
    <Box>
      <PageHeader
        title="Chapter Bin"
        // onAddClick={handleClickOpen}
        btnText={"Chapter"}
        onBtnClick={handleBinClick}
        isShowAdd={false}
        onFilterClick={openFilterDrawer}
        href={downLoadData}
        loading={binLoading}
        backButtonLink={"/admin-chapter"}
      />

      {binLoading ? (
        <Loader />
      ) : (
        <EnhancedTable
          rowsPerPage={rowsPerPage}
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={binChapter?.chapterData}
        />
      )}

      <DeleteDialog
        open={openRestore}
        loading={restoreLoading}
        dialogTitle="Are you sure you want to restore this chapter?"
        handleClose={handleCloseRestore}
        handleDelete={() => handleRestoreChapter(ChapterId)}
      />

      <DeleteDialog
        open={openDelete}
        loading={finalDeleteLoading}
        dialogTitle="Are you sure you want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {binChapter?.currentPage} of {binChapter?.totalPages}{" "}
            Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={binChapter?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  disabled={isAnyFieldFilled && !filterDatas.chapter_name}
                  variant="outlined"
                  color="primary"
                  label="Chapter Name"
                  name="chapter_name"
                  value={filterDatas.chapter_name}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <Input
                  disabled={isAnyFieldFilled && !filterDatas.postalcode}
                  variant="outlined"
                  color="primary"
                  label="Postal Code"
                  name="postalcode"
                  value={filterDatas.postalcode}
                  onChange={handleChange}
                />
              </Box>
              {/* <Box>
                <Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Name"
                  name="name"
                  value={filterDatas.name}
                  onChange={handleChange}
                  maxLength={10}
                />
              </Box>{" "} */}
              <Stack className={classes.buttonFilter}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
    </Box>
  );
};

export default ChapterBinList;
