import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, IconButton } from "@mui/material";
import NoAccountsIcon from "@mui/icons-material/NoAccounts"; // You can choose an appropriate icon

const useStyles = makeStyles({
  container: {
    textAlign: "center",
    padding: "32px", // Increased padding for a larger size
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  icon: {
    fontSize: "96px !important", // Increased font size for a larger icon
    color: "#757575", // Replace with your desired icon color
  },
  message: {
    marginTop: "10px", // Increased margin for more space
    color: "#757575", // Replace with your desired text color
  },
});

const NoUserFound = ({ text }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <IconButton disabled>
        <NoAccountsIcon className={classes.icon} />
      </IconButton>
      <Typography variant="h3" className={classes.message}>
        {text || "No users found."}
      </Typography>
    </Box>
  );
};

export default NoUserFound;
