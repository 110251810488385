import React from "react";
import Button from "@mui/material/Button";
import Zoom from "@mui/material/Zoom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    right: "40px",
    bottom: "40px",
    textAlign: "center",
    marginTop: "20px",
    zIndex: "1999",
    [theme.breakpoints.down('xs')]: {
      right: 3, // Value for xs breakpoint
    },
  },
  button: {
    animation: "$pulse 2s infinite",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    padding: 0,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.1)",
      boxShadow: "0px 6px 14px rgba(0, 0, 0, 0.3)",
    },
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.1)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
}));

const AnimatedCallToAction = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {/* <Zoom in={true} timeout={1000}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          href="https://wa.link/keawa8"
          target="_blank"
          sx={{ background: "#3EA219", borderRadius: 8 }}
        >
          <WhatsAppIcon sx={{ fontSize: 40, color: "#fff" }} />
        </Button>
      </Zoom> */}
    </Box>
  );
};

export default AnimatedCallToAction;
