import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import styled from "@emotion/styled";
import moment from "moment/moment";
import SelectBox from "../../common/SelectBox";
import axios from "../../redux/ApiConfig";
import { ApiURL, snackBar } from "../../redux/common";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import BusinessDocCard from "../businessDocCard/BusinessDocCard";
import dayjs from "dayjs";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const statusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  // { label: "Rejected", value: "rejected" },
];

const statusOptionMemberShip = [
  { label: "Pending", value: "pending" },
  { label: "Ongoing", value: "ongoing" },
  { label: "Delivered", value: "delivered" },
];

const ManageUserDetails = () => {
  const navigate = useNavigate("");

  const state = useLocation();
  const userDetails = state?.state?.partnerData;

  const [status, setStatus] = useState(
    userDetails?.approval_status || "pending"
  );
  const [statusMembership, setStatusMembership] = useState(
    userDetails?.membership_delieverables || []
  );

  const [documents, setDocuments] = useState([]);

  const formattedDate = moment(userDetails?.birth_date).format("DD/MM/YYYY");

  const handleChange = (e) => {
    setStatus(e.target.value);
  };

  const handleChangeMemberShip = (index, e) => {
    const updatedMembership = [...statusMembership];
    updatedMembership[index].status = e.target.value;
    setStatusMembership(updatedMembership);
  };
  const handleSubmitMemberShip = async () => {
    try {
      const formData = new FormData();
      formData.append(
        "membership_delieverables",
        JSON.stringify(statusMembership)
      );
      const res = await axios.put(
        `${ApiURL}user/update/${userDetails._id}`,
        formData
      );

      if (res?.data?.status) {
        snackBar(res?.data?.message, res?.data?.status);
        navigate("/admin-user");
      }
    } catch (err) {
      // Handle error
    }
  };

  const handleSubmit = async () => {
    // update api call
    try {
      const formData = new FormData();
      formData.append("approval_status", status);
      const res = await axios.put(
        `${ApiURL}user/update/${userDetails._id}`,
        formData
      );
      if (res?.data?.status) {
        snackBar(res?.data?.message, res?.data?.status);
        navigate("/admin-user");
      }
    } catch (err) { }
  };

  const getUserDetails = async (id) => {
    try {
      const res = await axios.get(`${ApiURL}user/get-user-by-id/${id}`);
      if (res?.data?.status) {
        setDocuments(res?.data?.data[0]?.business_documents);
      }
    } catch (err) { }
  };

  useEffect(() => {
    if (userDetails?.business?.document_uploaded === true) {
      getUserDetails(userDetails?._id);
    }
  }, []);

  const UserRole =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));

  return (
    <div>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          // backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <ArrowCircleLeftOutlinedIcon
            fontSize="large"
            onClick={() => navigate("/admin-user")}
            sx={{ cursor: "pointer", marginTop: "5px", marginBottom: "5px" }}
          />
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              paddingBottom: "25px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                width: "90%",
                marginTop: "0px",
                height: "100%",
                backgroundColor: "#d1d1d17a",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "black",
                  marginTop: "-11px",
                  padding: "22px 15px",
                }}
              >
                User Details
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  marginTop: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Name
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.first_name || "-"}{" "}
                      {userDetails?.last_name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.email || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Mobile Number
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.mobile_number || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Gender
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.gender || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Birth date
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {formattedDate || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Chapter
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.chapter_name || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Role
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.role || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Status
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.approval_status || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Address Proof
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.address || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Refferal By:
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.referred_by_user || "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Refferal Code:
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.referral_code || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              paddingBottom: "25px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                width: "90%",
                marginTop: "0px",
                height: "100%",
                backgroundColor: "#d1d1d17a",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "black",
                  marginTop: "-11px",
                  padding: "22px 15px",
                }}
              >
                Business Details
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  marginTop: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Name
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.business_name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Owner
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.owner_firstname || "-"}{" "}
                      {userDetails?.business?.owner_lastname || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Mobile Number
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.business_contact || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Email
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.business_email || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Website
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.business_website ?
                        <a
                          href={(!userDetails?.business?.business_website?.startsWith("https://") && !userDetails?.business?.business_website?.startsWith("http://")) ? "https://" + userDetails?.business?.business_website : userDetails?.business?.business_website}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#0099cc !important",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                        // onClick={(e)=>{
                        //   e.preventDefault();
                        //   if(!userDetails?.business?.business_website?.startsWith("https://")) {
                        //     window.open("https://" + userDetails?.business?.business_website, "_blank")
                        //   } else {
                        //     window.open(userDetails?.business?.business_website, "_blank")
                        //   }
                        // }}
                        >
                          View
                          {/* {userDetails?.business?.business_website || "-"} */}
                        </a>
                        :
                        <span>-</span>
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Category
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business_category || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Type
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.business_type || "Private Ltd"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Annual Turnover
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.annual_turnover || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Established Year
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.establish_year || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Document Uploaded
                    </Typography>

                    {userDetails?.business?.document_uploaded === false ? (
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        No
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        Yes
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Address
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.address || "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business Pan Number
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.business_pan || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Business GST Number
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {userDetails?.business?.business_gst || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  sx={{
                    fontWeight: "100",
                    fontSize: "17px",
                    marginTop: "-11px",
                    padding: " 15px",
                    display: "flex",
                    color: "#605a5a",
                  }}
                >
                  Representative
                </Typography>
                {userDetails?.business?.representatives?.map((rows) => {
                  return (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          Name
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "18px",
                            marginTop: "-31px",
                            padding: " 15px",
                            display: "flex",
                            color: "black",
                          }}
                        >
                          {rows?.first_name || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          Email
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "18px",
                            marginTop: "-31px",
                            padding: " 15px",
                            display: "flex",
                            color: "black",
                          }}
                        >
                          {rows?.email || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            fontWeight: "100",
                            fontSize: "17px",
                            marginTop: "-11px",
                            padding: " 15px",
                            display: "flex",
                            color: "#605a5a",
                          }}
                        >
                          Mobile Number
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "18px",
                            marginTop: "-31px",
                            padding: " 15px",
                            display: "flex",
                            color: "black",
                          }}
                        >
                          {rows?.mobile_number || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {userDetails?.business?.document_uploaded && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                paddingBottom: "25px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  width: "90%",
                  marginTop: "0px",
                  height: "100%",
                  backgroundColor: "#d1d1d17a",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    marginTop: "10px",
                    marginBottom: "20px",
                    marginLeft: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "20px",
                      color: "black",
                      marginTop: "-11px",
                      padding: "22px 0px",
                    }}
                  >
                    Business Documents
                  </Typography>
                  <Grid container spacing={1}>
                    {documents && documents[0]?.gst_business_document && (
                      <BusinessDocCard
                        data={{
                          business_document:
                            documents && documents[0]?.gst_business_document,
                          document_type: "GST",
                          status: documents && documents[0]?.gst_status,
                          statusKey: "gst_status",
                          _id: documents && documents[0]?._id,
                          link: documents && documents[0]?.gst_business_document,
                        }}
                      />
                    )}
                    {documents && documents[0]?.pan_business_document && (
                      <BusinessDocCard
                        data={{
                          business_document:
                            documents && documents[0]?.pan_business_document,
                          document_type: "PAN",
                          status: documents && documents[0]?.pan_status,
                          statusKey: "pan_status",
                          _id: documents && documents[0]?._id,
                          link: documents && documents[0]?.pan_business_document,
                        }}
                      />
                    )}
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}

      {userDetails?.membership === null ? (
        <></>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                paddingBottom: "25px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  width: "90%",
                  marginTop: "0px",
                  height: "100%",
                  backgroundColor: "#d1d1d17a",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "20px",
                    color: "black",
                    marginTop: "-11px",
                    padding: "22px 15px",
                  }}
                >
                  Membership Details
                </Typography>
                <Box
                  sx={{
                    flexGrow: 1,
                    marginTop: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Membership Name
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.membership_name || "-"}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Monthly Price
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.monthly_price || "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Max Range Amount
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.max_range_amount || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Min Range Amount
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.min_range_amount || "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Range type
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.range_type || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Yearly Price
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.yearly_price || "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Discount
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership?.discount || "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Activated Date
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership_validity?.activate_at ? 
                          dayjs(userDetails?.membership_validity?.activate_at).format('DD/MM/YYYY') :
                           "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Expired Date
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "-31px",
                          padding: " 15px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        {userDetails?.membership_validity?.expire_at ? 
                          dayjs(userDetails?.membership_validity?.expire_at).format('DD/MM/YYYY') :
                           "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "100",
                          fontSize: "17px",
                          marginTop: "-11px",
                          padding: " 15px",
                          display: "flex",
                          color: "#605a5a",
                        }}
                      >
                        Description
                      </Typography>
                      <div>
                        {statusMembership.map((item, index) => {
                          let chipColor;

                          switch (item?.status?.toLowerCase()) {
                            case "pending":
                              chipColor = "orange";
                              break;
                            case "ongoing":
                              chipColor = "green";
                              break;
                            case "reject":
                              chipColor = "red";
                              break;
                            default:
                              chipColor = "";
                          }

                          return (
                            <div key={index}>
                              <Grid container spacing={2}>
                                <Grid item xs={6} md={6}>
                                  <Typography
                                    key={`${index}-title`}
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      marginTop: "-31px",
                                      padding: " 15px",
                                      display: "flex",
                                      color: "black",
                                      wordBreak: "break-all", // Breaks long words onto the next line
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item.title || "-"}
                                  </Typography>

                                  {item?.["sub-points"]?.map(
                                    (row, subIndex) => (
                                      <div key={`${index}-${subIndex}`}>
                                        <Typography
                                          sx={{
                                            fontWeight: "200",
                                            fontSize: "18px",
                                            marginTop: "-31px",
                                            padding: " 15px",
                                            display: "flex",
                                            color: "black",
                                            wordBreak: "break-all", // Breaks long words onto the next line
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          -{row || "-"}
                                        </Typography>
                                      </div>
                                    )
                                  )}
                                </Grid>
                                {UserRole?.user?.user_role?.role ===
                                  "Community Super Admin" ||
                                  UserRole?.user?.user_role?.role ===
                                  "Community Admin" ? (
                                  <>
                                    <Grid
                                      item
                                      xs={6}
                                      md={6}
                                      sx={{ marginTop: "-20px" }}
                                    >
                                      <SelectBox
                                        sx={{ width: "50%" }}
                                        type="text"
                                        id="approval_status"
                                        name="approval_status"
                                        label="Status"
                                        ClassName="range-select-filter"
                                        value={statusMembership[index].status}
                                        onChange={(e) =>
                                          handleChangeMemberShip(index, e)
                                        }
                                        options={statusOptionMemberShip}
                                      />
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Chip
                                      label={item.status}
                                      sx={{
                                        backgroundColor: chipColor,
                                        color: "white",
                                      }}
                                    />
                                  </>
                                )}
                              </Grid>
                            </div>
                          );
                        })}
                        {UserRole && UserRole?.user?.user_role?.role !== "Community Member" && (
                          <Button
                            onClick={handleSubmitMemberShip}
                            sx={{
                              marginTop: "15px",
                              fontSize: "14px",
                              fontWeight: "600",
                              border: "0",
                              ":hover": {
                                bgcolor: "#1976d2",
                                border: "0",
                              },
                              backgroundColor: "#153e80",
                              marginLeft: {
                                xs: "0px",
                                sm: "15px",
                                md: "15px",
                                lg: "15px",
                              },
                              color: "#fff",
                              textTransform: "none",
                              minWidth: "100px",
                            }}
                          >
                            Submit
                          </Button>
                        )}
                      </div>

                      <Box
                        sx={{
                          flexGrow: 1,
                          marginTop: "10px",
                          marginBottom: "20px",
                          marginLeft: "10px",
                        }}
                      ></Box>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}

      {UserRole && UserRole?.user?.user_role?.role !== "Community Member" && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                paddingBottom: "25px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  width: "90%",
                  marginTop: "0px",
                  height: "100%",
                  backgroundColor: "#d1d1d17a",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    marginTop: "10px",
                    marginBottom: "20px",
                    marginLeft: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SelectBox
                        type="text"
                        id="approval_status"
                        name="approval_status"
                        label="Approval Status"
                        ClassName="range-select-filter"
                        value={status}
                        onChange={handleChange}
                        options={statusOptions}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        onClick={handleSubmit}
                        // disabled={!isAnyFieldNotEmpty}
                        sx={{
                          marginTop: "15px",
                          fontSize: "14px",
                          fontWeight: "600",
                          border: "0",
                          // color: isAnyFieldNotEmpty ? "#fff" : "#fff !important",
                          ":hover": { bgcolor: "#1976d2", border: "0" },
                          backgroundColor: "#153e80",
                          marginLeft: {
                            xs: "0px",
                            sm: "15px",
                            md: "15px",
                            lg: "15px",
                          },
                          color: "#fff",
                          textTransform: "none",
                          minWidth: "100px",
                          // opacity: isAnyFieldNotEmpty ? 1 : 0.5,
                          // pointerEvents: isAnyFieldNotEmpty ? "auto" : "none",
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default ManageUserDetails;
