import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable.js";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useUserRolePermissions from "../../common/UserPermissions";
import {
  CreateFAQ,
  DeleteFAQ,
  GetAllFAQ,
  UpdateFAQ,
} from "../../redux/action/FaqCategoryAction";
import Loader from "../../components/loader/Loader";
import DeleteDialog from "../../components/dialog/deletedialog";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";

const columns = [
  {
    id: "category_name",
    numeric: false,
    disablePadding: true,
    label: "Category Name",
  },

  !!getPermissions(permissions.faqs, "is_edit") ||
  !!getPermissions(permissions.faqs, "is_delete")
    ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
    : null,
].filter((el) => el !== null);

const initialValues = {
  category_name: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
];

const AdminFAQsCategory = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedClient, setSelectedBanner] = useState(null);

  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDeleteSubmitting, setIsDeleteSubmitting] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    loading,
    faqcategory,
    createFAQCategoryLoading,
    updateFAQCategoryLoading,
    deleteFAQCategoryLoading,
  } = useSelector((state) => state?.FAQCategoryReducer);

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1)
    dispatch(GetAllFAQ(page, rowPerPage));
  };

  const fetchGetAllFAQS = async () => {
    dispatch(GetAllFAQ(page, rowsPerPage));
  };

  useEffect(() => {
    fetchGetAllFAQS();
  }, [page, rowsPerPage]);

  const tableTitle = "FAQ's CategoryList";

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPopupName("Create FAQ's category");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("category_name" in fieldValues) {
      if (
        fieldValues.category_name === "" ||
        fieldValues.category_name === undefined ||
        fieldValues.category_name === null ||
        fieldValues.category_name?.trim() === ""
      ) {
        temp.category_name = "Please enter category name";
        setCreateFAQLoading(false);
      } else if (fieldValues.category_name?.length < 3) {
        temp.category_name = "Please enter category name at least 3 character";
        setCreateFAQLoading(false);
      } else if (fieldValues.category_name?.trim().length >= 50) {
        temp.category_name = `Max length of category name should be 50`;
        setCreateFAQLoading(false);
      } else {
        temp.category_name = "";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = async (data) => {
    setSelectedBanner(data);
    setErrors({});
    setPopupName("Edit FAQ's category");

    setValues({
      ...values,
      category_name: data?.category_name || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setIsDeleteSubmitting(false);
  };

  const handleSingleDelete = async () => {
    setIsDeleteSubmitting(true);
    try {
      const res = await dispatch(DeleteFAQ(DeleteId, handleCloseDelete));
      if (res && res.data.status === true) {
        fetchGetAllFAQS();
      }
    } catch (error) {
      console.error("Error deleting FAQ:", error);
    }
  };

  const fields = [
    {
      id: "category_name",
      label: "Category Name",
      type: "text",
      maxLength: 50,
      value: values.category_name,
      name: "category_name",
      errors: errors.category_name,
    },
  ];

  const rows =
    faqcategory?.categoryList && Array.isArray(faqcategory?.categoryList)
      ? faqcategory?.categoryList?.map((data) => {
          const selected = data === selectedClient;
          return {
            _id: data._id,
            category_name: data.category_name,

            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {!!getPermissions(permissions.faqs, "is_edit") && (
                  <IconButton
                    onClick={() => handleEditClick(data)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {!!getPermissions(permissions.faqs, "is_delete") && (
                  <IconButton
                    onClick={() => handleClickOpenDelete(data)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const [createFAQLoading, setCreateFAQLoading] = useState(false);

  const handleSubmit = async (e) => {
    setCreateFAQLoading(true);
    const body = {
      category_name: values.category_name,
    };

    if (validate()) {
      if (popupName === "Edit FAQ's category") {
        const res = await dispatch(
          UpdateFAQ(selectedClient._id, body, handleClose)
        );
        if (res && res?.data?.status) {
          fetchGetAllFAQS();
          setCreateFAQLoading(false);
        } else {
          setCreateFAQLoading(false);
        }
      } else {
        const res = await dispatch(CreateFAQ(body, handleClose));
        if (res?.data?.status) {
          setCreateFAQLoading(false);
          fetchGetAllFAQS();
        } else {
          setCreateFAQLoading(false);
        }
      }
    }
  };

  return (
    <Box>
      <PageHeader
        title="FAQ's Category"
        onAddClick={handleClickOpen}
        isShowAdd={true}
      />

      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          rowsPerPage={rowsPerPage}
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={faqcategory?.categoryList}
        />
      )}

      <FormDialog
        open={open}
        dialogTitle={popupName}
        fields={
          popupName === "Edit FAQ's category"
            ? fields.filter((el, index) => ["category_name"].includes(el.name))
            : fields.filter((el) => ["category_name"].includes(el.name))
        }
        loading={
          popupName === "Edit FAQ's category"
            ? createFAQLoading
            : createFAQLoading
        }
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
      />

      <DeleteDialog
        open={openDelete}
        loading={isDeleteSubmitting}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {faqcategory?.currentPage} of {faqcategory?.totalPages}{" "}
            Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={faqcategory?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default AdminFAQsCategory;
