import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../pageHeader/PageHeader";
import EnhancedTable from "../table/EnhancedTable.js";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "../dialog/deletedialog";
import SelectBox from "../../common/SelectBox";
import { GetAllCountry } from "../../redux/action/CountryAction";
import { GetAllStates, cleareAllStates } from "../../redux/action/StateAction";
import { GetAllCities, cleareAllCities } from "../../redux/action/CityAction";
import {
  CreatePostal,
  clearePostalCodes,
} from "../../redux/action/PostalCodeAction";
import {
  CreateBlog,
  DeleteBlog,
  GetBlogs,
  UpdateBlog,
} from "../../redux/action/BlogAction";
import useUserRolePermissions from "../../common/UserPermissions";
import axios from "axios";
import { ApiURL } from "../../redux/common";
import { useNavigate } from "react-router-dom";
import { removeHtmlTags } from "../../common/HtmlToString";
import Loader from "../loader/Loader";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import { GetAllBlog } from "../../web/redux/blogAction.js";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const initialValues = {
  title: "",
  description: "",
};
const initialFilterValues = {
  blog_name: "",
  is_active: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
];

const Blog = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectPostal, setselectPostal] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFilterClicked, setIsFilterClicked] = useState(false);

  const userRoleData = useUserRolePermissions("Blog");
  const [downLoadData, setDownLoadData] = useState("");

  const isEditAllowed = getPermissions(permissions.blog, "is_edit");
  const isDeleteAllowed = getPermissions(permissions.blog, "is_delete");
  const isAddAllowed = getPermissions(permissions.blog, "is_add");

  const columns = [
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Title",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: "Description",
    },
    {
      id:"createdAt",
      numeric: false,
      disablePadding: true,
      label:"Created Date"
    },
    {
      id: "is_active",
      numeric: true,
      disablePadding: false,
      label: "Is Active",
    },
    isEditAllowed || isDeleteAllowed
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    const title = filterDatas.blog_name;
    const blog_status = filterDatas.is_active ? filterDatas.is_active : "";
    setPage(1)
    dispatch(GetBlogs(page, title, rowPerPage, blog_status));
  };

  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, updateLoading, createLoading, deleteLoading, blogData } =
    useSelector((state) => state.BlogReducer);

  const tableTitle = "Blog Post";

  useEffect(() => {
    if (!open) {
      const title = filterDatas.blog_name;
      const blog_status = filterDatas.is_active
      dispatch(GetBlogs(page, title, rowsPerPage, blog_status));
    }
  }, [dispatch, open, page, rowsPerPage]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    navigate("/admin-blog/create-blog", {
      state: { formName: "Create Blog", eventData: {} },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if (dialogTitle === "Update Blog") {
      if ("title" in fieldValues) {
        if (
          fieldValues.title === "" ||
          fieldValues.title === undefined ||
          fieldValues.title === null ||
          fieldValues.title?.trim() === ""
        ) {
          temp.title = "Please enter title";
        } else {
          temp.title = "";
        }
      }
      if ("description" in fieldValues) {
        if (
          fieldValues.description === "" ||
          fieldValues.description === undefined ||
          fieldValues.description === null ||
          fieldValues.description?.trim() === ""
        ) {
          temp.description = "Please enter description";
        } else {
          temp.description = "";
        }
      }
    } else {
      if ("title" in fieldValues) {
        if (
          fieldValues.title === "" ||
          fieldValues.title === undefined ||
          fieldValues.title === null ||
          fieldValues.title?.trim() === ""
        ) {
          temp.title = "Please enter title";
        } else {
          temp.title = "";
        }
      }
      if ("description" in fieldValues) {
        if (
          fieldValues.description === "" ||
          fieldValues.description === undefined ||
          fieldValues.description === null ||
          fieldValues.description?.trim() === ""
        ) {
          temp.description = "Please enter description";
        } else {
          temp.description = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleEditClick = (postal) => {
    navigate("/admin-blog/create-blog", {
      state: { formName: "Update Blog", eventData: postal },
    });
    setselectPostal(postal);
    setErrors({});
  };

  const handleClickOpenDelete = (postal) => {
    setOpenDelete(true);
    SetDeleteId(postal._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeleteBlog(DeleteId, handleCloseDelete)).then((res) => {
      if (res && res.data.status === true) {
        dispatch(GetBlogs(page, "", rowsPerPage));
      }
    });
  };

  const fields = [
    {
      id: "title",
      label: "Title",
      type: "text",
      value: values.title,
      name: "title",
      errors: errors.title,
      maxLength: 50,
    },
    {
      id: "description",
      label: "Description",
      type: "text",
      value: values.description,
      name: "description",
      errors: errors.description,
      maxLength: 1000,
      rows: 5,
    },
    {
      id: "is_active",
      label: "Is Active",
      type: "select",
      options: [
        { value: "true", label: "ACTIVE" },
        { value: "false", label: "INACTIVE" },
      ],
      value: values.is_active,
      name: "is_active",
      errors: errors.is_active,
    },
  ];

  //filter
  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(GetBlogs(1, "", rowsPerPage,""));
    setIsFilterClicked(false)
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const nameRegex = /^[A-Za-z][A-Za-z\s.]*$/

    if(name === "blog_name" && value && !nameRegex.test(value)){
      return;
    }
    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setIsFilterClicked(true)
    const title = filterDatas.blog_name;
    const membership_status = filterDatas.is_active;
    DownLoadPdf(title, membership_status);
    setPage(1);
    dispatch(GetBlogs(page, title, rowsPerPage,membership_status));
    closeFilterDrawer1();
  };

  const rows =
    blogData && Array.isArray(blogData?.blogData)
      ? blogData?.blogData.map((blog) => {
          return {
            _id: blog._id,
            title: blog.title || "-",
            description:
              (blog?.description?.length > 60
                ? removeHtmlTags(blog?.description)?.slice(0, 60) + "..."
                : removeHtmlTags(blog?.description)) || "-",
            is_active: blog.is_active ? "Active" : "inActive",
            createdAt: moment(blog.createdAt).format("DD/MM/YYYY"),
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {isEditAllowed ? (
                  <IconButton
                    onClick={() => handleEditClick(blog)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                ) : null}
                {isDeleteAllowed ? (
                  <IconButton
                    onClick={() => handleClickOpenDelete(blog)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null}
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const isAnyFieldFilled = Object.values(filterDatas).some((value) =>
    Boolean(value)
  );

  const DownLoadPdf = async () => {
    const title = filterDatas.blog_name;
    const is_active = filterDatas.is_active;
    try {
      const res = await axios.get(
        `${ApiURL}blog/download-data?title=${title}&is_active=${is_active}`
      );
      setDownLoadData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching role data:", error);
      return {};
    }
  };

  useEffect(() => {
    DownLoadPdf();
  }, [isFilterClicked]);

  return (
    <Box>
      <PageHeader
        title="Blog Post"
        isShowAdd={false}
        btnText={isAddAllowed ? "Create Blog" : ""}
        onBtnClick={handleClickOpen}
        onFilterClick={openFilterDrawer}
        loading={loading}
        href={downLoadData}
      />
      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          rowsPerPage={rowsPerPage}
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={blogData?.blogData}
        />
      )}
      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {blogData?.currentPage} of {blogData?.totalPages} Pages
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={blogData?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  variant="outlined"
                  color="primary"
                  label="Blog Title"
                  name="blog_name"
                  value={filterDatas.blog_name}
                  onChange={handleChange}
                  maxLength={200}
                />
              </Box>
              <Box>
                <SelectBox
                  name="is_active"
                  label="Status"
                  placeholder="Chapter"
                  ClassName="status-select-filter"
                  value={filterDatas.is_active}
                  onChange={handleChange}
                  options={[
                    { label: "ACTIVE", value: "true" },
                    { label: "INACTIVE", value: "false" },
                  ]}
                />
              </Box>
              <Stack className={classes.buttonFilter}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                  disabled={
                    filterDatas.blog_name || filterDatas.is_active
                      ? false
                      : true
                  }
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  disabled={
                    filterDatas.blog_name || filterDatas.is_active
                      ? false
                      : true
                  }
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
    </Box>
  );
};

export default Blog;
