import { Box, Card, Grid, Typography, Avatar, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { DisplayHTMLString } from "../../common/HtmlToString";
import { api } from "../../web/config/api";
import { ApiURL } from "../../redux/common";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EnhancedTable from "../table/EnhancedTable.js";
import { Chip } from "@material-ui/core";
import Loader from "../../web/components/loader/Loader";
import { compareDates } from "../../utils/dateUtility.js";
import PageHeader from "../pageHeader/PageHeader.js";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import axios from "axios";

const useStyles = makeStyles({
  userContainer: {
    textAlign: "center",
    padding: "16px", // Replace with your desired padding value
  },
  myOwnAvatar: {
    width: "66px !important",
    height: "66px !important",
    margin: "auto",
  },
  avatar: {
    width: "56px", // Replace with your desired avatar size
    height: "56px", // Replace with your desired avatar size
    // margin: "auto",
  },
  showAllButton: {
    marginTop: "8px", // Replace with your desired margin value
    borderRadius: "30px !important", // Adjust the border-radius for a rounded button
    display: "block", // Display as block to take the full width
    marginLeft: "auto", // Center the button
    marginRight: "auto", // Center the button
    color: "#F07579 !important",
    borderColor: "#F07579 !important",
    background: "white !important",
  },
  tooltip: {
    fontSize: "12px",
    padding: "8px",
  },
});

function subStr(txt, len) {
  let acceptedText = txt.length < len ? txt : txt.substring(0, len - 1) + "...";
  return acceptedText;
}

const EventAdminDetails = () => {
  const navigate = useNavigate("");
  const classes = useStyles();
  const state = useLocation();
  const eventDetails = state?.state?.eventData;
  const [data, setData] = useState({});
  const [timeFilter, setTimeFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [enableForDownload,setEnableForDownload] = useState(false);
  const [download,setDownload] = useState("")

  const formattedDate = moment(eventDetails?.start_time).format(
    "MMMM Do YYYY, h:mm:ss a"
  );
  const formattedEndDate = moment(eventDetails?.end_time).format(
    "MMMM Do YYYY, h:mm:ss a"
  );
  const UserId =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));

  const getEventDetailsById = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        `${ApiURL}event/event-by-id/${eventDetails?._id}?entry_time=${timeFilter}`
      );
      if (res?.status) {
        if (res.data.length > 0) {
          setData(res?.data[0]);
          setLoading(false);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (
      UserId?.user?._id === eventDetails?.user?._id ||
      UserId?.user?.user_role?.role === "Community Super Admin" ||
      UserId?.user?.user_role?.role === "Community Admin"
    ) {
      getEventDetailsById();
    }
  }, [timeFilter]);

  const [value, setValue] = React.useState("1");
  const [subValue, setSubValue] = React.useState("1");
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubChange = (event, newValue) => {
    setSubValue(newValue);
  };

  const columns = [
    {
      id: "profile_picture",
      numeric: true,
      disablePadding: false,
      label: "Profile Picture",
    },
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "email",
      numeric: true,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "phone_number",
      numeric: true,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "created_at",
      numeric: true,
      disablePadding: false,
      label: "Created at",
    },
  ].filter((el) => el !== null);

  const attendeeColumns = [
    {
      id: "profile_picture",
      numeric: true,
      disablePadding: false,
      label: "Profile Picture",
    },
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "email",
      numeric: true,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "phone_number",
      numeric: true,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "attendance_time",
      numeric: true,
      disablePadding: false,
      label: "Attendee date",
    },
    {
      id: "Created_at",
      numeric: true,
      disablePadding: false,
      label: "Created at",
    },
  ].filter((el) => el !== null);

  const rows =
    data?.booked_users && Array.isArray(data?.booked_users)
      ? data?.booked_users?.map((data) => {
          return {
            _id: data._id,
            profile_picture: (
              <div style={{ display: "flex", justifyContent: "left" }}>
                <Avatar
                  alt={data?.first_name + data?.last_name || data?.name}
                  src={data.profile_picture}
                  className={classes.avatar}
                />
              </div>
            ),
            name: data?.first_name + data?.last_name || data?.name,
            email: data?.email || "-",
            phone_number: data?.mobile_number || data?.phone || "-",
          };
        })
      : [];
  const guestrows =
    data?.guest_users && Array.isArray(data?.guest_users)
      ? data?.guest_users?.map((data) => {
          return {
            _id: data._id,
            profile_picture: (
              <Avatar
                alt={data?.first_name + data?.last_name || data?.name}
                src={data.profile_picture}
                className={classes.avatar}
              />
            ),
            name: data?.first_name + data?.last_name || data?.name,
            email: data?.email || "-",
            phone_number: data?.mobile_number || "-",
          };
        })
      : [];
  const attendesrows =
    data?.attendee_users && Array.isArray(data?.attendee_users)
      ? data?.attendee_users?.map((data) => {
          return {
            _id: data._id,
            profile_picture: (
              <Avatar
                alt={data?.first_name + data?.last_name || data?.name}
                src={data.profile_picture}
                className={classes.avatar}
              />
            ),
            name: subStr(data?.name, 20) || "-",
            // name: subStr(data?.first_name + data?.last_name, 20) || data?.name,
            email: subStr(data?.email, 30) || "-",
            phone_number: data.mobile_number || data?.phone || "-",
            attendance_time:
              moment(data?.attendance_time).format("DD/MM/YYYY HH:MM:SS") ||
              data?.phone ||
              "-",
          };
        })
      : [];
  const onExportClick = () => {
    if(!data._id || enableForDownload) return;
    setEnableForDownload(true);
    axios.get(`${ApiURL}event/get-sold-ticket-data/${data._id}`)
      .then((response)=>{
        if(!response?.data?.data) return;
        const link = document.createElement('a');
        link.setAttribute("target","_blank")
        link.setAttribute("href",response?.data?.data);

        //Append to body, click and remove
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .finally(()=>{setEnableForDownload(false)})
  }

  return (
    <div>
      <div>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            // backgroundColor: "rgb(241, 245, 249)",
            display: "flex",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <ArrowCircleLeftOutlinedIcon
              fontSize="large"
              onClick={() => navigate("/admin-events")}
              sx={{ cursor: "pointer", marginTop: "5px", marginBottom: "5px" }}
            />
          </Box>
          {/* {value !== "1" && (
            <Button
              startIcon={<ArrowDownwardIcon />}
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                borderRadius: "50px",
                border: "0",
                color: "#fff",
                "&:hover": {
                  lineHeight: "0px",
                  fontSize: "14px",
                  fontWeight: "500",
                  textTransform: "none",
                  color: "#000 !important",
                },
                backgroundColor: "#153e80",
                textTransform: "none",
                marginLeft: "auto",
                mt: "7px",
              }}
              variant="outlined"
              onClick={onExportClick}
              disabled={enableForDownload}
              // onMouseEnter={() => setIsHovered(true)}
              // onMouseLeave={() => setIsHovered(false)}
            >
              Export
            </Button>
          )} */}
        </Box>

        {UserId?.user?._id === eventDetails?.user?._id ||
        UserId?.user?.user_role?.role === "Community Super Admin" ||
        UserId?.user?.user_role?.role === "Community Admin" ? (
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div style={{ display: "flex" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Event" value="1" />
                </TabList>
                {UserId?.user?.user_role?.role !== "Community Member" && (
                  <>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Ticket Sold List" value="2" />
                      <Tab label="Attendees" value="3" />
                    </TabList>
                  </>
                )}
              </div>
            </Box>

            <TabPanel value="1">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      paddingBottom: "25px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Card
                      sx={{
                        width: "90%",
                        marginTop: "0px",
                        height: "100%",
                        backgroundColor: "#d1d1d17a",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "20px",
                          color: "black",
                          marginTop: "-11px",
                          padding: "22px 15px",
                        }}
                      >
                        Event Details
                      </Typography>
                      <Box
                        sx={{
                          flexGrow: 1,
                          marginTop: "10px",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Event Name
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                                overflowWrap: "break-word",
                                wordBreak: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {eventDetails?.event_name || "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Event Type
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {eventDetails?.event_type || "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Start Time
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {formattedDate || "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              End Time
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {formattedEndDate || "-"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Price
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {eventDetails?.price || "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sx={{ width: "100%" }}>
                            <div style={{ width: "100%" }}>
                              <Typography
                                sx={{
                                  fontWeight: "100",
                                  fontSize: "17px",
                                  marginTop: "-11px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "#605a5a",
                                }}
                              >
                                Venue Link
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                  boxSizing: "border-box",
                                  wordBreak: "break-all", // Breaks long words onto the next line
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {eventDetails?.venue_link}
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              sx={{
                                fontWeight: "100",
                                fontSize: "17px",
                                marginTop: "-11px",
                                padding: " 15px",
                                display: "flex",
                                color: "#605a5a",
                              }}
                            >
                              Venue
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                                overflowWrap:
                                  "break-word" /* Modern equivalent of word-wrap */,
                                wordBreak:
                                  "break-word" /* Breaks words at arbitrary points if necessary */,
                                whiteSpace: "normal",
                                textAlign: "left",
                              }}
                            >
                              {eventDetails?.venue || "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        {compareDates(
                          new Date(eventDetails?.end_time).toUTCString(),
                          new Date().toUTCString()
                        ) &&
                        eventDetails?.approval_status !== "rejected" &&
                        eventDetails?.approval_status !== "pending" ? (
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  marginTop: "-31px",
                                  padding: " 15px",
                                  display: "flex",
                                  color: "black",
                                }}
                              >
                                <Link
                                  target="_blank"
                                  to={`/event/${eventDetails?._id}`}
                                >
                                  Book Now
                                </Link>
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      paddingBottom: "25px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Card
                      sx={{
                        width: "90%",
                        marginTop: "0px",
                        height: "100%",
                        backgroundColor: "#d1d1d17a",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "20px",
                          color: "black",
                          marginTop: "-11px",
                          padding: "22px 15px",
                        }}
                      >
                        Note
                      </Typography>
                      <Box
                        sx={{
                          flexGrow: 1,
                          marginTop: "10px",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              {DisplayHTMLString(eventDetails?.note || "-")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "0px" }}>
              {Object.keys(data)?.length > 0 ? (
                <>
                  <TabContext value={subValue}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          startIcon={<ArrowDownwardIcon />}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            borderRadius: "50px",
                            border: "0",
                            color: "#fff",
                            "&:hover": {
                              lineHeight: "0px",
                              fontSize: "14px",
                              fontWeight: "500",
                              textTransform: "none",
                              color: "#000 !important",
                            },
                            backgroundColor: "#153e80",
                            textTransform: "none",
                            marginLeft: "auto",
                            mt: "7px",
                            mr: "50px",
                            "&.Mui-disabled": {
                              backgroundColor: "#153e80", // Same background color when disabled
                              color: "#fff", // Keep text color white when disabled
                              opacity: 0.8, // Optionally adjust opacity if you want to indicate it's disabled
                            },
                          }}
                          variant="outlined"
                          onClick={onExportClick}
                          disabled={
                            enableForDownload || !data?.guest_users?.length
                          }
                        >
                          {data?.guest_users?.length ? "Export" : "No Data"}
                        </Button>
                      </Box>
                      <TabList
                        onChange={handleSubChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="All" value="1" />
                        <Tab label="Guests" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Box sx={{ flexGrow: 1 }}>
                        {/* <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{
                        paddingBottom: "25px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Card
                        sx={{
                          width: "90%",
                          marginTop: "0px",
                          height: "100%",
                          backgroundColor: "#d1d1d17a",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "20px",
                            color: "black",
                            marginTop: "-11px",
                            padding: "22px 15px",
                          }}
                        >
                          Booked Users
                        </Typography>
                        <Box
                          sx={{
                            flexGrow: 1,
                            marginTop: "10px",
                          }}
                        >
                          <Grid container spacing={2}>
                            {data?.booked_users?.length ? (
                              data?.booked_users?.map((user) => {
                                return (
                                  <>
                                    <Grid
                                      item
                                      key={user._id}
                                      xs={4}
                                      sm={3}
                                      md={2}
                                    >
                                      <Avatar
                                        alt={
                                          user?.first_name + user?.last_name ||
                                          user?.name
                                        }
                                        src={user.profile_picture}
                                        className={classes.avatar}
                                      />
                                      <Typography
                                        style={{
                                          textAlign: "center",
                                          marginTop: "10px",
                                        }}
                                        variant="body2"
                                      >
                                        {user?.first_name + user?.last_name ||
                                          user?.name}
                                      </Typography>
                                    </Grid>
                                  </>
                                );
                              })
                            ) : (
                              <>-</>
                            )}
                          </Grid>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid> */}
                        <EnhancedTable
                          key={rows._id}
                          columns={columns}
                          rows={rows}
                          order={order}
                          orderBy={orderBy}
                          selected={selected}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          title={"Booked Users"}
                          tableList={data?.booked_users}
                        />
                      </Box>
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "0px" }}>
                      <Box sx={{ flexGrow: 1 }}>
                        {/* <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{
                        paddingBottom: "25px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Card
                        sx={{
                          width: "90%",
                          marginTop: "0px",
                          height: "100%",
                          backgroundColor: "#d1d1d17a",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "20px",
                            color: "black",
                            marginTop: "-11px",
                            padding: "22px 15px",
                          }}
                        >
                          Guest Users
                        </Typography>
                        <Box
                          sx={{
                            flexGrow: 1,
                            marginTop: "10px",
                          }}
                        >
                          <Grid container spacing={2}>
                            {data?.guest_users?.length > 0 ? (
                              data?.guest_users?.map((user) => {
                                return (
                                  <>
                                    <Grid
                                      item
                                      key={user._id}
                                      xs={4}
                                      sm={3}
                                      md={2}
                                    >
                                      <Avatar
                                        alt={user?.name}
                                        src={user.profile_picture}
                                        className={classes.avatar}
                                      />
                                      <Typography
                                        style={{
                                          textAlign: "center",
                                          marginTop: "10px",
                                        }}
                                        variant="body2"
                                      >
                                        {user?.name}
                                      </Typography>
                                    </Grid>
                                  </>
                                );
                              })
                            ) : (
                              <>-</>
                            )}
                          </Grid>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid> */}
                        <EnhancedTable
                          key={guestrows._id}
                          columns={columns}
                          rows={guestrows}
                          order={order}
                          orderBy={orderBy}
                          selected={selected}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          title={"Booked Users"}
                          tableList={data?.guest_users}
                        />
                      </Box>
                    </TabPanel>
                  </TabContext>
                </>
              ) : (
                <></>
              )}
            </TabPanel>
            <TabPanel value="3" sx={{ padding: "0px" }}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  startIcon={<ArrowDownwardIcon />}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "50px",
                    border: "0",
                    color: "#fff",
                    "&:hover": {
                      lineHeight: "0px",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "none",
                      color: "#000 !important",
                    },
                    backgroundColor: "#153e80",
                    textTransform: "none",
                    marginLeft: "auto",
                    mt: "7px",
                    mr: "15px",
                    "&.Mui-disabled": {
                      backgroundColor: "#153e80", // Same background color when disabled
                      color: "#fff", // Keep text color white when disabled
                      opacity: 0.8, // Optionally adjust opacity if you want to indicate it's disabled
                    },
                  }}
                  variant="outlined"
                  onClick={onExportClick}
                  disabled={enableForDownload || !data?.guest_users?.length}
                >
                  {data?.guest_users?.length ? "Export" : "No Data"}
                </Button>
              </Box>
              <div className="py-2 d-flex flex-wrap" style={{ gap: "10px" }}>
                <Chip
                  label="All"
                  variant={timeFilter === "" ? "default" : "outlined"}
                  onClick={() => {
                    setTimeFilter("");
                  }}
                />
                <Chip
                  label="Early"
                  variant={timeFilter === "early" ? "default" : "outlined"}
                  onClick={() => {
                    setTimeFilter("early");
                  }}
                />
                <Chip
                  label="Ontime"
                  variant={timeFilter === "ontime" ? "default" : "outlined"}
                  onClick={() => {
                    setTimeFilter("ontime");
                  }}
                />
                <Chip
                  label="Late"
                  variant={timeFilter === "late" ? "default" : "outlined"}
                  onClick={() => {
                    setTimeFilter("late");
                  }}
                />
              </div>
              {loading ? (
                <Loader />
              ) : (
                <EnhancedTable
                  key={attendesrows._id}
                  columns={attendeeColumns}
                  rows={attendesrows}
                  order={order}
                  orderBy={orderBy}
                  selected={selected}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  title={"Booked Users"}
                  tableList={data?.attendee_users}
                  loading={loading}
                />
              )}
            </TabPanel>
          </TabContext>
        ) : (
          <>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    paddingBottom: "25px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    sx={{
                      width: "90%",
                      marginTop: "0px",
                      height: "100%",
                      backgroundColor: "#d1d1d17a",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                        color: "black",
                        marginTop: "-11px",
                        padding: "22px 15px",
                      }}
                    >
                      Event Details
                    </Typography>
                    <Box
                      sx={{
                        flexGrow: 1,
                        marginTop: "10px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            sx={{
                              fontWeight: "100",
                              fontSize: "17px",
                              marginTop: "-11px",
                              padding: " 15px",
                              display: "flex",
                              color: "#605a5a",
                            }}
                          >
                            Event Name
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-31px",
                              padding: " 15px",
                              display: "flex",
                              color: "black",
                              overflowWrap:
                                "break-word" /* Modern equivalent of word-wrap */,
                              wordBreak:
                                "break-word" /* Breaks words at arbitrary points if necessary */,
                              whiteSpace: "normal",
                              textAlign: "left",
                            }}
                          >
                            {eventDetails?.event_name || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            sx={{
                              fontWeight: "100",
                              fontSize: "17px",
                              marginTop: "-11px",
                              padding: " 15px",
                              display: "flex",
                              color: "#605a5a",
                            }}
                          >
                            Event Type
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-31px",
                              padding: " 15px",
                              display: "flex",
                              color: "black",
                            }}
                          >
                            {eventDetails?.event_type || "-"}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            sx={{
                              fontWeight: "100",
                              fontSize: "17px",
                              marginTop: "-11px",
                              padding: " 15px",
                              display: "flex",
                              color: "#605a5a",
                            }}
                          >
                            Start Time
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-31px",
                              padding: " 15px",
                              display: "flex",
                              color: "black",
                            }}
                          >
                            {formattedDate || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            sx={{
                              fontWeight: "100",
                              fontSize: "17px",
                              marginTop: "-11px",
                              padding: " 15px",
                              display: "flex",
                              color: "#605a5a",
                            }}
                          >
                            End Time
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-31px",
                              padding: " 15px",
                              display: "flex",
                              color: "black",
                            }}
                          >
                            {formattedEndDate || "-"}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            sx={{
                              fontWeight: "100",
                              fontSize: "17px",
                              marginTop: "-11px",
                              padding: " 15px",
                              display: "flex",
                              color: "#605a5a",
                            }}
                          >
                            Price
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-31px",
                              padding: " 15px",
                              display: "flex",
                              color: "black",
                            }}
                          >
                            {eventDetails?.price || "-"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          sx={{ marginTop: "50px" }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "100",
                              fontSize: "17px",
                              marginTop: "-11px",
                              padding: " 15px",
                              display: "flex",
                              color: "#605a5a",
                            }}
                          >
                            Venue Link
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-31px",
                              padding: " 15px",
                              display: "flex",
                              color: "black",
                              boxSizing: "border-box",
                              wordBreak: "break-all", // Breaks long words onto the next line
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {eventDetails?.venue_link}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            sx={{
                              fontWeight: "100",
                              fontSize: "17px",
                              marginTop: "-11px",
                              padding: " 15px",
                              display: "flex",
                              color: "#605a5a",
                            }}
                          >
                            Venue
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-31px",
                              padding: " 15px",
                              display: "flex",
                              color: "black",
                              overflowWrap:
                                "break-word" /* Modern equivalent of word-wrap */,
                              wordBreak:
                                "break-word" /* Breaks words at arbitrary points if necessary */,
                              whiteSpace: "normal",
                              textAlign: "left",
                            }}
                          >
                            {eventDetails?.venue || "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                      {compareDates(
                        new Date(eventDetails?.end_time).toUTCString(),
                        new Date().toUTCString()
                      ) &&
                      eventDetails?.approval_status !== "rejected" &&
                      eventDetails?.approval_status !== "pending" ? (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                marginTop: "-31px",
                                padding: " 15px",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              <Link
                                target="_blank"
                                to={`/event/${eventDetails?._id}`}
                              >
                                Book Now
                              </Link>
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    paddingBottom: "25px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    sx={{
                      width: "90%",
                      marginTop: "0px",
                      height: "100%",
                      backgroundColor: "#d1d1d17a",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                        color: "black",
                        marginTop: "-11px",
                        padding: "22px 15px",
                      }}
                    >
                      Note
                    </Typography>
                    <Box
                      sx={{
                        flexGrow: 1,
                        marginTop: "10px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "18px",
                              marginTop: "-31px",
                              padding: " 15px",
                              display: "flex",
                              color: "black",
                            }}
                          >
                            {DisplayHTMLString(eventDetails?.note || "-")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </div>
    </div>
  );
};

export default EventAdminDetails;
