import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

export const GetAllStates = (id, is_active) => async (dispatch) => {
  try {
    dispatch({
      type: "STATE_LOADING",
      payload: true,
    });
    let url = `${ApiURL}state/state-by-countryid/${
      typeof id == "object" ? id._id : id
    }`;
    if (is_active) {
      url = `${ApiURL}state/state-by-countryid/${
        typeof id == "object" ? id._id : id
      }?is_active=true`;
    }
    let res = await axios.get(url);
    if (res.data.status === true) {
      dispatch({
        type: "STATE_LOADING",
        payload: false,
      });
      dispatch({
        type: "ALL_STATES_SUCCESS",
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: "STATE_LOADING",
      payload: false,
    });
  }
};

export const cleareAllStates = () => async (dispatch) => {
  dispatch({
    type: "ALL_STATES_SUCCESS",
    payload: [],
  });
};

export const GetStates =
  (page, state_name = "", rowsPerPage) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "STATE_LOADING",
        payload: true,
      });

      const res = await axios.get(
        `${ApiURL}state/all-state?limit=${rowsPerPage}&page=${page}&state_name=${state_name}`
      );

      if (res.data.status === true) {
        dispatch({
          type: "STATE_LOADING",
          payload: false,
        });

        const responseData =
          res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "STATES_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "STATE_LOADING",
          payload: false,
        });

        dispatch({
          type: "STATES_SUCCESS",
          payload: [],
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "STATE_LOADING",
        payload: false,
      });
    }
  };

export const CreateState = (data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_STATE_LOADING",
      payload: true,
    });
    const res = await axios.post(`${ApiURL}state/create-state`, data);

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_STATE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "CREATE_STATE_LOADING",
      payload: false,
    });
  }
};

export const DeleteState = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_STATE_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}state/delete-state/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_STATE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "DELETE_STATE_LOADING",
      payload: false,
    });
  }
};

export const UpdateState = (id, data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_STATE_LOADING",
      payload: true,
    });
    const res = await axios.put(`${ApiURL}state/update-state/${id}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_STATE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_STATE_LOADING",
      payload: false,
    });
  }
};
