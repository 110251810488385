import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";
import { GetChapterAll } from "../../web/redux/findserachAction";
import { number } from "yup";

const axiosApiCall = () =>
  axios.create({
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

export const getChapterAll = (is_active = "") => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING",
      payload: true,
    });

    const res = await axios.get(`${ApiURL}chapter/all-chapter?is_active=${is_active}`);

    if (res.data.status === true) {
      dispatch({
        type: "LOADING",
        payload: false,
      });
      dispatch({
        type: "GET_ALL_CHAPTER_SUCCESS",
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: "LOADING",
      payload: false,
    });
  }
};

export const GetAllChapter =
  (
    page = "",
    chapter_name = "",
    postalcode = "",
    rowsPerPage = "",
    status = "",
    is_active = "", 
    country_id = "",
    state_id = "",
    city_id = "",
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "LOADING",
        payload: true,
      });

       

      const res = await axios.get(
        `${ApiURL}chapter/all-chapter?limit=${rowsPerPage}&page=${page}&chapter_name=${chapter_name}&postalcode=${postalcode}&status=${status}&is_active=${is_active}&country_id=${country_id}&state_id=${state_id}&city_id=${city_id}`
      );

      if (res.data.status === true) {
        const responseData =
          res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "CHAPTER_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "CHAPTER_SUCCESS",
          payload: [],
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
  };

export const GetBinChapter =
  (page, chapter_name = "", postalcode = "", rowsPerPage) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_BIN_LOADING",
        payload: true,
      });
      const res = await axios.get(
        `${ApiURL}chapter/all-chapter?limit=${rowsPerPage}&page=${page}&chapter_name=${chapter_name}&postalcode=${postalcode}&is_deleted=true`
      );
      if (res.data.status === true) {
        dispatch({
          type: "GET_BIN_SUCCESS",
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: "GET_BIN_SUCCESS",
          payload: res.data.data,
        });       
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch({
        type: "GET_BIN_LOADING",
        payload: false,
      });
    }
  };

export const CreateChapter = (data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_LOADING_CHAPTER",
      payload: true,
    });

    const res = await axiosApiCall().post(
      `${ApiURL}chapter/create-chapter`,
      data
    );

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_LOADING_CHAPTER",
        payload: false,
      });
      dispatch({
        type: "CREATE_CHAPTER_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      dispatch(GetAllChapter(1, "", "", 10, ""));
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "CREATE_LOADING_CHAPTER",
      payload: false,
    });
  }
};

export const UpdateChapter = (id, data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_LOADING_CHAPTER",
      payload: true,
    });

    const res = await axiosApiCall().put(`${ApiURL}chapter/update/${id}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_CHAPTER_SUCCESS",
      });
      // dispatch(GetAllChapter(1, "", "", 10));
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_LOADING_CHAPTER",
      payload: false,
    });
  }
};

export const DeleteChapter = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_CHAPTER_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}chapter/delete/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_CHAPTER_LOADING",
        payload: false,
      });
      dispatch({
        type: "DELETE_CHAPTER_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
    dispatch({
      type: "DELETE_CHAPTER_LOADING",
      payload: false,
    });
  }
};

export const RestoreChapter = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "RESTORE_CHAPTER_LOADING",
      payload: true,
    });
    const res = await axios.put(`${ApiURL}chapter/restore/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "RESTORE_CHAPTER_LOADING",
        payload: false,
      });
      dispatch({
        type: "RESTORE_CHAPTER_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
    dispatch({
      type: "RESTORE_CHAPTER_LOADING",
      payload: false,
    });
  }
};

export const FinalDeleteChapter = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "FINAL_DELETE_CHAPTER_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}chapter/final-delete/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "FINAL_DELETE_CHAPTER_LOADING",
        payload: false,
      });
      dispatch({
        type: "FINAL_DELETE_CHAPTER_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
    dispatch({
      type: "FINAL_DELETE_CHAPTER_LOADING",
      payload: false,
    });
  }
};
