import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

function convertDateFormat(dateString) {
  // Parse the date string using the ISO 8601 format
  const dateObject = new Date(dateString);

  // Format the date object to the desired format
  const formattedDate = dateObject.toLocaleDateString("en-IN", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    //   second: "2-digit",
  });

  return formattedDate;
}

const ViewTransaction = () => {
  const navigate = useNavigate("");
  const { state } = useLocation();
  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          // backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <ArrowCircleLeftOutlinedIcon
            fontSize="large"
            onClick={() => navigate("/admin-transactions")}
            sx={{ cursor: "pointer", marginTop: "5px", marginBottom: "5px" }}
          />
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              paddingBottom: "25px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                width: "90%",
                marginTop: "0px",
                height: "100%",
                backgroundColor: "#d1d1d17a",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "black",
                  marginTop: "-11px",
                  padding: "22px 15px",
                }}
              >
                Transaction Details
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  marginTop: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {state?.transactiondata?.email || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Contact
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {state?.transactiondata?.contact || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Amount
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {state?.transactiondata?.amount || "-"} ({" "}
                      {state?.transactiondata?.currency} )
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Bank
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {state?.transactiondata?.bank || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Method
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {state?.transactiondata?.method || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Razorpay Payment Id
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {state?.transactiondata?.razorpay_payment_id || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                
                <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Transaction Date
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {convertDateFormat(state?.transactiondata?.createdAt) || "-"}
                    </Typography>
                  </Grid>
                
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              paddingBottom: "25px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                width: "90%",
                marginTop: "0px",
                height: "100%",
                backgroundColor: "#d1d1d17a",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "black",
                  marginTop: "-11px",
                  padding: "22px 15px",
                }}
              >
                User Details
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  marginTop: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Name
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {state?.transactiondata?.user?.first_name || "-"}{" "}
                      {state?.transactiondata?.user?.last_name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontWeight: "100",
                        fontSize: "17px",
                        marginTop: "-11px",
                        padding: " 15px",
                        display: "flex",
                        color: "#605a5a",
                      }}
                    >
                      Mobile Number
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "-31px",
                        padding: " 15px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      {state?.transactiondata?.user?.mobile_number || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ViewTransaction;
