import { api } from "../config/api";

export const GetAllEvent = async (limit = "", page = "", event_name = "") => {
  try {
    const response = await api.get(
      `/event/all-event?limit=${limit}&page=${page}&event_name=${event_name}`
    );
    const responseData = response;
    // const approvedEvents = responseData?.data?.eventData?.filter(event => event?.approved_status === 'approved')
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const GetEventDetails = async (id) => {
  try {
    const response = await api.get(`/event/event-by-id/${id}`);
    const responseData = response;
    return responseData;
  } catch (error) {
    throw error;
  }
};
