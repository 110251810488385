import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import InternalServerError from "../../../pages/InternalServerError.js";
import { DisplayHTMLString } from "../../../common/HtmlToString.js";
import Loader from "../../components/loader/Loader";

import { api } from "../../config/api";
import { ApiURL } from "../../../redux/common";
import nextArrow from "../../GGC_LendingPage/svg/arrowLeft.svg";
import SearchComponent from "../../components/search/SerachComponent.js";

const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const getPrivacyPolicyData = async () => {
    try {
      const res = await api.get(`${ApiURL}privacy-policy`);
      if (res?.status) {
        setData(res?.data?.policy);
      } else {
        setData("");
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrivacyPolicyData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <InternalServerError />;
  }

  const handleClick =() => {
    navigate("/contact-us")
  }

  return (
    <>
      <div>
        <div id="page-title" className="page-title pt-3 text-center">
          <div className="container">
            <div className="h-100 ">
              <h1
                className="mb-0 fadeInDown animated"
                data-animate="fadeInDown"
              >
                Privacy Policy
              </h1>
              {/*HEAD*/}
              <div className="">
                <Link to="/" className="detailsLink">
                  Home
                </Link>
                <span className="text-fff mxy-10 font-weight-bold text-20">
                  <img src={nextArrow} />
                </span>
                <span
                  className="text-000 font-weight-bold text-19"
                  style={{ textDecoration: "none", letterSpacing: '0.6px'  }}
                >
                  Privacy Policy
                </span>
              </div>
            </div>
          </div>
        </div>

        <div id="wrapper-content" className="wrapper-content pt-1 pb-0">
          <div className="responsive">{DisplayHTMLString(data)}</div>
        </div>

        {/*Contact Us*/}
        <div className="responsive" style={{ margin: "-165px auto 100px" }}>
          <SearchComponent title="Have a question? Contact us now!" func={handleClick} />
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
