import React, { useEffect, useState } from "react";
import {
  Dialog,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Grid,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import { Close as CloseIcon } from "@mui/icons-material";
import MuiPhoneNumber from "material-ui-phone-number";
import { snackBar } from "../../../redux/common";
import * as Yup from "yup";
import GuestDetailsForm from "./GuestDetailsForm";
import { DisplayHTMLString } from "../../../common/HtmlToString";
import PaymentGateway from "../PaymentGateway";

const GuestDetailsModal = ({
  open,
  handleClose,
  guest,
  checkoutButton,
  guestDetails,
  setGuestDetails,
  amount,
  afterPayment,
  enablePayementProcess
}) => {
  const validationSchmaObj = {};
  const extractCountryCodeAndNumber = (value) => {
    const matches = value.match(/^(\+\d{1,3})\s*(\d{5})-(\d{5})$/);
    if (matches) {
      return {
        countryCode: matches[1],
        phoneNumber: `${matches[2]}${matches[3]}`
      };
    }
    return null;
  };
  const validations = {
    name: Yup.string()
    .required("Name is required")
    .matches(/^[a-zA-Z\s]+$/, "Event name can only contain letters and spaces"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string()
    .matches(/^\+\d{1,3}\s*\d{5}-\d{5}$/, "Phone must be in the format +<country code> <5 digits>-<5 digits>")
    .required("Phone is required")
    .test('country-code-and-number', 'Invalid phone number format', (value) => {
      const result = extractCountryCodeAndNumber(value);
      return result !== null;
    }),
  };
  Array.from({ length: guest }).forEach((_, index) => {
    validationSchmaObj[`name${index}`] = validations["name"];
    validationSchmaObj[`email${index}`] = validations["email"];
    validationSchmaObj[`phone${index}`] = validations["phone"];
  });
  const validationSchema = Yup.object().shape(validationSchmaObj);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
  };

  const handleFormSubmit = (values, { resetForm }) => {
    if (isFormDisabled()) {
      snackBar("Fill all guest details.", false);
      return;
    } else {
      resetForm();
    }
  };

  const isFormDisabled = () => {
    return guestDetails.some(
      (detail) => !detail.name || !detail.email || !detail.phone
    );
  };

  const handleFieldChange = (index, fieldName, fieldValue) => {
    const updatedGuestDetails = guestDetails.map((detail, i) =>
      i === index ? { ...detail, [fieldName]: fieldValue } : detail
    );

    const allValuesSet = updatedGuestDetails.every(detail =>
      Object.values(detail).every((value) => value !== '')
    );
    formik.validateForm().then((touched) => {
      if (Object.keys(touched).length === 0) {
        setIsActivate(false)
        } else {
        setIsActivate(true)       
      }
    });
    setGuestDetails(updatedGuestDetails);
  };

  const handleAlertClose = () => {
    setGuestDetails([]);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => { },
  });
  const [isActivate, setIsActivate] = useState(false)

  const handleCheckoutClick = () => {
    // Mark all fields as touched to display errors
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {})
    );

    // Validate the form
    formik.validateForm().then((touched) => {
      if (Object.keys(touched).length === 0) {
        
      } else {
        // Display error messages next to each field with errors
        formik.setTouched(touched);
      }
    });
  };
 
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ color: "#000 !important" }}>
            Add Guest Details
          </Typography>
          <IconButton
            sx={{
              color: "black",
              "&:hover": {
                backgroundColor: "white",
                color: "#153e80",
              },
              "&:focus": {
                backgroundColor: "white",
                color: "#153e80",
                border: "none",
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit}>
            {guestDetails.map((detail, index) => (
              <GuestDetailsForm
                key={index}
                index={index}
                value={{
                  name: formik.values[`name${index}`],
                  phone: formik.values[`phone${index}`],
                  email: formik.values[`email${index}`],
                }}
                names={{
                  name: `name${index}`,
                  phone: `phone${index}`,
                  email: `email${index}`,
                }}
                onchange={{
                  nameChange: (e) => {
                    formik.handleChange(e);
                    handleFieldChange(index, "name", e.target.value);
                  },
                  phoneChange: (value) => {
                    formik.setFieldValue(`phone${index}`, value);
                    handleFieldChange(index, "mobile_number", value);
                  },
                  emailChange: (e) => {
                    formik.handleChange(e);
                    handleFieldChange(index, "email", e.target.value);
                  },
                }}
                valuesforvalidation={{
                  name: formik.values[`name${index}`],
                }}
                onBlur={formik.handleBlur}
                touched={{
                  name: formik.touched[`name${index}`],
                  phone: formik.touched[`phone${index}`],
                  email: formik.touched[`email${index}`],
                }}
                errors={{
                  name: formik.errors[`name${index}`],
                  phone: formik.errors[`phone${index}`],
                  email: formik.errors[`email${index}`],
                }}
              />
            ))}
            <Grid
              container
              justifyContent="flex-end"
              sx={{ marginTop: "20px", marginBottom: "10px" }}
            >
              <Button
                type="button"
                variant="contained"
                color="warning"
                className="mr-2"
                onClick={handleClose}
              >
                Cancel
              </Button>
              {!isActivate ? (
                checkoutButton
              ) : (
                <Button variant="contained" type="button" onClick={handleCheckoutClick}
                >
                  Check Out
                </Button>
              )}
            </Grid>
          </form>
        </DialogContent>

        {guestDetails.length === guest && (
          <Dialog open={guestDetails.length === guest}>
            <DialogContent dividers>
              <Typography variant="h6">All details submitted:</Typography>
              {guestDetails.map((detail, index) => (
                <Card key={index} style={{ marginBottom: "20px" }}>
                  <CardContent>
                    <Typography>{`Name: ${detail.name}`}</Typography>
                    <Typography>{`Email: ${detail.email}`}</Typography>
                    <Typography>{`Phone: ${detail.phone}`}</Typography>
                  </CardContent>
                </Card>
              ))}
              <Button onClick={handleAlertClose} color="primary">
                Close
              </Button>
            </DialogContent>
          </Dialog>
        )}
      </Dialog>
    </>
  );
};

export default GuestDetailsModal;
