import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Input from "../../common/Input";
import * as Yup from "yup";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserManage } from "../../redux/action/UserManageAction";
import { CreateRole } from "../../redux/action/RoleandPermissionAction";
import axios from "../../redux/ApiConfig";
import { ApiURL, snackBar } from "../../redux/common";
import SelectBox from "../../common/SelectBox";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const statusOptions = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
];

const UpdatePermission = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  
  const dispatch = useDispatch();
  
  const [data, setData] = useState({});
  const [moduleList, setModuleList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  

  const getAllModule = async () => {
    const res = await axios.get(`${ApiURL}module/all-modules`);
    setModuleList(res.data.data);
  };

  const getRoleDataById = async () => {
    const res = await axios.get(`${ApiURL}role/by-id/${state?._id}`);
    setData(res?.data?.data?.permissions || {});
  };

  const handleRowChange = (event) => {
    const [module, permission] = event.target.name.split(".");
    setData({
      ...data,
      [module]: { ...data[module], [permission]: Number(event.target.checked) },
    });
  };

  const rows = moduleList && moduleList.map((module) =>
    createData(
      module.name,
      <Checkbox
        color="primary"
        checked={data[module.name]?.is_read || false}
        name={`${module.name}.is_read`}
        onChange={handleRowChange}
      />,
      <Checkbox
        color="primary"
        checked={data[module.name]?.is_add || false}
        name={`${module.name}.is_add`}
        onChange={handleRowChange}
      />,
      <Checkbox
        color="primary"
        checked={data[module.name]?.is_edit || false}
        name={`${module.name}.is_edit`}
        onChange={handleRowChange}
      />,
      <Checkbox
        color="primary"
        checked={data[module.name]?.is_delete || false}
        name={`${module.name}.is_delete`}
        onChange={handleRowChange}
      />
    )
  );

  const [isSubmmitting, setIsSubmmitting] = useState(false);

  const nameRegex = /^[^\s][\w\W]*[^\s]$/;

  const roleSchema = Yup.object().shape({
    role: Yup.string()
      .required("Please enter role name")
      .matches(nameRegex, "Role name should contain only alphabets and without leading spaces"),
  })


  const formik = useFormik({
    initialValues: {
      role: "",
      is_active: "",
    },
    validationSchema: roleSchema,
    onSubmit: async (values) => {
      setIsSubmmitting(true);
      let body = {};
      //   if (isEdit) {
      body = {
        role: values.role,
        is_active: values.is_active,
        permissions: data,
      };
      //   }

      if (isEdit) {
        // dispatch(UpdateUserManage(state._id, body));
        const res = await axios.put(
          `${ApiURL}role/update-role/${state?._id}`,
          body
        );
        snackBar(res?.data?.message, res?.data?.status);
        navigate("/admin-role-permission");
      }

      if (!isEdit) {
        dispatch(CreateRole(body));
        navigate("/admin-role-permission");
      }
    },
  });

  useEffect(() => {
    if (state && state.role) setIsEdit(true);
    {
      formik.setValues({
        ...formik.values,
        role: state?.role || "",
        is_active: state?.is_active ? "true" : "false",
      });
    }
  }, [state, formik.setValues]);

  useEffect(() => {
    getAllModule();
    if (state?._id) {
      getRoleDataById(state?._id);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        margin: "3rem",
        // height: "100vh",
      }}
    >
      <ArrowCircleLeftOutlinedIcon
        fontSize="large"
        onClick={() => navigate("/admin-role-permission")}
        sx={{ cursor: "pointer" }}
      />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "60px",
        }}
      >
        <Grid item md={12} xs={12} sm={12}>
          <Grid item md={12}>
            <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container>
                  <Grid item md={6}>
                    <Grid item md={9}>
                      <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                        Role:
                      </Typography>
                      <Input
                        id="role"
                        name="role"
                        type="text"
                        label="Role"
                        maxLength={50}
                        onChange={formik.handleChange}
                        value={formik.values.role}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.role &&
                          Boolean(formik.errors.role)
                        }
                        helperText={
                          formik.touched.role &&
                          formik.errors.role ? (
                            <div className="err-msg err-msg-font">{formik.errors.role}</div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ display: "flex", gap: "50px", alignItems: "center" }}
                  >
                    <Grid item md={7}>
                      <Typography variant="h6" sx={{ marginBottom: "-0.7em" }}>
                        Status:
                      </Typography>
                      <SelectBox
                        type="text"
                        id="is_active"
                        name="is_active"
                        label="Status"
                        ClassName="range-select-filter"
                        onChange={formik.handleChange}
                        value={formik.values.is_active}
                        // defaultValue={statusModal?.status || "pending"}
                        options={statusOptions}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Button
                        sx={{ marginTop: 4 }}
                        type="submit"
                        variant="contained"
                        disabled={isSubmmitting}
                      >
                        {isEdit ? "Update" : "Submit"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography
                    fontWeight={900}
                    fontSize={21}
                    marginBottom={2}
                    color="black"
                    sx={{ marginTop: "25px" }}
                  >
                    Roles Permission
                  </Typography>

                  <Box>
                    <TableContainer component={Paper} sx={{ maxHeight: 540 }}>
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-label="sticky table"
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Role</TableCell>
                            <TableCell align="center">
                              View
                            </TableCell>
                            <TableCell align="center">
                              Add
                            </TableCell>
                            <TableCell align="center">
                              Edit
                            </TableCell>
                            <TableCell align="center">
                              Delete
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows && rows?.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="center">
                                {row.calories}
                              </TableCell>
                              <TableCell align="center">{row.fat}</TableCell>
                              <TableCell align="center">{row.carbs}</TableCell>
                              <TableCell align="center">
                                {row.protein}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdatePermission;
