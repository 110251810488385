import * as React from "react";
import Box from "@mui/material/Box";
import { FormHelperText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { InputLabel } from "@mui/material";
import Menu from "@mui/material/Menu";
import DoneIcon from "@mui/icons-material/Done";
export default function SelectBox(props) {
  const {
    name,
    value,
    onChange,
    maxRows = 0,
    options,
    disabled,
    touched,
    ClassName,
    error,
    label,
    onBlur,
    style,
    mt,
    ...other
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleChange = (event) => {
    setAnchorEl(event);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ minWidth: 120, mt: mt || 2, color: "red" }}>
      <FormControl fullWidth size="small" {...(error && { error: true })}>
        <InputLabel
          sx={{
            "&.MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "14px",
              fontWeight: "500",
            },

            "&.MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
              borderRadius: "7px",
              paddingTop: "0px",
              paddingBottom: "3px",
              borderColor: "blue",
            },
            "&.MuiOutlinedInput-root.Mui-focused": {
              borderColor: "red",
            },
            "&.MuiInputLabel-root.Mui-error": {
              color: "#fc9494!important",
            },
          }}
        >
          {label}
        </InputLabel>
        <Select
          name={name}
          sx={{
            paddingTop: "0px",
            paddingBottom: "5px",
            borderRadius: "5px",
            color: "black",
            input: { color: "#000" },

            ".MuiOutlinedInput-notchedOutline": {
              height: other.temp ? "54px" : null,
            },
            "&.MuiOutlinedInput-root": {
              "& fieldset": {
              },
              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "blue",
              },
            },
            "&.MuiOutlinedInput-root.Mui-error": {
              "& fieldset": {
                borderColor: "#fc9494!important",
              },
              "&:hover fieldset": {
                borderColor: "#fc9494!important",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#fc9494!important",
              },
            },
            ".css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
            {
              paddingTop: "0!important",
            },
            ".MuiInputLabel-root": {
              color: "rgb(92 91 91 / 70%)",
            },
            ".MuiSelect-select": {
            },
            "&.Mui-focused .MuiOutlinedInput-root": {
              borderColor: "red",
            },
            ".MuiOutlinedInput-notchedOutline.Mui-focused": {
              borderColor: "red",
            },
            "& label.Mui-focused": {
              color: "#4f46e5!important",
            },
            "& .MuiPaper-root-MuiMenu-paper-MuiPopover-paper": {
            },
            ".MuiSvgIcon-root ": {
              fill: "rgb(92 91 91 / 70%) !important",
            },
          }}
          id="demo-select-small"
          value={value}
          label={label}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          error={touched && Boolean(error)}
          {...other}
          {...(error && { error: true, helperText: error })}
        >
          {options?.length ? (
            options?.map((item) => {
              return (
                <MenuItem
                  key={item.id}
                  value={item.value || item._id}
                  sx={{ color: "#000!important" }}
                >
                  {name == "role" &&
                    Array.isArray(value) &&
                    value.includes(item.value) && (
                      <DoneIcon fontSize="10px" />
                    )}{" "}
                  {item.name ||
                    item.label ||
                    item.chapter_name ||
                    item.role ||
                    item.category_name ||
                    item.country_name}
                  &#x2002;
                  <span style={{ fontSize: "11px", color: "gray" }}>
                    {item.ref_role ? "(" + item.ref_role + ")" : ""}
                  </span>
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value="no-data-found" disabled>No Data Found</MenuItem>
          )}
        </Select>
        <FormHelperText style={{ color: "#d32f2f" }}>
          {touched && error ? <div>{error}</div> : null}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
