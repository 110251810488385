import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiURL, snackBar } from "../../../redux/common";
import axios from "axios";
import { DisplayHTMLString } from "../../components/htmlToString/HtmlToString";
import nextArrow from "../../GGC_LendingPage/svg/arrowLeft.svg";
import SearchComponent from "../../components/search/SerachComponent";

const TermsAndCondition = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [getAllTnc, setGetAllTnC] = useState();

  useEffect(() => {
    getTermsAndCondition();
  }, []);

  const getTermsAndCondition = async () => {
    try {
      setLoading(true);
      const getTnC = await axios
        .get(`${ApiURL}terms-and-conditions`)
        .then((res) => {
          if (res?.data?.status) {
            setGetAllTnC(res.data.data);
          }
        });
      setLoading(false);
      return getTnC;
    } catch (error) {
      snackBar(error, false);
      setLoading(false);
    }
  };

  const handleClick =() => {
    navigate("/contact-us")
  }

  return (
    <>
      <div>
        <div id="page-title" class="page-title pt-3 text-center">
          <div class="container">
            <div class="h-100 ">
              <h1 class="mb-0 fadeInDown animated" data-animate="fadeInDown">
                Terms &amp; Condition
              </h1>
              {/*HEAD*/}
              <div className="">
                <Link to="/" className="detailsLink">
                  Home
                </Link>
                <span className="text-fff mxy-10 font-weight-bold text-20">
                  <img src={nextArrow} />
                </span>
                <span
                  className="text-000 font-weight-bold text-19"
                  style={{ textDecoration: "none", letterSpacing: '0.6px' }}
                >
                  Terms & Conditions
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* #page-title end */}
        {/* #wrapper-content start */}
        <div id="wrapper-content" className="wrapper-content pb-0 pt-3">
          <div className="responsive">
            {/* #term-condition START */}
            <div id="term-condition" className="pb-9">
              <div className="mb-7">
                {DisplayHTMLString(getAllTnc && getAllTnc?.termsAndConditions)}
              </div>
            </div>
          </div>
        </div>

        {/*Contact Us*/}
        <section className="responsive" style={{ margin: "-150px auto 100px" }}>
          <SearchComponent title="Have a question? Contact us now!" func={handleClick} />
        </section>
      </div>
    </>
  );
};

export default TermsAndCondition;
