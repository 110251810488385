import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import Logo from "../../assets/images/logo-home.svg";
import React, { useState } from "react";
import Input from "../../common/Input";
import {
  Box,
  Grid,
  CardMedia,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonCom from "../../common/Button";
import * as Yup from "yup";
import { snackBar } from "../../redux/common";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { base_Url } from "../../web/utils/common";

const useStyles = makeStyles((theme) => ({
  main: {
    background:
      "linear-gradient(317deg, rgba(254,118,120,1) 9%, rgba(21,62,128,1) 100%)",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      padding: "10px",
    },

    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },

    display: "flex",
    alignItems: "center",
  },
  subMain: {
    width: "450px",
    margin: "0 auto",
    backgroundColor: "#fff",
    borderRadius: "20px",
  },

  logo: {
    marginBottom: "20px",
    width: "60% !important",
    display: "block",
    margin: "0 auto",
  },
  mainCard: {
    boxShadow: "none !important",
    color: "#000 !important",
    display: "block",
    textAlign: "center",
    minWidth: 275,
  },
  boxCard: {
    [theme.breakpoints.up("xs")]: {
      padding: "20px",
    },

    [theme.breakpoints.up("md")]: {
      padding: "40px",
    },
  },

  typography: {
    fontSize: "24px !important",
    fontWeight: "bold !important",
    color: "#000",
  },

  send: {
    ":hover": { bgcolor: "#153E80", color: "#FFFFFF" },
    marginTop: "20px !important",
    textTransform: "none !important",
    fontSize: "16px !important",
    color: "#FFFFFF !important",
    width: "100%",
    backgroundColor: "#153E80 !important",
    border: "2px solid #153E80 !important",
    borderRadius: "15px !important",
    padding: "10px 40px !important",
    "&.Mui-disabled": {
      backgroundColor: "#153E80 !important",
      color: "#fff !important",
    },
    marginLeft: "3px !important",
  },

  mainPassword: {
    marginTop: "10px",
    width: "100%",
  },

  outlinePassword: {
    "& .MuiInputBase-input": {
      padding: "12px 14px !important",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      paddingTop: "10px!important",
      borderColor: "rgba(107, 114, 128, .5)",

      borderRadius: "7px!important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "blue",
    },
    paddingTop: "0px",
    color: "#000 !important",
  },
}));

const ConfirmPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [data, setData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswords, setShowPasswords] = useState(false);

  const [rememberMe, setRememberMe] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibilitys = () => {
    setShowPasswords(!showPasswords);
  };
  const location = useLocation();
  const { token } = location?.state?.token;
  const initialValues = {
    // password: rememberMeCookie?.password || "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Please enter password")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=[\]{}|\\:;"'<>,.?/~`])(?!.*\s)/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and no spaces allowed."
      ),
    confirm_password: Yup.string()
      .required("Please enter confirm password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setLoading(true);
    try {
      const loginData = {
        password: values.password,
        confirm_password: values.confirm_password,
      };
      const response = await axios.put(
        `${base_Url}user/reset-password`,
        loginData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status === true) {
        setLoading(false);
        snackBar(response?.data?.message, response?.data?.status);
        navigate("/");
      } else {
        setLoading(false);
        snackBar(response?.data?.message, response?.data?.status);
      }
    } catch (error) {
      setLoading(false);
      snackBar("Please provide valid password!");
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Box className={classes.main}>
      <Box className={classes.subMain}>
        <Grid
          container
          item
          columns={{ xs: 2, sm: 12, md: 12 }}
          spacing={2}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={12}>
            <Box className={classes.boxCard}>
              {/* <Link to="/">
                <CardMedia
                  className={classes.logo}
                  component="img"
                  image={Logo}
                  alt="Paella dish"
                />
              </Link> */}
              <h2 className="font-weight-bold text-center">Set a new password</h2>
              <p style={{fontSize: '14px', color: '#717171', textAlign: 'center', marginBottom: '22px'}}>Please enter your new password</p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  isSubmitting,
                  values,
                  handleChange,
                  setFieldError,
                  errors,
                  setErrors,
                  ...rest
                }) => {
                  setData(values);
                  return (
                    <Form>
                      <div
                        style={{ position: "relative" }}
                        className="form-group mb-2"
                      >
                        <label htmlFor="password" style={{display: 'block', marginBottom: '5px'}}>New Password</label>
                        <Field
                          type={showPasswords ? "text" : "password"}
                          id="password"
                          name="password"
                          placeholder="Password"
                          className="password-input"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "5px",
                            border: "1px solid #dee2e6",
                            paddingLeft: "10px",
                          }}
                          maxLength={16}
                        />
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: "47%",
                            right: "0px",
                          }}
                          onClick={togglePasswordVisibilitys}
                          aria-label={
                            showPasswords ? "Hide password" : "Show password"
                          }
                        >
                          {showPasswords ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                        {Boolean(errors.password) ? (
                          <p
                            style={{
                              color: "rgb(255 0 0)",
                              marginTop: "4px",
                              marginBottom: "4px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.password}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        style={{ position: "relative" }}
                        className="form-group mb-2"
                      >
                        <label htmlFor="password" style={{display: 'block', marginBottom: '5px'}}>Confirm Password</label>
                        <Field
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="confirm_password"
                          placeholder="Confirm Password"
                          className="password-input"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "5px",
                            border: "1px solid #dee2e6",
                            paddingLeft: "10px",
                          }}
                          maxLength={30}
                        />
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: "47%",
                            right: "0px",
                          }}
                          onClick={togglePasswordVisibility}
                          aria-label={
                            showPassword ? "Hide password" : "Show password"
                          }
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>

                        {Boolean(errors.confirm_password) ? (
                          <p
                            style={{
                              color: "rgb(255 0 0)",
                              marginTop: "4px",
                              marginBottom: "4px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.confirm_password}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <ButtonCom
                        className={classes.send}
                        type="submit"
                        text={
                          loading ? (
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                width: "28px !important",
                                height: "28px !important",
                              }}
                            />
                          ) : (
                            "Reset"
                          )
                        }
                      ></ButtonCom>

                      {/* </Grid> */}

                      {/* <Link className="mb-8" to="be-member-form">
                            Sign Up / Register
                          </Link> */}
                    </Form>
                  );
                }}
              </Formik>
              <div onClick={() =>navigate("/")} style={{margin: '8px auto 0', textAlign: 'center', color: '#515151', cursor: 'pointer', fontWeight: '600'}}>Back to Home</div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ConfirmPassword;
