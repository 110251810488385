import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable.js";
import { CloseIcon, Visibility } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { useForm } from "../../common/useForms";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import { getWalletDetails } from "../../redux/action/WalletAction";
import Loader from "../loader/Loader";
import useUserRolePermissions from "../../common/UserPermissions";
import dayjs from "dayjs";
import axios from "axios";
import { base_Url } from "../../web/utils/common.js";
import { snackBar } from "../../redux/common.js";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SelectBox from "../../common/SelectBox.js";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const columns = [
  {
    id: "user_name",
    numeric: true,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "user_mobile_number",
    numeric: true,
    disablePadding: false,
    label: "Mobile Number",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "transaction_amount",
    numeric: true,
    disablePadding: true,
    label: "Transaction Amount",
  },
  {
    id: "closing_balance",
    numeric: true,
    disablePadding: true,
    label: "Closing Balance",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created At",
  },
  {
    id: "transaction_type",
    numeric: false,
    disablePadding: true,
    label: "Transaction Type",
  },
  {
    id: "transaction_id",
    numeric: false,
    disablePadding: true,
    label: "Transaction ID",
  },
  {
    id: "view",
    numeric: true,
    disablePadding: false,
    label: "View",
  },
].filter((el) => el !== null);

const initialValues = {
  banner_images: "",
  banner_type_id: "",
  chapter_id: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
];
const initialFilterValues = {
  user_name: "",
  email: "",
  mobile_number: "",
  transaction_type: "",
  month_year: "",
  transactionId: "",
};

function generateDateArray(startDate) {
  let result = [];
  let start = new Date(startDate);
  start.setDate(1);
  let end = new Date(); // current date
  end.setDate(2);
  while (start <= end) {
    let month = (start.getMonth() + 1).toString().padStart(2, "0");
    let year = start.getFullYear();
    result.push(`${month}/${year}`);
    start.setMonth(start.getMonth() + 1);
  }

  return result;
}

const WalletList = () => {
  const { userProfileData, loading } = useSelector(
    (state) => state.UserManageReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [selectedDate, setSelectedDate] = useState("");
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  console.log(filterDatas, "filterDatas");
  const formattedDate = dayjs(userProfileData?.data?.createdAt);
  const userCreatedDate = userProfileData?.data?.createdAt;
  const [selectedMonth, setSelectedMonth] = useState(dayjs(userCreatedDate));
  const [downLoadData, setDownLoadData] = useState("");
  const [monthYearSet, setMonthYearSet] = useState(new Set());

  const date = new Date(selectedDate);
  const formattedDateFilter = selectedDate ? dayjs(date).format("MM/YYYY") : "";

  useEffect(() => {
    if (userProfileData?.data?.createdAt) {
      setSelectedMonth(dayjs(userProfileData?.data?.createdAt));
    }
  }, [userProfileData]);

  const role = useUserRolePermissions();

  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { getWalletLoading, wallet } = useSelector(
    (state) => state?.WalletReducer
  );

  useEffect(() => {
    const initialSet = new Set(
      wallet?.monthly_transaction?.map((data) => data.month_year)
    );
    const currentDate = dayjs();
    let current = selectedMonth;

    while (
      current.isBefore(currentDate) ||
      current.isSame(currentDate, "month")
    ) {
      const monthYear = current.format("MM/YYYY");
      initialSet.add(monthYear);
      current = current.add(1, "month");
    }

    setMonthYearSet(initialSet);
  }, [wallet, selectedMonth, userProfileData]);

  const sortedMonthYearArray = generateDateArray(
    userProfileData?.data?.createdAt
      ? new Date(userProfileData?.data?.createdAt)
      : new Date()
  ).sort((a, b) => {
    const [monthA, yearA] = a.split("/").map(Number);
    const [monthB, yearB] = b.split("/").map(Number);
    if (yearA !== yearB) {
      return yearB - yearA;
    }
    return monthB - monthA;
  });

  useEffect(() => {
    const body = {
      user_name: filterDatas.user_name ? filterDatas.user_name : "",
      mobile_number: filterDatas.mobile_number ? filterDatas.mobile_number : "",
      email: filterDatas.email ? filterDatas.email : "",
      transaction_type: filterDatas.transaction_type
        ? filterDatas.transaction_type
        : "",
      month_year: filterDatas.month_year ? filterDatas.month_year : "",
      transactionId: filterDatas.transactionId ? filterDatas.transactionId : "",
    };
    dispatch(
      getWalletDetails(
        page,
        rowsPerPage,
        body.email,
        body.mobile_number,
        body.transaction_type,
        body.user_name,
        body.month_year,
        body.transactionId
      )
    );
  }, [page, rowsPerPage]);

  const handleChangeRow = (event) => {
    const body = {
      user_name: filterDatas.user_name ? filterDatas.user_name : "",
      mobile_number: filterDatas.mobile_number ? filterDatas.mobile_number : "",
      email: filterDatas.email ? filterDatas.email : "",
      transaction_type: filterDatas.transaction_type
        ? filterDatas.transaction_type
        : "",
      month_year: filterDatas.month_year ? filterDatas.month_year : "",
      transactionId: filterDatas.transactionId ? filterDatas.transactionId : "",
    };
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1);
    dispatch(
      getWalletDetails(
        page,
        rowPerPage,
        body.email,
        body.mobile_number,
        body.transaction_type,
        body.user_name,
        body.month_year,
        body.transactionId
      )
    );
  };

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const tableTitle = "SubscriptionList";

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPopupName("Create Banner");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if (popupName === "Edit Banner") {
      if ("banner_type_id" in fieldValues) {
        if (
          fieldValues.banner_type_id === "" ||
          fieldValues.banner_type_id === undefined ||
          fieldValues.banner_type_id === null ||
          fieldValues.banner_type_id?.trim() === ""
        ) {
          temp.banner_type_id = "Please enter banner type";
        } else {
          temp.banner_type_id = "";
        }
      }
    } else {
      if ("banner_images" in fieldValues) {
        if (
          fieldValues.banner_images === "" ||
          fieldValues.banner_images === undefined ||
          fieldValues.banner_images === null
        ) {
          temp.banner_images = "This field is required.";
        } else {
          temp.banner_images = "";
        }
      }

      if ("banner_type_id" in fieldValues) {
        if (
          fieldValues.banner_type_id === "" ||
          fieldValues.banner_type_id === undefined ||
          fieldValues.banner_type_id === null
        ) {
          temp.banner_type_id = "Please select banner type";
        } else {
          temp.banner_type_id = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setFieldValues,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const rows =
    wallet?.walletData && Array.isArray(wallet?.walletData)
      ? wallet?.walletData?.map((data) => {
          return {
            _id: data._id,
            user_name: data?.user_name,
            user_mobile_number: data?.user_mobile_number,
            email: data?.email,
            transaction_amount: data.transaction_amount,
            closing_balance: data?.closing_balance,
            createdAt: dayjs(data?.createdAt).format("DD/MM/YYYY"),
            transaction_type: data?.transaction_type,
            transaction_id: data?._id,
            view: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IconButton
                  onClick={() =>
                    navigate("/admin-wallet/view", {
                      state: {
                        transaction_amount: data.transaction_amount,
                        closing_balance: data.closing_balance,
                        transaction_type: data.transaction_type,
                        user_name: data.user_name,
                        user_mobile_number: data.user_mobile_number,
                        email: data.email,
                      },
                    })
                  }
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <Visibility />
                </IconButton>
              </div>
            ),
          };
        })
      : [];

  const openFilterDrawer = () => {
    setFilter(true);
  };

  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };

  const closeFilterDrawer1 = () => {
    console.log(filterDatas);
    setFilter(false);
  };

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    handleDownload();
    setSelectedDate(null);
    setPage(1);
    dispatch(getWalletDetails(1, rowsPerPage, "", "", "", "", "", ""));
    closeFilterDrawer();
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const body = {
      user_name: filterDatas.user_name ? filterDatas.user_name : "",
      mobile_number: filterDatas.mobile_number ? filterDatas.mobile_number : "",
      email: filterDatas.email ? filterDatas.email : "",
      transaction_type: filterDatas.transaction_type
        ? filterDatas.transaction_type
        : "",
      month_year: formattedDateFilter,
      transactionId: filterDatas.transactionId ? filterDatas.transactionId : "",
    };
    handleDownload(body);
    setFilterDatas(body);

    setPage(1);
    dispatch(
      getWalletDetails(
        page,
        rowsPerPage,
        body.email,
        body.mobile_number,
        body.transaction_type,
        body.user_name,
        body.month_year,
        body.transactionId
      )
    );
    closeFilterDrawer1();
  };

  const [isNotValidEmail, setIsNotValidEmail] = useState();
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;

    if (name === "email" && value && !emailRegex.test(value)) {
      setIsNotValidEmail("Please enter valid email");
      setIsValidEmail(false);
    } else {
      setIsNotValidEmail();
      setIsValidEmail(true);
    }
    if (name === "mobile_number" && value && !numericRegex.test(value)) {
      return;
    }
    if (name === "referral_code" && value && !numericRegex.test(value)) {
      return;
    }

    setFilterDatas((prevFilterData) => {
      handleDownload({
        ...prevFilterData,
        [name]: value,
      });
      return { ...prevFilterData, [name]: value };
    });
  };

  const handleSelectChange = (event) => {
    setSelectedMonth(dayjs(event.target.value, "MM/YYYY"));
    // setSelectedMonth(event.target.value);
  };

  const handleDownload = async (downloadFilterData = {}) => {
    try {
      const res = await axios.get(
        `${base_Url}wallet/download-wallet-transaction?month_year=${
          downloadFilterData.month_year ? downloadFilterData.month_year : ""
        }&transaction_type=${
          downloadFilterData.transaction_type
            ? downloadFilterData.transaction_type
            : ""
        }&user_name=${
          downloadFilterData.user_name ? downloadFilterData.user_name : ""
        }&mobile_number=${
          downloadFilterData.mobile_number
            ? downloadFilterData.mobile_number
            : ""
        }&email=${
          downloadFilterData.email ? downloadFilterData.email : ""
        }&transactionId=${
          downloadFilterData.transactionId
            ? downloadFilterData.transactionId
            : ""
        }`
      );
      if (res.data.status) {
        setSelectedMonth(formattedDate);
        setDownLoadData(res?.data?.data);
      } else {
        setDownLoadData("");
        //snackBar("No data available for download.", false);
        setSelectedMonth(formattedDate);
      }
    } catch {
      setDownLoadData("");
    }
  };

  useEffect(() => {
    handleDownload();
  }, []);

  return (
    <Box>
      <PageHeader
        onFilterClick={openFilterDrawer}
        title="Wallet Transactions"
        onAddClick={handleClickOpen}
        isShowAdd={!!getPermissions(permissions.wallet, "is_add") && false}
        href={downLoadData}
      />
      <Box
        sx={{
          paddingLeft: "20px",
        }}
      >
        {/* <FormControl sx={{ maxWidth: "400px", width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Select Month</InputLabel>
          <Select
            defaultValue={sortedMonthYearArray[0]}
            onChange={handleSelectChange}
            label="Select Month"
            placeholder="Select Month"
          >
            {loading && <MenuItem disabled>Loading...</MenuItem>}
            {!loading &&
              (sortedMonthYearArray.length > 0 ? (
                sortedMonthYearArray.map((monthYear, index) => (
                  <MenuItem key={index} value={monthYear}>
                    {monthYear}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No data found</MenuItem>
              ))}
          </Select>
        </FormControl> */}
        {/* <Button
          variant="contained"
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            border: "0",
            height: "53px",
            // color: isAnyFieldNotEmpty ? "#fff" : "#fff !important",
            ":hover": { bgcolor: "#1976d2", border: "0" },
            backgroundColor: "#153e80",
            marginLeft: {
              xs: "0px",
              sm: "15px",
              md: "15px",
              lg: "15px",
            },
            color: "#fff",
            textTransform: "none",
            minWidth: "100px",
          }}
          // disabled={(!wallet?.monthly_transaction?.length || selectedMonth == '')}
          onClick={handleDownload}
        >
          Download
        </Button> */}
      </Box>
      {getWalletLoading ? (
        <Loader />
      ) : (
        <EnhancedTable
          rowsPerPage={rowsPerPage}
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={wallet?.walletData}
        />
      )}
      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div>
              <Box>
                <Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="User Name"
                  name="user_name"
                  value={filterDatas?.user_name}
                  onChange={handleChange}
                  maxLength={50}
                />
              </Box>
              <Box>
                <Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Email"
                  name="email"
                  value={filterDatas.email}
                  onChange={handleChange}
                  maxLength={50}
                />
                <p
                  style={{
                    marginLeft: "10px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                  className="err-msg err-msg-font"
                >
                  {isNotValidEmail}
                </p>
              </Box>
              <Box>
                <Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Mobile Number"
                  name="mobile_number"
                  value={filterDatas.mobile_number}
                  onChange={handleChange}
                  maxLength={20}
                />
              </Box>
              <Box>
                <Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="TransactionId"
                  name="transactionId"
                  value={filterDatas.transactionId}
                  onChange={handleChange}
                  maxLength={80}
                />
              </Box>
              <Box>
                <SelectBox
                  name="transaction_type"
                  label="Transaction type"
                  placeholder="Transaction Type"
                  // disabled={isAnyFieldFilled && !filterDatas.status}
                  ClassName="status-select-filter"
                  value={filterDatas.transaction_type}
                  onChange={handleChange}
                  options={[
                    { label: "credit", value: "credit" },
                    { label: "debit", value: "debit" },
                  ]}
                />
              </Box>
              <Box sx={{ marginTop: "12px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Select Month Year"
                    openTo="month"
                    views={["year", "month"]}
                    format="MM/YYYY"
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    className="custom-date-picker"
                  />
                </LocalizationProvider>
              </Box>
              <Stack className={classes.buttonFilter}>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
      {/* <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      /> */}
      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            Showing {wallet?.currentPage} of {wallet?.totalPages} Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={wallet?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default WalletList;
