import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Input from "../../../common/Input";
import { useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Yup from "yup";
import SelectBox from "../../../common/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createService,
  getAllService,
  updateService,
} from "../../../redux/action/ServiceAction";
import MuiPhoneNumber from "material-ui-phone-number";
import { makeStyles } from "@material-ui/core/styles";
import {
  createFAQ,
  getFAQCategory,
  updateFAQ,
} from "../../../redux/action/FaqsAction";
import Loader from "../../../components/loader/Loader";

const useStyles = makeStyles((theme) => ({
  customCircularProgress: {
    width: "25px !important",
    height: "25px !important",
    color: "#fff !important",
  },
}));

const styles = {
  root: {
    borderBottom: "1px solid black",
  },
  icon: {
    color: "black",
    fill: "black",
  },
};

const customStyles = {
  option: {
    color: "red", // Set the text color to red
  },
};

const CreateFAQs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const classes = useStyles();
  const { _id, category_name, question, answer, description, faqid } =
    location?.state?.faqsData;
  const navigate = useNavigate();

  const { getFaqLoading, createFaqLoading, updateFaqLoading, FaqData } =
    useSelector((state) => state.FaqsReducer);

  useEffect(() => {
    getFAQsCategory();
  }, []);

  const getFAQsCategory = () => {
    dispatch(getFAQCategory());
  };

  const [addedDiscritption, setAddedDiscritption] = useState(
    description && description.length
      ? description
      : [{ title: "", points: [""] }]
  );

  const [selectedCategoryId, setSelectedCategoryId] = useState();

  const handleSelectCategoryChange = (e) => {
    const cate_id = e.target.value;
    setSelectedCategoryId(cate_id);
  };

  const UserSchema = Yup.object().shape({
    faq_category: Yup.string().required("Please select category"),
    question: Yup.string().required("Please enter question"),
    answer: Yup.string().required("Please enter answer"),
  });

  const handleClose = () => {
    navigate("/admin-faqs");
  };
  const formik = useFormik({
    initialValues: {
      faq_category: _id || "",
      question: question || "",
      answer: answer || "",
    },
    validationSchema: UserSchema,
    onSubmit: async (values, { resetForm }) => {
      const body = {
        faq_category: values.faq_category, //selectedCategoryId,
        question: values.question,
        answer: values.answer,
      };
      const formData = new FormData();

      if (location.state.formName === "Create Faqs") {
        formData.append("faq_category", body.faq_category);
        formData.append("question", body.question);
        formData.append("answer", body.answer);
      } else {
        formData.append("faq_category", body.faq_category);
        formData.append("question", body.question);
        formData.append("answer", body.answer);
      }
      const resetInitForm = () => {
        resetForm();
      };

      if (location.state.formName === "Create Faqs") {
        dispatch(createFAQ(formData));
        resetInitForm();
        handleClose();
      } else {
        await dispatch(updateFAQ(faqid, formData));
        navigate("/admin-faqs");
      }
    },
  });

  const handleRemoveAddedDesc = (ind, subDataInd) => {
    setAddedDiscritption((prev) => {
      const data = prev.map((dataobj, index) => {
        if (ind == index) {
          return {
            title: dataobj.title,
            points: dataobj["points"].filter(
              (data, indexx) => indexx !== subDataInd
            ),
          };
        }
        return dataobj;
      });
      return data;
    });
  };
  const handleDiscriptionAdd = () => {
    setAddedDiscritption([
      ...addedDiscritption,
      {
        title: "",

        points: [],
      },
    ]);
  };
  const addSubDesc = (dataInd) => {
    setAddedDiscritption((prev) => {
      const data = prev.map((dataobj, index) => {
        if (dataInd == index) {
          return {
            title: dataobj.title,
            points: [...dataobj["points"], ""],
          };
        }
        return dataobj;
      });
      return data;
    });
  };

  const handleRemoveAddedDiscription = (index) => {
    setAddedDiscritption((prev) => {
      const data = prev.filter((dataobj, previndex) => previndex !== index);
      return data;
    });
  };
  const classes = styles;
  const useClasses = useStyles();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
          // height: "100vh",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/admin-faqs")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <form onSubmit={formik.handleSubmit}>
                  <Typography
                    textAlign="center"
                    fontWeight={900}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                  >
                    {location.state.formName}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <FormControl fullWidth>
                        <InputLabel labelId="test-select-label">
                          FAQs Category
                        </InputLabel>
                        <Select
                          id="faq_category"
                          name="faq_category"
                          label="FAQs Category"
                          inputProps={{
                            classes: {
                              root: classes.border,
                              icon: classes.icon,
                            },
                          }}
                          labelId="test-select-label"
                          sx={{
                            input: { color: "#000" },
                            svg: { color: "#000" },
                            color: "#000",
                            backgroundColor: "#fff",
                          }}
                          value={formik.values.faq_category}
                          variant="outlined"
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleSelectCategoryChange(e);
                          }}
                          error={Boolean(
                            formik.touched.faq_category &&
                              formik.errors.faq_category
                          )}
                        >
                          {getFaqLoading ? (
                            <Loader />
                          ) : (
                            FaqData?.categoryList?.map((item, i) => {
                              return (
                                <MenuItem key={i} value={item._id}>
                                  {item.category_name}
                                </MenuItem>
                              );
                            })
                          )}
                        </Select>
                        {formik.touched.faq_category &&
                        formik.errors.faq_category ? (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: "12px" }}
                          >
                            <div className="err-msg-font">
                              {formik.errors.faq_category}
                            </div>
                          </Typography>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="question"
                        name="question"
                        type="text"
                        label="Question"
                        onChange={formik.handleChange}
                        value={formik.values.question}
                        onBlur={formik.handleBlur}
                        maxLength={300}
                        error={
                          formik.touched.question &&
                          Boolean(formik.errors.question)
                        }
                        helperText={
                          formik.touched.question && formik.errors.question ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.question}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="answer"
                        name="answer"
                        type="text"
                        label="Answer"
                        onChange={formik.handleChange}
                        value={formik.values.answer}
                        onBlur={formik.handleBlur}
                        maxLength={500}
                        error={
                          formik.touched.answer && Boolean(formik.errors.answer)
                        }
                        helperText={
                          formik.touched.answer && formik.errors.answer ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.answer}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>
                  {/* description code ended */}

                  {(
                    location.state.formName === "Create Faqs"
                      ? createFaqLoading
                      : updateFaqLoading
                  ) ? (
                    <Button sx={{ marginTop: 4 }} variant="contained">
                      <CircularProgress
                        style={{ width: "25px", height: "25px", color: "#fff" }}
                      />
                    </Button>
                  ) : (
                    <Button
                      sx={{ marginTop: 4 }}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateFAQs;
