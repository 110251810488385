import React, { useState } from "react";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { Button } from "@mui/material";
import ScheduleServiceDialog from "./ScheduleServiceDialog";

import img from '../../assets/GG_C_Services/service1.png'
import ShowFirstImage from "../../../components/showFirstImage/ShowFirstImage";

function subStr(txt, len) {
  let acceptedText =
    txt.length < len ?
      txt :
      txt.substring(0, len - 1) + '...';
  return acceptedText;
}


const ServiceCard = ({ item, handleModalOpen }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [getServiceId, setGetServiceId] = useState();

  const userData =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));

  const token = localStorage.getItem("access_token");

  const handleOpenScheduleServiceModule = (id) => {
    if (token) {
      setOpen(true);
      setGetServiceId(id);
    } else {
      handleModalOpen();
    }
  };

  const [scheduledServiceDetail, setScheduledServiceDetail] = useState()

  const handleOpenScheduleServiceDetailModule = (item) => {
    if (token) {
      setOpen(true);
      setScheduledServiceDetail(item)
    } else {
      handleModalOpen();
    }
  };
  const handleCloseScheduleServiceModule = () => {
    setOpen(false);
  };

  return (
    <>
      <div key={item?._id} className="blogCss">
        <div style={{width: '100%', paddingTop: '5px'}}>
          {/*IMAGE*/}
          <Link to={`/service/${item?._id}`} >
          <div className="serviceImg" >
              <img
                className="serImg allImg"
                style={{objectFit: 'cover',}}
                src={item?.service_images?.length && item?.service_images[0]}
                alt="product 1"
              />
          </div>
          </Link>
          
          {/* <div style={{ margin: '4px 4px 0 4px'}}>
                  <ShowFirstImage
                    images={item?.service_images?.length && item?.service_images}
                    height="100%"
                    width="100%"
                    link={`/service/${item?._id}`}
                  />
              </div> */}

          {/*DESCRIPTION*/}
          <div className="card-body px-1">
            {/*Description*/}
            <div className="mb-1" style={{width: '100%', marginTop: '-10px'}}>
              <div style={{width: '100%', display: "flex", alignItems: 'center', justifyContent: 'space-between', paddingRight: '5px'}}>
                  <a style={{fontWeight: 'bold', fontSize: '21px', fontWeight: 'bold', color: '#000', cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => navigate(`/service/${item?._id}`)}>{subStr(item?.service_name, 15)}</a>
                  <div style={{color: "black", fontWeight: 'bold' }}>₹ {item.service_price} </div>
              </div>
              <div className="d-flex justify-content-start">
                <span className="text-black font-size-md">
                  <a
                    href={`tel:${item?.contact_number}`}
                    target="_blank"
                    style={{color: '#1976d2', textDecoration: 'underline'}}
                  >
                    {/* <PhoneOutlinedIcon style={{ color: "inherit" }} />  */}
                    Book free consultation</a>
                </span>
              </div>
            </div>

            {/*Buttons*/}
            <div style={{display: 'flex', gap: '13px', marginTop: '12px', marginBottom: '-10px'}}>
              {item &&
                Array.isArray(item.purchased_by) &&
                item.purchased_by.includes(userData?.user?._id) ? (
                <div style={{width: '100%',}}>
                  {/* <Button
                    disabled
                    variant="outlined"
                    fullWidth
                    sx={{
                      width: "100%",
                    }}
                    // className="btnAll"
                  > */}
                    <button disabled type="button" className="btnAll font-size-md" style={{width: '100%',}}>Activated</button>
                  {/* </Button> */}
                </div>
              ) : (
                <div style={{width: '100%'}}>
                  <Button
                    variant="outlined"
                    sx={{
                      "&:hover": {
                        color: "white !important",
                        backgroundColor: "#0f2b5a !important",
                      },
                      "&:focus": {
                        color: "black !important",
                        backgroundColor: "#153e80 !important",
                      },
                    }}
                    style={{backgroundColor: '#153e80', color: '#fff', width: '100%', borderRadius: '8px' , fontSize: '16px', fontWeight: 'bold', textTransform: 'none',}}
                    onClick={() => navigate(`/service/${item?._id}`)}
                  >
                    Book Now
                  </Button>
                </div>
              )}
              {item?.service_purchased !== true ?
                <div className="font-size-lg" style={{width: '100%'}}>
                  {item?.service_scheduled && item?.scheduled_at !== null ?
                    // <Button
                    //   variant="outlined"
                    //   sx={{
                    //     "&:hover": {
                    //       color: "#153e80 !important",
                    //       backgroundColor: "#fff !important",
                    //     },
                    //     "&:focus": {
                    //       color: "#153e80 !important",
                    //       backgroundColor: "#fff !important",
                    //       // border: "1px solid #8cbae8 !important",
                    //     },
                    //   }}
                    //   style={{width: '100%', color: '#d1d1d1', border: '1px solid #d1d1d1', padding: '4px 0', borderRadius: '8px', fontSize: '16px', fontWeight: 'bold', textTransform: 'none'}}
                    //   onClick={() => handleOpenScheduleServiceDetailModule(item)}
                    // >
                      <button disabled type="button" style={{width: '100%', border:'1px solid #153e80', backgroundColor: '#fff', color: '#153e80', borderRadius:'8px', fontSize:'17px', fontWeight:'bold', padding:'4px 0',}}>Scheduled</button>
                    // </Button>
                    :
                    <Button
                      variant="outlined"
                      sx={{
                        "&:hover": {
                          color: "#0f2b5a !important",
                          // backgroundColor: "#c2dcf7 !important",
                        },
                        "&:focus": {
                          color: "#0f2b5a !important",
                          // backgroundColor: "#c2dcf7 !important",
                          border: "1px solid #8cbae8 !important",
                        },
                        // Define responsive styles
                        fontSize: {
                          xs: '0.75rem', // extra-small screens
                          sm: '0.875rem', // small screens
                          md: '0.7rem', // medium screens
                          lg: '0.80rem', // large screens
                          xl: '0.80rem', // extra-large screens
                        },
                        padding: {
                          xs: '6px 12px', // extra-small screens
                          sm: '0px 16px', // small screens
                          md: '0px 20px', // medium screens
                          lg: '0px 12px', // large screens
                          xl: '0px 28px', // extra-large screens
                        },
                        minWidth: {
                          xs: '100px', // extra-small screens
                          sm: '120px', // small screens
                          md: '140px', // medium screens
                          lg: '160px', // large screens
                          xl: '180px', // extra-large screens
                        },
                      }}
                      style={{width: '100%', color: '#153e80', border: '1px solid #153e80', padding: '4px 0', borderRadius: '8px', fontSize: '16px', fontWeight: 'bold', textTransform: 'none'}}
                      onClick={() => handleOpenScheduleServiceModule(item?._id)}
                    >
                      Schedule Activation
                    </Button>
                  }
                </div>
                : null
              }
            </div>
          </div>
        </div>
      </div>
      <ScheduleServiceDialog
        isOpen={open}
        closeModel={handleCloseScheduleServiceModule}
        getServiceId={getServiceId}
        scheduledServiceDetail={scheduledServiceDetail}
      />
    </>
  );
};

export default ServiceCard;
