import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import moment from "moment";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import {
  addScheduleService,
  editScheduleServiceById,
  getAllScheduleServiceRequests,
  getAllService,
} from "../../../redux/action/ServiceAction";
import Loader from "../../components/loader/Loader";
import { CircularProgress, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ScheduleServiceDialog = ({ isOpen, closeModel, getServiceId, editScheduleService, scheduledServiceDetail }) => {
  const { addScheduleServiceLoading, editScheduleServiceLoading } = useSelector((state) => state?.ServiceReducer)

  const dispatch = useDispatch()

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const today1 = dayjs().startOf('day');

  const UserSchema = Yup.object().shape({
    schedule_time: Yup.date()
      .required("Please enter schedule time")
      .min(today1, "Please enter present or future date")
  });

  const formik = useFormik({
    initialValues: {
      schedule_time: editScheduleService && editScheduleService?.schedule_time ? new Date(editScheduleService?.schedule_time).toISOString() : new Date(),
      note: editScheduleService ? editScheduleService.note : ""
    },
    validationSchema: UserSchema,
    onSubmit: async (values, { resetForm }) => {
      const time = new Date(values.schedule_time)
      const body = {
        service_id: editScheduleService && editScheduleService?.service_id || getServiceId,
        schedule_time: new Date(values.schedule_time).toISOString(),
        // moment(time).format("YYYY-MM-DD"),
        note: values.note,
      };


      if (getServiceId) {
        await dispatch(addScheduleService(body, closeModel));
        dispatch(getAllService(1, "", 10));
        resetInitForm();
      } else if (editScheduleService?.service_id) {
        await dispatch(
          editScheduleServiceById(editScheduleService?._id, body, closeModel)
        );
      }

      const resetInitForm = () => {
        resetForm();
      };
    },
  });

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    formik.setFieldValue(
      "schedule_time",
      editScheduleService && editScheduleService?.schedule_time
        ? new Date(editScheduleService?.schedule_time).toISOString()
        : new Date()
    );
    formik.setFieldValue(
      "note",
      editScheduleService ? editScheduleService.note : ""
    );
  }, [editScheduleService]);

  return (
    <>
      <Dialog
        onClose={closeModel}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        fullWidth={scheduledServiceDetail && scheduledServiceDetail?.service_scheduled ? true : false}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: "19px" }}>
          {scheduledServiceDetail && scheduledServiceDetail?.service_scheduled ? "Service Schedule Time Detail" : "Service Schedule Time"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {scheduledServiceDetail && (scheduledServiceDetail?.service_scheduled && scheduledServiceDetail?.scheduled_at !== null) ?
          <>
            <DialogContent dividers>
              <div style={{
                display: "flex", width: "100%"
              }}>
                <label style={{width:"150px"}}>Service Name : </label>
                <div style={{
                  display: "flex", width: "100%", overflowWrap: "break-word",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                }}>
                  <label>{scheduledServiceDetail?.service_name}</label>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <label>Service Schedule Time : </label>
                <label>{scheduledServiceDetail?.scheduled_at ? moment(scheduledServiceDetail?.scheduled_at).format("DD-MM-YYYY") : <>&#x2002;-</>}</label>
              </div>
              <div style={{
                display: "flex", width: "100%"
              }}>
                <label style={{width:"100px"}}>Note : </label>
                <div style={{
                  display: "flex", width: "100%", overflowWrap: "break-word",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                }}>
                  <label>{scheduledServiceDetail?.note ? scheduledServiceDetail?.note : <>&#x2002;-</>}</label>
                </div>
              </div>
            </DialogContent>
          </>
          :
          <form onSubmit={formik.handleSubmit}>
            <DialogContent dividers>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    id="schedule_time"
                    name="schedule_time"
                    label="Schedule Time"
                    fullWidth
                    format="DD-MM-YYYY"
                    // defaultValue={formik.values.schedule_time}
                    minDate={dayjs(new Date())}
                    value={
                      formik.values.schedule_time
                        ? dayjs(formik.values.schedule_time)
                        : null

                    }
                    onChange={(newValue) =>
                      formik.setFieldValue("schedule_time", newValue)
                    }
                    sx={{
                      color: "red",
                      "&:focus": {
                        color: "gray !important"
                      }
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        error: Boolean(
                          formik.touched.schedule_time &&
                          formik.errors.schedule_time
                        ), //error handling
                        helperText: formik.touched.schedule_time &&
                          formik.errors.schedule_time && (
                            <div
                              style={{ marginLeft: "-12px" }}
                              className="err-msg-font"
                            >
                              {formik.errors.schedule_time}
                            </div>
                          ),
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <TextField
                id="note"
                name="note"
                variant="outlined"
                type="text"
                label="Note"
                onChange={formik.handleChange}
                value={formik.values.note || ""}
                onBlur={formik.handleBlur}
                error={formik.touched.note && Boolean(formik.errors.note)}
                helperText={formik.touched.note && <div style={{ marginLeft: '-12px', fontSize: '12px' }}>{formik.errors.note}</div>}
                multiline
                fullWidth
                sx={{ marginTop: "10px" }}
                inputProps={{
                  maxLength: 200, // Maximum number of characters allowed
                }}
                rows={2} // Adjust the number of rows as needed
              />
            </DialogContent>
            <DialogActions>
              {addScheduleServiceLoading || editScheduleServiceLoading ? (
                <Button variant="outlined">
                  <CircularProgress style={{ width: "20px", height: "20px" }} />
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  type="submit"
                  autoFocus
                  sx={{
                    "&:focus": {
                      color: "black !important",
                      backgroundColor: "#c2dcf7 !important",
                      border: "1px solid #8cbae8 !important",
                    },
                  }}
                >
                  Save
                </Button>
              )}
            </DialogActions>
          </form>
        }
      </Dialog>
    </>
  )
}

export default ScheduleServiceDialog;
