import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllFAQs } from "../../../redux/action/FaqsAction";
import Loader from "../loader/Loader";

const styles = {
  bgColor: "white",
  titleTextColor: "black",
  rowTitleColor: "black",
  rowContentColor: "grey",
  arrowColor: "#153e80",
};
const config = {
  animate: true,
  arrowIcon: "V",
  // openOnload: 0,
  expandIcon: "+",
  collapseIcon: "-",
};

const Faqs = () => {
  const dispatch = useDispatch();
  const { getFaqLoading, FaqData } = useSelector((state) => state.FaqsReducer);

  const fetchFAQsData = async () => {
    await dispatch(getAllFAQs(40, 1, ""));
  };

  useEffect(() => {
    fetchFAQsData();
  }, []);

  return (
    <div>
      {getFaqLoading ? (
        <Loader />
      ) : (
        FaqData?.faqData?.length > 0 &&
        FaqData?.faqData.map((item, i) => {
          let number = ".1";
          return (
            <Faq
              key={i}
              data={{
                title: "",
                rows: [
                  {
                    title: <b>{i + 1 + "." + item.category_name}</b>,
                    content: (
                      <ol style={{ listStyleType: "none" }}>
                        {item.faqs.map((faq, j) => (
                          <li key={j} style={{ marginTop: "0.5em" }}>
                            <b>
                              {"Q" +
                                (i + 1) +
                                "." +
                                (j + 1) +
                                ":" +
                                " " +
                                faq.question}
                            </b>
                            <ul style={{ listStyleType: "none" }}>
                              <li style={{ marginLeft: "-27px" }}>
                                {`A${i + 1}.${j + 1}`}: {faq.answer}
                              </li>
                            </ul>
                          </li>
                        ))}
                      </ol>
                    ),
                  },
                ],
              }}
              styles={styles}
              config={config}
            />
          );
        })
      )}
    </div>
  );
};

export default Faqs;
