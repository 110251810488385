import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { base_Url } from "../utils/common";

const LocationContext = createContext();

export const useLocation = () => {
  return useContext(LocationContext);
};

const removeRoles = ["Community Super Admin", "Community Admin"];

export const LocationProvider = ({ children }) => {
  const [country, setCountry] = useState({});
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [postal, setPostal] = useState([]);
  const [role, setRole] = useState([]);
  const [isActiverole, setIsActiveRole] = useState([]);

  const getAllCountry = async (is_active) => {
    let url = `${base_Url}country/all-country`;
    if (is_active) {
      url = `${base_Url}country/all-country?is_active=${is_active}`;
    }
    const res = await axios.get(url);
    if (res.data.status === true) {
      setCountry(res.data.data);
      setState([]);
      setCity([]);
      setPostal([]);
    }
  };

  const getState = async (id, is_active) => {
    let url = `${base_Url}state/state-by-countryid/${id}`;
    if (is_active) {
      url = `${base_Url}state/state-by-countryid/${id}?is_active=${is_active}`;
    }
    const res = await axios.get(url);
    if (res.data.status === true) {
      setState(res.data.data);
      setCity([]);
      setPostal([]);
    }
  };

  const getCity = async (id, is_active) => {
    let url = `${base_Url}city/city-by-stateid/${id}`;
    if (is_active) {
      url = `${base_Url}city/city-by-stateid/${id}?is_active=${is_active}`;
    }
    const res = await axios.get(url);
    if (res.data.status === true) {
      setCity(res.data.data);
      setPostal([]);
    }
  };

  const getPostalCode = async (id, is_active) => {
    let url = `${base_Url}postalcode/code-by-cityid/${id}`;
    if (is_active) {
      url = `${base_Url}postalcode/code-by-cityid/${id}?is_active=${is_active}`;
    }
    const res = await axios.get(url);
    if (res.data.status === true) {
      setPostal(res.data.data);
    }
  };

  const getRole = async () => {
    const res = await axios.get(`${base_Url}role/all-roles`);
    if (res.data.status === true) {
      setRole(
        res?.data?.data?.roleData.filter(
          (item) => !removeRoles.includes(item.role)
        )
      );
    }
    var exists = res?.data?.data?.roleData?.filter(function (o) {
      return o.is_active == true;
    });
    if (exists.length > 0) {
      setIsActiveRole(exists);
    }
  };

  return (
    <LocationContext.Provider
      value={{
        country,
        getAllCountry,
        getState,
        state,
        getCity,
        getRole,
        role,
        city,
        postal,
        getPostalCode,
        setState,
        setCity,
        setPostal,
        getRole,
        role,
        isActiverole,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
