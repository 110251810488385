import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "../../redux/ApiConfig";
import { ApiURL, snackBar } from "../../redux/common";
import { Visibility } from "@mui/icons-material";

const STATUS_OPTIONS = [
  { title: "Approved", key: "approved" },
  { title: "Rejected", key: "rejected" },
  { title: "Pending", key: "pending" },
];

const DOCUMENT_TYPES = {
  PDF: "pdf",
  IMAGE: "image",
  OTHER: "other",
};

const BusinessDocCard = ({ data: businessCardData, link }) => {
  const [data, setData] = useState(businessCardData);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(data.status || "pending");
  const documentType = getDocumentType(data.business_document);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    setData(businessCardData);
  }, [businessCardData]);

  const handleUpdateStatus = async () => {
    setLoading(true);
    try {
      const payload = {
        [data?.statusKey]: status,
      };
      const res = await axios.put(
        `${ApiURL}business-doc/update/${data._id}`,
        payload
      );
      if (res?.data?.status) {
        setData({ ...data, status });
        snackBar(res?.data?.message, res?.data?.status);
      } else {
        snackBar(res?.data?.message, res?.data?.status);
      }
    } catch (error) {
      console.error("Error updating status:", error);
      snackBar("Error updating status", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card elevation={5} variant="outlined" sx={{ borderRadius: "12px" }}>
        <CardContent style={{ position: "relative" }}>
          <a rel="noreferrer" target="_blank" href={data.business_document}>
            {documentType === DOCUMENT_TYPES.PDF ? (
              <embed
                src={data.business_document}
                type="application/pdf"
                width="100%"
                height="300"
                style={{ borderRadius: "8px" }}
              />
            ) : documentType === DOCUMENT_TYPES.IMAGE ? (
              <img
                src={data.business_document}
                alt={data.document_type}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
            ) : (
              <Typography>No preview available</Typography>
            )}
          </a>

          <Typography
            variant="h5"
            gutterBottom
            style={{
              marginTop: "1.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {data.document_type}
            <a href={data.link} target="_blank" rel="noopener noreferrer">
              <IconButton
                aria-label="visible"
                sx={{
                  marginTop: "-10px",
                  marginLeft: "35px",
                }}
              >
                <Visibility />
              </IconButton>
            </a>
            {/* <Chip
              label={data?.status?.toUpperCase()}
              color={getStatusColor(data.status)}
              style={{ marginLeft: "1rem", borderRadius: "8px" }}
            /> */}
          </Typography>

          {/* {data?.disable_update ? (
            <></>
          ) : (
            <>
              <Select
                value={status}
                onChange={handleStatusChange}
                displayEmpty
                fullWidth
                style={{ marginTop: "1rem" }}
                sx={{ borderRadius: "8px" }}
              >
                {STATUS_OPTIONS.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: "1.5rem", borderRadius: "8px" }}
                onClick={handleUpdateStatus}
                disabled={loading}
              >
                {loading ? "Updating..." : "Update Approval"}
              </Button>
            </>
          )} */}

          {/* <Box>
            <a href={data.link} target="_blank" rel="noopener noreferrer">
              <IconButton
                aria-label="visible"
                sx={{
                  marginTop: "-75px",
                  marginLeft: "35px",
                }}
              >
                <Visibility />
              </IconButton>
            </a>
          </Box> */}
        </CardContent>
      </Card>
    </Grid>
  );
};

const getDocumentType = (documentUrl) => {
  if (typeof documentUrl === "string") {
    const fileExtension = documentUrl.split(".").pop().toLowerCase();
    if (fileExtension === "pdf") {
      return DOCUMENT_TYPES.PDF;
    } else if (["png", "jpg", "jpeg"].includes(fileExtension)) {
      return DOCUMENT_TYPES.IMAGE;
    }
  }
  return DOCUMENT_TYPES.OTHER;
};

const getStatusColor = (status) => {
  switch (status) {
    case "approved":
      return "success";
    case "rejected":
      return "error";
    case "pending":
      return "warning";
    default:
      return "default";
  }
};

export default BusinessDocCard;
