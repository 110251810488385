import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "../../common/useForms";
import Input from "../../common/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonCom from "../../common/Button";
import Logo from "../../assets/images/logo-home.svg";
import { themeSettings } from "../../theme";
import { makeStyles } from "@material-ui/core/styles";
import { snackBar } from "../../redux/common";
import axios from "axios";
import { base_Url } from "../../web/utils/common";

const useStyles = makeStyles((theme) => ({
  main: {
    background:
      "linear-gradient(317deg, rgba(254,118,120,1) 9%, rgba(21,62,128,1) 100%)",
    height: "100%",

    [theme.breakpoints.up("sm")]: {
      height: "100vh",
    },

    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },

    display: "flex",
    alignItems: "center",
  },
  subMain: {
    width: "450px",
    margin: "0 auto",
    backgroundColor: "#fff",
    borderRadius: "20px",
  },
  resendButton: {
    color: "#153e80 !important",
    fontWeight: 'bold',
    "&.Mui-disabled": {
      fontWeight: 600,
      color: "gray !important", // Change text color to white when disabled
    },
  },

  logo: {
    marginBottom: "20px",
    width: "60% !important",
    display: "block",
    margin: "0 auto",
  },
  mainCard: {
    boxShadow: "none !important",
    color: "#000 !important",
    display: "block",
    textAlign: "center",
    minWidth: 275,
  },
  boxCard: {
    [theme.breakpoints.up("xs")]: {
      padding: "20px",
    },

    [theme.breakpoints.up("md")]: {
      padding: "40px",
    },
  },

  typography: {
    fontSize: "16px !important",
    // fontWeight: "bold !important",
    color: "#000",
    textAlign: 'center',
  },

  resendBtnMain: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "13px",
  },

  subResendBtnMain: {
    display: "block",
    margin: "auto 0",
  },

  send: {
    ":hover": { bgcolor: "#153E80", color: "#FFFFFF" },
    marginTop: "20px !important",
    textTransform: "none !important",
    fontSize: "16px !important",
    color: "#FFFFFF !important",
    width: "100%",
    backgroundColor: "#153E80 !important",
    border: "2px solid #153E80 !important",
    borderRadius: "15px !important",
    padding: "10px 40px !important",
    "&.Mui-disabled": {
      backgroundColor: "#153E80 !important",
      color: "#fff !important",
    },
    marginLeft: "3px !important",
  },
}));

const Otp = () => {
  const classes = useStyles();
  const otpTimer = 120;
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { email, mobile_number, phone_code } = location?.state?.credential;

  const typography = themeSettings(theme.palette.mode);

  const [OTP, setOTP] = useState();
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(otpTimer);

  const handleChange = (event) => {
    setOTP((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };



  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const digit1 = useRef(null);
  const digit2 = useRef(null);
  const digit3 = useRef(null);
  const digit4 = useRef(null);
  const digit5 = useRef(null);
  const digit6 = useRef(null);

  const handleOTP = (e) => {
    if (e.key === "Backspace") {
      if (e.target.name === "digit2") {
        digit1.current.focus();
      }
      if (e.target.name === "digit3") {
        digit2.current.focus();
      }
      if (e.target.name === "digit4") {
        digit3.current.focus();
      }
      if (e.target.name === "digit5") {
        digit4.current.focus();
      }
      if (e.target.name === "digit6") {
        digit5.current.focus();
      }
    } else {
      if (e.target.name === "digit1" && e.target.value) {
        digit2.current.focus();
      }
      if (e.target.name === "digit2" && e.target.value) {
        digit3.current.focus();
      }
      if (e.target.name === "digit3" && e.target.value) {
        digit4.current.focus();
      }
      if (e.target.name === "digit4" && e.target.value) {
        digit5.current.focus();
      }
      if (e.target.name === "digit5" && e.target.value) {
        digit6.current.focus();
      }
    }
  };

  const handleOtpChange = async (e) => {
    e.preventDefault();
    setCounter(otpTimer);

    digit1.current.value = "";
    digit2.current.value = "";
    digit3.current.value = "";
    digit4.current.value = "";
    digit5.current.value = "";
    digit6.current.value = "";
    const loginData = email
      ? { email: email }
      : { mobile_number: Number(mobile_number) };
    try {
      const res = await axios.post(
        `${base_Url}user/forgot-password`,
        loginData
      );
      if (res.data.status === true) {
        snackBar(res?.data?.message, res?.data?.status);
      } else {
        snackBar(res?.data?.message, res?.data?.status);
      }
    } catch (err) {
      snackBar("Please enter valid mobile number", false);
    }
  };

  const handleOtpVerifyClick = async () => {
    const payload = {};
    setLoading(true);
    if (email) {
      payload.email = email;
    } else {
      payload.mobile_number = mobile_number;
      payload.phone_code = phone_code;
    }

    payload.otp = Number(Object.values(OTP).join(""));
    try {
      const res = await axios.post(`${base_Url}user/verify-otp`, payload);
      if (res.data.status === true) {
        setLoading(false);
        snackBar(res?.data?.message, res?.data?.status);
        navigate("/admin/confirmpassword", {
          state: {
            token: res.data.data,
          },
        });
      } else {
        setLoading(false);
        snackBar(res?.data?.message, res?.data?.status);
      }
    } catch {
      setLoading(false);
      snackBar("Something went wrong", false);
    }
  };

  return (
    <Box className={classes.main}>
      <Box className={classes.subMain}>
        <Grid
          container
          item
          columns={{ xs: 4, sm: 12, md: 12 }}
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={12}>
            <Box className={classes.boxCard} style={{marginBottom: '-14px'}}>
              {/* <Link to="/">
                <CardMedia
                  component="img"
                  image={Logo}
                  alt="Paella dish"
                  className={classes.logo}
                />
              </Link> */}
              <h2 className="font-weight-bold text-center">OTP Verification</h2>
              <p style={{fontSize: '14px', color: '#717171', textAlign: 'center', marginBottom: '22px'}}>Verify your one time password</p>
              {/* <Box>
                <Card className={classes.mainCard}>
                  <CardContent>
                    <Typography fontSize={typography.typography.h5.fontSize}>
                      Welcome
                    </Typography>
                    <Typography fontSize={typography.typography.h5.fontSize}>
                      to
                    </Typography>
                    <Typography fontSize={typography.typography.h5.fontSize}>
                      Global Garner Community
                    </Typography>
                    <br />
                    <Typography fontSize={typography.typography.h5.fontSize}>
                      Building a stronger tomorrow
                    </Typography>
                  </CardContent>
                </Card>
              </Box> */}

              <div className={classes.typography} sx={{textAlign:"center"}} style={{marginTop: '35px', fontSize: '10px', color: '#717171'}}>
                One-time-password has been sent to your {email ? "email" : "mobile"}.
              </div>

              <Form>
                <Grid>
                  <Box display="flex" style={{marginLeft: '-20px', marginTop: '10px'}}>
                    <Box
                      sx={{
                        marginLeft: "10px",
                      }}
                    >
                      <Input
                        margin="normal"
                        fullWidth
                        id="digit1"
                        name="digit1"
                        type="text"
                        inputRef={digit1}
                        onChange={handleChange}
                        onKeyUp={handleOTP}
                        maxLength={1}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Box>
                    <Box sx={{ marginLeft: "10px" }}>
                      <Input
                        margin="normal"
                        fullWidth
                        id="digit2"
                        name="digit2"
                        type="text"
                        inputRef={digit2}
                        onChange={handleChange}
                        onKeyUp={handleOTP}
                        maxLength={1}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Box>{" "}
                    <Box sx={{ marginLeft: "10px" }}>
                      <Input
                        margin="normal"
                        fullWidth
                        id="digit3"
                        name="digit3"
                        type="text"
                        inputRef={digit3}
                        onChange={handleChange}
                        onKeyUp={handleOTP}
                        maxLength={1}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Box>{" "}
                    <Box sx={{ marginLeft: "10px" }}>
                      <Input
                        margin="normal"
                        fullWidth
                        id="digit4"
                        name="digit4"
                        type="text"
                        inputRef={digit4}
                        onChange={handleChange}
                        onKeyUp={handleOTP}
                        maxLength={1}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Box>{" "}
                    <Box sx={{ marginLeft: "10px" }}>
                      <Input
                        margin="normal"
                        fullWidth
                        id="digit5"
                        name="digit5"
                        type="text"
                        inputRef={digit5}
                        onChange={handleChange}
                        onKeyUp={handleOTP}
                        maxLength={1}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Box>{" "}
                    <Box sx={{ marginLeft: "10px" }}>
                      <Input
                        margin="normal"
                        fullWidth
                        id="digit6"
                        name="digit6"
                        type="text"
                        inputRef={digit6}
                        onChange={handleChange}
                        onKeyUp={handleOTP}
                        maxLength={1}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Box>
                  </Box>
                  <div className={classes.resendBtnMain} style={{marginTop: '-13px', marginBottom: '15px'}}>
                    <div className={classes.subResendBtnMain} style={{marginLeft: '-9px'}}>
                      <span>
                        Time Remaining:{" "}
                        {counter % 60 < 10
                          ? `${Math.floor(counter / 60)} : 0${counter % 60}`
                          : `${Math.floor(counter / 60)} : ${counter % 60}`}
                      </span>
                    </div>
                    <div style={{marginRight: '-13px'}}>
                      <Button
                        variant="text"
                        className={classes.resendButton}
                        disabled={!!counter}
                        onClick={handleOtpChange}
                        style={{fontWeight: 'bold', textTransform: 'none', fontSize: '14px'}}
                      >
                        Resend OTP
                      </Button>
                    </div>
                  </div>
                </Grid>
                <ButtonCom
                  className={classes.send}
                  // type="submit"
                  disabled={
                    OTP && Object.values(OTP).join("").length == 6 && counter
                      ? false
                      : true
                  }
                  text={
                    loading ? (
                      <CircularProgress
                        sx={{
                          color: "#fff",
                          width: "28px !important",
                          height: "28px !important",
                        }}
                      />
                    ) : (
                      "Verify OTP"
                    )
                  }
                  // onClick={() => navigate("/admin/dashboard")}
                  onClick={handleOtpVerifyClick}
                ></ButtonCom>
              </Form>
              <div onClick={() =>navigate("/")} style={{margin: '8px auto 0', textAlign: 'center', color: '#515151', cursor: 'pointer', fontWeight: '600'}}>Back to Home</div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Otp;
