import { Autocomplete, Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import UserHeader from "../header/Header";
import { useFormik } from "formik";
import * as Yup from "yup";
import MuiPhoneNumber from "material-ui-phone-number";
import { useDispatch, useSelector } from "react-redux";
import './beMemeberForm.css'
import Input from "../../common/Input";
import { GetAllCountry } from "../../redux/action/CountryAction";
import { GetAllStates, cleareAllStates } from "../../redux/action/StateAction";
import { GetAllCities, cleareAllCities } from "../../redux/action/CityAction";
import { GetAllPostalCodes, clearePostalCodes } from "../../redux/action/PostalCodeAction";
import { CreatePartnerReq } from "../../redux/action/PartnerRequestAction";
import BeMemberFormComponents from "./BeMemberFormComponents";

// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
var emailValidation =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  email: Yup.string()
    .required("Please enter email")
    .matches(emailValidation, "Please enter valid email"),
  mobile_number: Yup.string().required("Please enter mobile number"),
  city: Yup.object().required("Please select city"),
  state: Yup.object().required("Please select state"),
  country: Yup.object().required("Please select country"),
  postal: Yup.object().required("Please select postal code"),
  // message: Yup.string().required("Please enter message"),
});

const BeMemberForm = () => {
  const dispatch = useDispatch();

  const {
    allStateData,
  } = useSelector((state) => state.StateReducer);
  const {
    allCountryData
  } = useSelector((state) => state.CountryReducer);
  const {
    allCitiesData
  } = useSelector((state) => state.CityReducer);
  const {
    allPostalData
  } = useSelector((state) => state.PostalCodeReducer);
  useEffect(() => {
    dispatch(GetAllCountry(true))
    // dispatch(GetAllPostalCodes())
  }, [])

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile_number: "",
      city: "",
      state: "",
      country: "",
      postal: "",
      message: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values, { resetForm }) => {
      const number = values.mobile_number.split(" ");
      const body = {
        name: values.name,
        email: values.email,
        phone_code: number[0]?.replace("+", ''),
        mobile_number: number[1]?.replace("-", ''),
        message: values.message || "-",
        city_id: values.city._id, 
        country_id: values.country._id, 
        state_id: values.state._id, 
        postalcode_id: values.postal._id
      }
      dispatch(CreatePartnerReq(body));
      resetForm();
    },
  });


  return (
    <div>
      <Box
        sx={{
          margin: 5,
        }}
      >
        <Grid container spacing={{ xs: 2, md: 5, sm: 2, xl: 5, lg: 5 }}>
          <Grid item md={5}>
            <Box>
              <Typography
                fontWeight={900}
                fontSize={28}
                marginBottom={2}
                color="black"
              >
                Request Demo
              </Typography>
              <Typography
                fontWeight={900}
                fontSize={16}
                marginBottom={2}
                color="gray"
              >
                Tell us a few details and we’ll conduct a review of your threat
                profile and identify the threats that can be addressed with
                CyberSiARA. One of our executives will demonstrate our
                platform’s approach to remediating automated and human-driven
                fraud and abuse.
              </Typography>
            </Box>
          </Grid>

          <Grid item md={7} xs={12} sm={12}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
                <BeMemberFormComponents />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BeMemberForm;
