import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Dialog,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Logo from "../../assets/image/blog/Login.svg";
import { LoginApi } from "../../redux/authAction";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import { snackBar } from "../../utils/common";
import { useDispatch } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import parsePhoneNumberFromString from "libphonenumber-js";

import cancel from '../../assets/cancel.svg';

const phoneValidation = (value) => {
  if (!value) {
    return false;
  }
  const phoneNumber = parsePhoneNumberFromString(value);
  return phoneNumber && phoneNumber.isValid();
};

function extractPhoneNumber(input) {
  const phoneNumber = parsePhoneNumberFromString(input);

  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.nationalNumber;
  }

  return null;
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  },
  subMain: {
    margin: "0 auto",
    backgroundColor: "#e4e4e4",
    borderRadius: "20px",
  },

  mainCard: {
    boxShadow: "none !important",
    backgroundColor: "#fff !important",
    color: "#000 !important",
    display: "block",
    textAlign: "center",
  },
  boxCard: {
    width: "360px",
    padding: "40px",
  },
  typography: {
    fontSize: "25px !important",
    fontWeight: "600 !important",
    color: "rgb(255, 87, 34)",
  },
}));

const Login = ({ open, handleClose, toNav }) => {
  const uuid = uuidv4();

  const rememberMeCookieString = Cookies?.get("rememberMe");

  let rememberMeCookie = {};
  if (rememberMeCookieString) {
    try {
      rememberMeCookie = JSON.parse(rememberMeCookieString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }

  const initialValues = {
    email: rememberMeCookie?.email || "",
    mobile_number: rememberMeCookie?.mobile_number || "",
    password: rememberMeCookie?.password || "",
  };

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [data, setData] = useState({});
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    ...(!data?.mobile_number && {
      email: Yup.string()
        .email("Invalid email address")
        .required("Please enter email"),
    }),
    ...(!data?.email && {
      mobile_number: Yup.string()
      .required("Please enter mobile number")
      .test("is-valid-phone", "Mobile number is not valid", phoneValidation),
    }),
    password: Yup.string()
      .required("Please enter password")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]).{8,}$/,
        "Password must contain at least one special character, one uppercase letter, one lowercase letter, and one number, no space allowed"
      ),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const loginData = values.email
        ? { password: values.password, email: values.email, device_id: uuid }
        : {
            password: values.password,
            phone_code: values?.country_code ? Number(values?.country_code) : 91,
            mobile_number: Number(extractPhoneNumber(values.mobile_number)),
            device_id: uuid,
          };
      if (rememberMe) {
        Cookies.set("rememberMe", JSON.stringify(loginData), { expires: 7 });
      }
      const response = await LoginApi(loginData);

      if (response?.status === true) {
        handleClose();
        resetForm();
        snackBar(response?.message, response?.status);
        dispatch({
          type: "AUTH_SUCCESS",
          payload: response,
        });
        if (toNav) {
          navigate("/admin/dashboard");
        }
      } else {
        snackBar(
          response?.message || "Please provide valid password!",
          response?.status
        );
      }
    } catch (error) {
      snackBar("User Not Found!");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="food-login">
        <Box component="form" noValidate sx={{ width: "600px",}}>
          <Dialog
            open={open}
            onTouchCancel={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Grid
              container
              component="main"
              width="100%"
              sx={{
                padding: {xs:"30px",md:"55px",lg:"55px",xl:"55px"},
                backgroundColor: "white",
              }}
              style={{position: 'relative',}}
            >
              <figure onClick={handleClose} className="cancelBtn">
                <img src={cancel} alt="Cancel" className="w-full h-full" />
              </figure>
              
              <Grid
                item
                xs={false}
                sm={4}
                md={5}
                px={2}
                justifyContent="center"
                display="flex"
                alignItems="center"
              >
                <CardMedia
                  component="img"
                  src={Logo}
                  alt="Live from space album cover"
                  sx={{
                    width: "100%",
                    display: { xs: "none", sm: "block" },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={7}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="left"
              >
                {/*Welcome and Login*/}
                <Box
                  sx={{
                    my: 1,
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    width: { xs: "212px", md: "300px" },
                  }}
                >
                  <h2 className="font-weight-bold text-center" style={{fontSize: '27px'}}>Welcome to GGC</h2>
              <p style={{fontSize: '16px', color: '#717171', textAlign: 'center', marginBottom: '22px', marginTop: '-7px'}}>Login your account</p>
              <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      isSubmitting,
                      values,
                      handleChange,
                      setFieldError,
                      errors,
                      setErrors,
                      setFieldValue,
                      ...rest
                    }) => {
                      setData(values);
                      return (
                        <Form>
                          <div className="form-group mb-2">
                            <Field
                              type="text"
                              // id="email"
                              name="email"
                              placeholder="Email"
                              className="email-input"
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "5px",
                                border: "1px solid #dee2e6",
                                paddingLeft: "10px",
                              }}
                              disabled={values.mobile_number}
                              maxLength={40}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              style={{
                                marginTop: "5px",
                                color: "red",
                                fontSize: "15px",
                              }}
                            />
                          </div>
                          <Typography
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: "10px",
                            }}
                          >
                            OR
                          </Typography>

                          <div className="form-group mb-2">
                            <MuiPhoneNumber
                              type="text"
                              fullWidth
                              defaultCountry={"in"}
                              size="small"
                              variant="outlined"
                              id="mobile_number"
                              name="mobile_number"
                              placeholder="Mobile Number"
                              className="mobile_number-input"
                              disabled={values.email}
                              onChange={(e,value) => {
                                setFieldValue("country_code",value.dialCode)
                                setFieldValue("mobile_number", e)
                              }}
                            />

                            <ErrorMessage
                              name="mobile_number"
                              component="div"
                              style={{
                                marginTop: "5px",
                                color: "red",
                                fontSize: "15px",
                              }}
                            />
                          </div>
                          <div
                            style={{ position: "relative" }}
                            className="form-group mb-2"
                          >
                            <Field
                              type={showPassword ? "text" : "password"}
                              id="password"
                              name="password"
                              placeholder="Password"
                              className="password-input"
                              style={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "5px",
                                border: "1px solid #dee2e6",
                                paddingLeft: "10px",
                              }}
                              maxLength={50}
                            />
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "2px",
                                color: "black",
                                right: "2px",
                                "&:hover": {
                                  backgroundColor: "lightgray",
                                  color: "black",
                                },
                                "&:focus": {
                                  backgroundColor: "white",
                                  color: "black",
                                },
                              }}
                              onClick={togglePasswordVisibility}
                              aria-label={
                                showPassword ? "Hide password" : "Show password"
                              }
                            >
                              {!showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>

                            <ErrorMessage
                              name="password"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "15px",
                              }}
                            />
                          </div>
                          <div className="form-group mb-6">
                            <div className="custom-control custom-checkbox">
                              <input
                                value={rememberMe}
                                type="checkbox"
                                className="custom-control-input"
                                id="check"
                                onClick={(e) => setRememberMe(e.target.checked)}
                              />
                              <label
                                className="custom-control-label text-dark"
                                htmlFor="check"
                              >
                                Remember Me
                              </label>
                            </div>
                          </div>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btnAll font-size-lg rounded-md"
                            style={{width: '100%'}}
                          >
                            {isSubmitting ? (
                              <CircularProgress color="inherit" size="26px" />
                            ) : (
                              "Log In"
                            )}
                          </button>

                          {/* </Grid> */}

                          {/* <Link className="mb-8" to="be-member-form">
                            Sign Up / Register
                          </Link> */}
                        </Form>
                      );
                    }}
                  </Formik>
                </Box>

                {/*Sign Up and Forgot password*/}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="left"
                  sx={{ marginLeft: "15px" }}
                >
                  <Link
                    to="/be-member-form"
                    // variant="body2"
                    className="navLink"
                    style={{fontSize: '15px'}}
                    onClick={handleClose}
                  >
                    Don't have an account? Sign Up
                  </Link>
                  <Link
                    to="/admin/resetpassword"
                    // variant="body2"
                    className="navLink"
                    style={{fontSize: '15px'}}
                    onClick={handleClose}
                  >
                    Reset Password?
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Dialog>
        </Box>
      </div>
    </div>
  );
};

export default Login;
