import * as React from "react";
import { Box, FormHelperText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { InputLabel } from "@mui/material";
import Menu from "@mui/material/Menu";
export default function SelectBox(props) {
  const {
    name,
    value,
    onChange,
    maxRows = 0,
    options,
    disabled,
    touched,
    ClassName,
    error,
    label,
    onBlur,
    style,
    mt,
    ...other
  } = props;

  const handleChange = (...rest) => {
    // if(onChange){
    //   onChange(...rest);
    // }
    document.documentElement.classList.add('no-scroll');
  };
  const handleClose = (...rest) => {
    if(onBlur){
      onBlur(...rest);
    }
    document.documentElement.classList.remove('no-scroll');
  };
  
  return (
    <Box sx={{ minWidth: 160, mt: mt || 2, color: "red" }}>
      <FormControl fullWidth size="small" {...(error && { error: true })}>
        <InputLabel
          sx={{
            "&.MuiInputLabel-root": {
              // marginTop: "8px",
              color: "rgba(0, 0, 0, 0.87)",
              // borderColor: "rgba(0, 0, 0, 0.87)",
              fontSize: "14px",
              fontWeight: "500",
            },

            "&.MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
              borderRadius: "7px",
              paddingTop: "0px",
              paddingBottom: "3px",
              borderColor: "blue",
            },
            "&.MuiOutlinedInput-root.Mui-focused": {
              borderColor: "red",
            },
            "&.MuiInputLabel-root.Mui-error": {
              color: "#fc9494!important",
            },
          }}
        >
          {label}
        </InputLabel>
        <Select
          name={name}
          sx={{
            paddingTop: "0px",
            paddingBottom: "5px",
            borderRadius: "5px",
            color: "black",
            input: { color: "#000" },

            ".MuiOutlinedInput-notchedOutline": {
              // borderColor: "rgba(107, 114, 128, .5)",
            },
            "&.MuiOutlinedInput-root": {
              "& fieldset": {
                // borderColor: "rgba(107, 114, 128)",
              },
              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "blue",
              },
            },
            "&.MuiOutlinedInput-root.Mui-error": {
              "& fieldset": {
                borderColor: "#fc9494!important",
              },
              "&:hover fieldset": {
                borderColor: "#fc9494!important",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#fc9494!important",
              },
            },
            ".css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
              {
                paddingTop: "0!important",
              },
            ".MuiInputLabel-root": {
              color: "rgb(92 91 91 / 70%)",
            },
            ".MuiSelect-select": {
              // color: "rgb(92 91 91 / 100%)",
            },
            "&.Mui-focused .MuiOutlinedInput-root": {
              borderColor: "red",
            },
            ".MuiOutlinedInput-notchedOutline.Mui-focused": {
              borderColor: "red",
            },
            "& label.Mui-focused": {
              color: "#4f46e5!important",
            },
            "& .MuiPaper-root-MuiMenu-paper-MuiPopover-paper": {
              // backgroundColor: "white !important",
            },
            ".MuiSvgIcon-root ": {
              fill: "rgb(92 91 91 / 70%) !important",
            },
          }}
          id="demo-select-small"
          value={value}
          label={label}
          disabled={disabled}
          onOpen={handleChange}
          onChange={onChange}
          onClose={handleClose}
          onBlur={handleClose}
          error={touched && Boolean(error)}
          {...other}
          {...(error && { error: true, helperText: error })}
        >
          {/* <MenuItem value="" sx={{ color: "#000!important" }}>
            None
          </MenuItem> */}
          {options?.map((item) => {
            return (
              <MenuItem
                key={item.id}
                value={item.value}
                sx={{ color: "#000!important" }}
              >
                {item.name || item.label || item.first_name || item.last_name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText style={{ color: "#d32f2f" }}>
          {touched && error ? <div>{error}</div> : null}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
