import React from 'react'
import { Outlet } from 'react-router-dom';
import PendingRequest from '../../../pages/PendingRequest';

const ApprovedRoute = () => {
    const userDataString = localStorage.getItem("user_data");
    const userData = JSON.parse(userDataString);
    const approval_status =
        userData?.user?.approval_status ||
        userData?.admin?.approval_status ||
        "approved";
    if (approval_status !== "approved") {
        return <PendingRequest />
    }
    return (
        <><Outlet /></>
    )
}

export default ApprovedRoute;