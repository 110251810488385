import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Input from "../../common/Input";
import { useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Yup from "yup";
import SelectBox from "../../common/SelectBox";
import {
  CreateMembership,
  GetMembership,
  UpdateMembership,
} from "../../redux/action/MembershipAction";
import { useDispatch, useSelector } from "react-redux";
import { InputLabel } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";

const rangeOptions = [
  { label: "Thousand", value: "thousand" },
  { label: "Lakhs", value: "lakhs" },
  { label: "Crore", value: "cr" },
];

const isActiveOptions = [
  { value: "true", label: "ACTIVE" },
  { value: "false", label: "INACTIVE" },
];

const CreateMembershipForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    _id,
    is_active,
    description,
    max_range_amount,
    membership_name,
    min_range_amount,
    monthly_price,
    range_type,
    yearly_price,
  } = location?.state?.membershipData;
  const [activate, setAvtivate] = useState(monthly_price > 0);


  const [descriptionFields, setDescriptionFields] = useState(
    description && description.length ? [description[1]] : []
  );

  const [addedDiscritption, setAddedDiscritption] = useState(
    description && description.length
      ? description
      : [{ title: "", "sub-points": [""] }]
  );

  const descriptions = descriptionFields.filter((field, index) => field || "");
  const navigate = useNavigate();

  const addDescriptionField = () => {
    setDescriptionFields([...descriptionFields, ""]);
  };

  const removeDescriptionField = (index) => {
    const updatedFields = [...descriptionFields];
    updatedFields.splice(index, 1);
    setDescriptionFields(updatedFields);
  };

  const MembershipSchema = Yup.object().shape({
    membership_name: Yup.string()
    .required("Please enter membership name")
    .matches(/^[A-Za-z\s]+$/, "Numerics are not valid in membership name"),
    min_range_amount: Yup.number()
      .typeError("min range amount must be a number")
      .positive("min range amount must be a positive number")
      .required("Please enter minimum range amount"),
    max_range_amount: Yup.string()
      .required("Please enter maximum range amount")
      .test(
        "is-yearly-price-required",
        "Maximum price range is lower than minimum price",
        function (value) {
          const minPrice = this.parent.min_range_amount || 0; // Handling null or undefined min_range_amount
          if (parseFloat(value) < parseFloat(minPrice)) {
            return false;
          }
          return true;
        }
      ),
    // description: Yup.string().required("Please enter Description"),
    monthly_price:
      activate &&
      Yup.number()
        .typeError("Monthly Price must be a number")
        .positive("Monthly Price must be a positive number")
        .integer("Monthly Price must be an integer")
        .required("Monthly Price is required")
        .test(
          "is-yearly-price-required",
          "Monthly price is greter than yearly price",
          function (value) {
            const minPrice = this.parent.yearly_price || 0; // Handling null or undefined min_range_amount
            if (minPrice) {
              if (parseFloat(value) > parseFloat(minPrice)) {
                return false;
              }
            }
            return true;
          }
        ),
    yearly_price: Yup.number()
      .typeError("Yearly Price must be a number")
      .positive("Yearly Price must be a positive number")
      .integer("Yearly Price must be an integer")
      .required("Yearly Price is required")
      .test(
        "is-monthly-price-required",
        "Yearly price is lower than monthly price",
        function (value) {
          const minPrice = this.parent.monthly_price || 0; // Handling null or undefined min_range_amount
          if (parseFloat(value) < parseFloat(minPrice)) {
            return false;
          }
          return true;
        }
      ),
    range_type: Yup.string().required("Please select your range type"),
  });

  const formik = useFormik({
    initialValues: {
      membership_name: membership_name || "",
      // turnover_range: "",
      description: description?.[0] || "",
      min_range_amount: min_range_amount || "",
      max_range_amount: max_range_amount || "",
      range_type: range_type || "",
      // max_range_type: "",
      monthly_price: activate || monthly_price > 0 ? monthly_price : "",
      yearly_price: yearly_price || "",
      is_active: is_active ? "true" : "false" || "",
    },
    validationSchema: MembershipSchema,
    onSubmit: (values, { resetForm }) => {
      // values.description = [values.description, ...descriptions]
      let notEmptyValue = [];
      for (let i = 0; i < addedDiscritption.length; i++) {
        if (addedDiscritption[i].title !== '') {
          notEmptyValue.push(addedDiscritption[i])
        }
      }
      const body = {
        ...values,
        description: notEmptyValue,
      };
      if (!activate) {
        body.monthly_price = 0;
      }
      if (location.state.formName === "Create Membership") {
        delete body.is_active;
      }

      if (location.state.formName === "Create Membership") {
        dispatch(CreateMembership(body));
        resetForm();
        dispatch(GetMembership(1, "", 10));
        navigate("/admin-memberships");
      } else {
        dispatch(UpdateMembership(_id, body));
        dispatch(GetMembership(1, "", 10));
        navigate("/admin-memberships");
      }
    },
  });

  const handleDiscriptionAdd = () => {
    setAddedDiscritption([
      ...addedDiscritption,
      { title: "", "sub-points": [] },
    ]);
  };

  const addSubDesc = (dataInd) => {
    setAddedDiscritption((prev) => {
      const data = prev.map((dataobj, index) => {
        if (dataInd == index) {
          return {
            title: dataobj.title,
            "sub-points": dataobj["sub-points"]
              ? [...dataobj["sub-points"], ""]
              : [""],
          };
        }
        return dataobj;
      });
      return data;
    });
  };

  const handleRemoveAddedDesc = (ind, subDataInd) => {
    setAddedDiscritption((prev) => {
      const data = prev.map((dataobj, index) => {
        if (ind == index) {
          return {
            title: dataobj.title,
            "sub-points": dataobj["sub-points"].filter(
              (data, indexx) => indexx !== subDataInd
            ),
          };
        }
        return dataobj;
      });
      return data;
    });
  };

  const handleRemoveAddedDiscription = (index) => {
    setAddedDiscritption((prev) => {
      const data = prev.filter((dataobj, previndex) => previndex !== index);
      return data;
    });
  };

  const activeMonthlyPrice = () => {
    setAvtivate(!activate);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
          // height: "100vh",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/admin-memberships")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <Typography
                  textAlign="center"
                  fontWeight={900}
                  fontSize={28}
                  marginBottom={2}
                  color="black"
                >
                  {location.state.formName}
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                  <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                    Membership Name:
                  </Typography>
                  <Input
                    id="membership_name"
                    name="membership_name"
                    type="text"
                    label="Membership Name"
                    onChange={formik.handleChange}
                    value={formik.values.membership_name}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.membership_name &&
                      Boolean(formik.errors.membership_name)
                    }
                    helperText={
                      formik.touched.membership_name &&
                        formik.errors.membership_name ? (
                        <div className="err-msg err-msg-font">
                          {formik.errors.membership_name}
                        </div>
                      ) : null
                    }
                    maxLength={50}
                  />

                  <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                    Turnover Range:
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Input
                        id="min_range_amount"
                        name="min_range_amount"
                        type="number"
                        label="Minimum Range"
                        onChange={formik.handleChange}
                        value={formik.values.min_range_amount}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.min_range_amount &&
                          Boolean(formik.errors.min_range_amount)
                        }
                        helperText={
                          formik.touched.min_range_amount &&
                            formik.errors.min_range_amount ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.min_range_amount}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <Input
                        id="max_range_amount"
                        name="max_range_amount"
                        type="number"
                        label="Maximum Range"
                        onChange={formik.handleChange}
                        value={formik.values.max_range_amount}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.max_range_amount &&
                          Boolean(formik.errors.max_range_amount)
                        }
                        helperText={
                          formik.touched.max_range_amount &&
                            formik.errors.max_range_amount ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.max_range_amount}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>
                  <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                    Range Type:
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sm={12}
                      sx={{ marginTop: "-8px" }}
                    >
                      <div>
                        <SelectBox
                          type="text"
                          id="range_type"
                          name="range_type"
                          label="Range Type"
                          ClassName="range-select-filter"
                          value={formik.values.range_type}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          options={rangeOptions}
                          error={
                            formik.touched.range_type &&
                            Boolean(formik.errors.range_type)
                          }
                          touched={formik.touched.range_type}
                        />
                        {formik.touched.range_type &&
                          formik.errors.range_type && (
                            <div
                              style={{
                                color: "rgb(211, 47, 47)",
                                fontWeight: "400",
                                fontSize: "0.7428571428571428rem",
                                lineHeight: "1.66",
                                textAlign: "left",
                              }}
                            >
                              {formik.errors.range_type}
                            </div>
                          )}
                      </div>
                    </Grid>
                  </Grid>

                  {/* description code started */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                      Membership Description:
                    </Typography>
                    <Button variant="contained" onClick={handleDiscriptionAdd}>
                      + Add Desc...
                    </Button>
                  </div>

                  {addedDiscritption?.map((data, index) => {
                    return (
                      <>
                        <div
                          style={{
                            border: index !== 0 ? "2px solid lightgray" : "",
                            padding: index !== 0 ? "5px" : "",
                            borderRadius: index !== 0 ? "5px" : "",
                            marginTop: index !== 0 ? "10px" : "",
                            marginBottom: index !== 0 ? "10px" : "",
                          }}
                        >
                          <div
                            key={index}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid container spacing={2}>
                              <Grid
                                sx={{ display: "flex", justifyContent: "end" }}
                                item
                                md={12}
                                xs={12}
                                sm={12}
                              >
                                {index !== 0 && (
                                  <Button
                                    color="error"
                                    variant="contained"
                                    style={{
                                      marginLeft: "10px",
                                      border: "2px solid",
                                    }}
                                    onClick={() =>
                                      handleRemoveAddedDiscription(index)
                                    }
                                  >
                                    X
                                  </Button>
                                )}
                              </Grid>
                              <Grid item md={12} xs={12} sm={12}>
                                <Input
                                  value={addedDiscritption[index].title}
                                  type="text"
                                  label="Title"
                                  maxLength={50}
                                  onChange={(e) => {
                                    setAddedDiscritption((prev) => {
                                      const data = prev.map(
                                        (dataobj, indofprev) => {
                                          if (indofprev == index) {
                                            return {
                                              title: e.target.value,
                                              "sub-points":
                                                dataobj["sub-points"],
                                            };
                                          }
                                          return dataobj;
                                        }
                                      );
                                      return data;
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </div>
                          {data["sub-points"]?.map((field, subIndex) => (
                            <div
                              key={subIndex}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Input
                                maxRows={2}
                                maxLength={300}
                                type="text"
                                label="Description"
                                value={
                                  addedDiscritption[index]["sub-points"][
                                  subIndex
                                  ]
                                }
                                onChange={(e) => {
                                  setAddedDiscritption((prev) => {
                                    const data = prev.map(
                                      (dataobj, indofprev) => {
                                        if (indofprev == index) {
                                          return {
                                            title: dataobj.title,
                                            "sub-points": dataobj[
                                              "sub-points"
                                            ].map((data, subpoiIndex) => {
                                              if (subpoiIndex == subIndex) {
                                                return e.target.value;
                                              }
                                              return data;
                                            }),
                                          };
                                        }
                                        return dataobj;
                                      }
                                    );
                                    return data;
                                  });
                                }}
                              />
                              <Button
                                color="error"
                                variant="outlined"
                                style={{
                                  marginLeft: "10px",
                                  border: "2px solid",
                                }}
                                onClick={() =>
                                  handleRemoveAddedDesc(index, subIndex)
                                }
                              >
                                X
                              </Button>
                            </div>
                          ))}
                          <div
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <Button
                              color="success"
                              variant="contained"
                              style={{
                                marginLeft: "10px",
                                border: "2px solid",
                              }}
                              onClick={() => addSubDesc(index)}
                            >
                              + Add Sub Desc...
                            </Button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <Typography variant="h6" sx={{ marginTop: "0.5rem" }}>
                    Yearly Price:
                  </Typography>
                  <Input
                    name={`yearly_price`}
                    maxLength={10}
                    type="number"
                    value={formik.values.yearly_price}
                    label="Yearly Price"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.yearly_price &&
                      Boolean(formik.errors.yearly_price)
                    }
                    helperText={
                      formik.touched.yearly_price && (
                        <div className="err-msg err-msg-font">
                          {formik.errors.yearly_price}
                        </div>
                      )
                    }
                  />
                  {location.state.formName !== "Create Membership" && (
                    <Grid container spacing={2}>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        sm={12}
                        sx={{ marginTop: "-8px" }}
                      >
                        <SelectBox
                          id="is_active"
                          name="is_active"
                          label="Is Active"
                          ClassName="range-select-filter"
                          value={formik.values.is_active}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          options={isActiveOptions || []}
                          error={
                            formik.touched.is_active &&
                            Boolean(formik.errors.is_active)
                          }
                          touched={formik.touched.is_active}
                        />
                        {formik.touched.is_active &&
                          formik.errors.is_active && (
                            <div className="err-msg-font">
                              {formik.errors.is_active}
                            </div>
                          )}
                      </Grid>
                    </Grid>
                  )}

                  <Button
                    sx={{ marginTop: 4 }}
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateMembershipForm;
