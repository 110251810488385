import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_Url } from "../../utils/common";
import Loader from "../../components/loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import Login from "../auth/Login";
import PaymentGateway from "../../components/PaymentGateway";
import { api } from "../../config/api";
import { snackBar } from "../../../redux/common";
import RegistrationLoader from "../../components/loader/RegisterLoader";
import ComCard from "../../../common/ComCard";
import PricingDesc from "./PricingDesc";

const Pricing = () => {
  const [getMemberShipData, setMemberShipData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [monthlyPriceAvailable, setMonthlyPriceAvailable] = useState();
  const [planSelected, setPlanSelected] = useState("yearly");
  const [successModal, setSuccessModal] = useState({
    data: {},
    success: false,
    open: false,
  });

  const token = localStorage.getItem("access_token");

  const handleModalOpen = () => {
    setLoginModalOpen(true);
  };

  const handleModalClose = () => {
    setLoginModalOpen(false);
  };

  useEffect(() => {
    Membership();
  }, []);

  const Membership = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${base_Url}membership/get-all-memberships?is_active=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMemberShipData(res?.data?.data?.membershipData);
      const membershipdata = res?.data?.data?.membershipData || [];
      const isMonthlyPriceAvailable = membershipdata.some(
        (element) =>
          element.hasOwnProperty("monthly_price") && element.monthly_price > 0
      );
      setMonthlyPriceAvailable(isMonthlyPriceAvailable);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePlanSelected = (plan) => {
    setPlanSelected(plan);
  };

  const handleAfterPayment = async (res, membership) => {
    try {
      setPaymentLoading(true);
      let body = {
        membership_id: membership._id,
        razorpay_payment_id: res?.razorpay_payment_id,
      };
      await api
        .post(`${base_Url}membership/purchase-membership`, body)
        .then((res) => {
          if (res.status) {
            snackBar(res?.message, res?.status);
            setSuccessModal({
              success: true,
              open: true,
              data: {
                membership_id: membership._id,
                razorpay_payment_id: body?.razorpay_payment_id,
              },
            });
          } else {
            snackBar(res?.message, res?.status);
            setSuccessModal({
              success: false,
              open: true,
              data: {
                membership_id: membership._id,
                razorpay_payment_id: body?.razorpay_payment_id,
              },
            });
          }
        });
    } catch (error) {
      snackBar("Something went wrong!", false);
      setSuccessModal({
        success: false,
        open: true,
        data: {
          membership_id: membership._id,
          razorpay_payment_id: res?.razorpay_payment_id,
        },
      });
    } finally {
      setPaymentLoading(false);
    }
  };

  const handlePaymentModalClose = () => {
    setSuccessModal({ success: false, open: false, data: {} });
    navigate("/admin/dashboard");
  };

  const [showAll, setShowAll] = useState({});

  const handleReadMore = (descIndex, index) => {
    setShowAll((prevState) => ({
      ...prevState,
      [index]: {
        [descIndex]: prevState[index] ? !prevState[index][descIndex] : true,
      },
    }));
  };
  return (
    <div>
      {paymentLoading && <RegistrationLoader />}

      <div id="wrapper-content" className="wrapper-content pt-7 pb-7">
        <div className="container">
          <div id="pricing-table" className="section-pricing-table pt-0">
            <div className="d-flex flex-wrap align-item-center justify-content-center mb-7">
              {monthlyPriceAvailable ? (
                <button
                  style={{ minWidth: "23%", fontSize: "90%" }}
                  className={`${
                    planSelected == "monthly"
                      ? "btn-primary"
                      : "btn-white border"
                  } btn mr-5 font-weight-bold rounded-0`}
                  onClick={() => handlePlanSelected("monthly")}
                >
                  Monthly
                </button>
              ) : null}
              <button
                style={{ minWidth: "23%", fontSize: "90%" }}
                className={`${
                  planSelected == "yearly" ? "btn-primary" : "btn-white border"
                } btn font-weight-bold rounded-0`}
                onClick={() => handlePlanSelected("yearly")}
              >
                Yearly
              </button>
            </div>
            <div className="card-deck">
              <Grid container spacing={2}>
                {loading ? (
                  <Loader />
                ) : (
                  getMemberShipData &&
                  getMemberShipData.map((item, i) => {
                    if (planSelected == "monthly" && item?.monthly_price <= 0) {
                      return <></>;
                    }
                    return (
                      <Grid item xs={12} sm={6} md={4} key={i}>
                        <div
                          className={`pricing-table card rounded-0 ${
                            i == 1 ? "bg-primary text-white" : ""
                          }`}
                        >
                          <div className="card-header bg-transparent border-0 p-0">
                            <div
                              className={`${
                                i == 1
                                  ? "font-weight-semibold font-size-md mb-3 text-uppercase"
                                  : "font-weight-semibold text-dark font-size-md mb-3 text-uppercase"
                              }`}
                            >
                              {item.membership_name}
                            </div>
                            <div className="mb-5">
                              {planSelected === "monthly" ? (
                                <>
                                  <span
                                    className={`price ${
                                      i === 1 ? "text-white" : "text-primary"
                                    }`}
                                  >
                                    &#x20B9;{" "}
                                    {item.monthly_price.toLocaleString()}{" "}
                                  </span>
                                  <span>/</span>
                                  <span>Mo</span>
                                </>
                              ) : (
                                <>
                                  <span
                                    className={`price ${
                                      i === 1 ? "text-white" : "text-primary"
                                    }`}
                                  >
                                    &#x20B9;{" "}
                                    {item.yearly_price.toLocaleString()}
                                  </span>
                                  <span className="mx-2">/Yr</span>
                                </>
                              )}
                            </div>
                          </div>
                          <div
                            className="card-body px-0 pt-5 pb-7"
                            style={{ overflowY: "scroll", maxHeight: "400px" }}
                          >
                            <ul className="features list-group list-group-flush list-group-borderless">
                              {item.description.map((desc, descIndex) => (
                                <>
                                  <PricingDesc
                                    desc={desc}
                                    key={descIndex}
                                    descIndex={descIndex}
                                    i={i}
                                  />
                                </>
                              ))}
                            </ul>
                          </div>
                          {token ? (
                            <PaymentGateway
                              amount={
                                planSelected == "monthly"
                                  ? item.monthly_price
                                  : item.yearly_price
                              }
                              afterPayment={(res) =>
                                handleAfterPayment(res, item)
                              }
                            >
                              <div className="card-footer bg-transparent border-0 mt-auto p-0">
                                <button
                                  style={{ marginTop: "40px" }}
                                  className={`${
                                    i !== 1
                                      ? "btn btn-primary btn-block lh-lg font-weight-bold rounded-0"
                                      : "btn btn-white lh-lg text-primary btn-block font-weight-bold rounded-0"
                                  }`}
                                >
                                  Enroll Now
                                </button>
                              </div>
                            </PaymentGateway>
                          ) : (
                            <div className="card-footer bg-transparent border-0 mt-auto p-0">
                              <button
                                onClick={handleModalOpen}
                                className={`${
                                  i !== 1
                                    ? "btn btn-primary btn-block lh-lg font-weight-bold rounded-0"
                                    : "btn btn-white lh-lg text-primary btn-block font-weight-bold rounded-0"
                                }`}
                              >
                                Enroll Now
                              </button>
                            </div>
                          )}
                        </div>
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Login open={loginModalOpen} handleClose={handleModalClose} />

      <ComCard
        success={successModal?.success}
        open={successModal?.open}
        handleClose={handlePaymentModalClose}
      >
        <p>
          <b>Membership Id: </b>
          {successModal?.data?.membership_id}
        </p>
        <p>
          <b>Razorpay Payment Id: </b>
          {successModal?.data?.razorpay_payment_id}
        </p>
      </ComCard>
    </div>
  );
};

export default Pricing;
