import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const ComCard = ({ success = false, children, handleClose, open }) => {
  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        ></DialogTitle>
        <DialogContent dividers sx={{ width: "340px" }}>
          {success ? (
            <CheckCircleIcon
              color="success"
              fontSize="large"
              sx={{
                justifyContent: "center",
                display: "flex",
                width: "100%",
                height: "100px",
              }}
            />
          ) : (
            <CancelIcon
              color="error"
              fontSize="large"
              sx={{
                justifyContent: "center",
                display: "flex",
                width: "100%",
                height: "100px",
              }}
            />
          )}
          <Typography gutterBottom>{children}</Typography>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ComCard;
