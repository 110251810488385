import { Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { PieChart, Pie, Legend, Tooltip } from "recharts";
import NoDataChart from "../nodatachart/NoDataChart";

const BaeCharts = ({ data, title, onClick }) => {
  return (
    <div>
      <PieChart width={1000} height={400}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data}
          onClick={onClick}
          cx={200}
          cy={"50%"}
          outerRadius={80}
          fill="#8884d8"
          label
        />
        <Tooltip />
      </PieChart>
    </div>
  );
};

export default BaeCharts;
