import React from "react";
import { Banner } from "../Banners/Banner";
import { Link } from "react-router-dom";

import nextArrow from "../../assets/GG_C_Events/whtRightArw.svg";

export const Head = ({ title }) => {
  return (
    <>
      <div className="hideBanner w-full relative ha">
        <div className="w-full opacity-7">
          <Banner bannerTitle={title} />
        </div>

        <div className="h-full bg-000 opacity-5 w-full absolute t-0"></div>

        {title === "Some Tips & Articles" ? (
          <div className="mb-2 BannerData">
            <h1 className="w-full text-36 font-weight-bold text-fff">
              {title}
            </h1>

            <Link
              to="/" 
              className="headLink font-weight-bold text-17"
            >
              Home
            </Link>
            <span className="text-fff mleft-1 font-weight-bold text-17">
              {" "}
              <img src={nextArrow} />
            </span>
            <span
              className="text-fff mleft-1 font-weight-bold text-17">
              {title}
            </span>
          </div>
        ) : (
          <div
            className="mb-2 absolute text-center"
            style={{top: "33%",left: "45.8%"}}
          >
            <h1 className="w-full text-36 font-weight-bold text-fff">
              {title}
            </h1>
            <Link
              to="/" 
              className="headLink font-weight-bold text-17"
            >
              Home
            </Link>
            <span className="text-fff mleft-1 font-weight-bold text-20">
              {/* {" /"} */}
              <img src={nextArrow} />
            </span>
            <span className="text-fff mleft-1 font-weight-bold text-17">
              {title}
            </span>
          </div>
        )}
      </div>

      <div className="showBanner">
        <div className="flex flex-col items-center justify-center">
          <h1 className="w-full text-36 font-weight-bold ma2y0 text-000 text-center">
            {title}
          </h1>

          <div className="flex gap-03">
            <Link
              to="/"
              className="text-000 font-weight-bold text-17 decoration-none"
            >
              Home
            </Link>
            <span className="text-000 mleft-1 font-weight-bold text-17">
              {" /"}
              {/* <img src={nextArrow} /> */}
            </span>
            <span className="text-000 mleft-1 font-weight-bold text-17">
              {title}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
