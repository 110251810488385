import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
  InputLabel,
  IconButton,
  InputAdornment,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import OutlinedInput from "@mui/material/OutlinedInput";
import Input from "../../common/Input";
import { FormHelperText, Input as MUIInput } from "@material-ui/core";
import { useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Yup from "yup";
import SelectBox from "../../common/SelectBox";
import {
  CreateMembership,
  UpdateMembership,
} from "../../redux/action/MembershipAction";
import { Visibility, VisibilityOff } from "../../assets/icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GetAllCountry } from "../../redux/action/CountryAction";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  GetAllStates,
  cleareAllStates,
} from "../../redux/action/RoleandPermissionAction";
import { GetAllCities, cleareAllCities } from "../../redux/action/CityAction";
import {
  GetAllPostalCodes,
  clearePostalCodes,
} from "../../redux/action/PostalCodeAction";
import {
  CreateUserManage,
  GetAllUser,
  GetUserRole,
  UpdateUserManage,
} from "../../redux/action/UserManageAction";
import { getChapterAll } from "../../redux/action/ChapterAction";
import { api } from "../../web/config/api";
import { ApiURL } from "../../redux/common";
import { GetAllChapter } from "../../redux/action/ChapterAction";
import parsePhoneNumberFromString from "libphonenumber-js";
import Loader from "../loader/Loader.js";

var emailValidation =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Transgender", value: "transgender" },
  { label: "Other", value: "other" },
];

function formatPhoneNumber(countryCode, mobileNumber) {
  // Convert mobileNumber to a string and remove any non-digit characters
  const formattedMobileNumber = mobileNumber?.toString()?.replace(/\D/g, "");

  // Check if the country code contains a plus sign, and add one if not
  const formattedCountryCode = countryCode?.toString()?.startsWith("+")
    ? countryCode
    : `+${countryCode}`;

  // Use regular expressions to format the mobile number as desired
  const formattedNumber = formattedMobileNumber?.replace(
    /(\d{5})(\d{5})/,
    "$1-$2"
  );

  // Combine the formatted country code and mobile number
  const formattedPhoneNumber = `${formattedCountryCode} ${formattedNumber}`;

  return formattedPhoneNumber;
}

const removeRoles = ["Community Super Admin", "Community Admin"];
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const phoneValidation = (value) => {
  if (!value) {
    return false;
  }
  const phoneNumber = parsePhoneNumberFromString(value);
  return phoneNumber && phoneNumber.isValid();
};

const CreateUserForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(dayjs(""));
  const [roleId, setRoleId] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [referredData, setReferredData] = useState();
  const [referredActive, setReferredActive] = useState(false);
  const [loading, setLoading] = useState(true);  // State to track loading status

  const nameRegex = /^[a-zA-Z]+([a-zA-Z\s]*[a-zA-Z]+)?$/;

  const UserSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("Please enter first name")
      .matches(nameRegex, "First name should contain only alphabets and spaces"),
    last_name: Yup.string()
      .required("Please enter last name")
      .matches(nameRegex, "Last name should contain only alphabets and spaces"),
    email: Yup.string()
      .required("Please enter email")
      .matches(emailValidation, "Please enter valid email"),
    mobile_number: Yup.string()
      .required("Please enter mobile number")
      .test("is-valid-phone", "Mobile number is not valid", phoneValidation),
    gender: Yup.string().required("Please select gender"),
    birth_date: Yup.date()
      .required("Please select date of birth")
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "You must be at least 18 years old"
      ),
    address: Yup.string()
      .required("Please enter address")
      .matches(/^\s*\S.*/, "Please enter address"),
    country: Yup.object().required("Please select country"),
    state: Yup.object().required("Please select state"),
    city: Yup.object().required("Please select city"),
    pincode: Yup.object().required("Please select pincode"),
    role: Yup.array()
      .required("Please select a role")
      .min(1, "Please select a role"),
    referred_by : referredActive && Yup.string().required("Please select reffered by"),
    chapter: Yup.string().required("Please select chapter"),
    password:
      location.state.formName === "Create User" &&
      Yup.string()
        .required("Please enter password")
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
          "Password must contain at least one uppercase letter, one lowercase letter, and one number"
        ),
    confirm_password:
      location.state.formName === "Create User" &&
      Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const [selectedImageError1, setSelectedImageError1] = useState("");

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "image/JPEG",
      "image/JPG",
      "image/PNG",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/svg",
      "image/webp",
      "image/avi",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
      "image/mpeg-2",
    ];
    if (file && allowedTypes.includes(file.type)) {
      setSelectedImage(file);
      setSelectedImageError1("");
    } else {
      setSelectedImageError1("Please select valid image file");
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { allStateData } = useSelector((state) => state.StateReducer);
  const { allCountryData } = useSelector((state) => state.CountryReducer);
  const { allCitiesData } = useSelector((state) => state.CityReducer);
  const { allPostalData } = useSelector((state) => state.PostalCodeReducer);
  const { roleData, createLoading, updateLoading } = useSelector(
    (state) => state.UserManageReducer
  );

  useEffect(() => {
    if (allStateData && allStateData.length > 0) {
      setLoading(false);
    }
  }, [allStateData]);

  useEffect(() => {
    if (allCitiesData && allCitiesData.length > 0) {
      setLoading(false);
    }
  }, [allCitiesData]);

  useEffect(() => {
    if (allPostalData && allPostalData.length > 0) {
      setLoading(false);
    }
  }, [allPostalData]);

  const { getAllChapter, chapter } = useSelector(
    (state) => state.ChapterReducer
  );

  const allChapterData =
    chapter &&
    chapter?.chapterData?.map((el) => {
      return { value: el._id, label: el.chapter_name };
    });

  const isActiveOptions = [
    { value: "true", label: "ACTIVE" },
    { value: "false", label: "INACTIVE" },
  ];

  const allRolesData =
    roleData &&
    roleData?.roleData
      ?.map((el) => {
        return { value: el._id, label: el.role, isActive: el.is_active };
      })
      ?.filter(function (o) {
        if (o.isActive == true && (!o.label.includes("Community Super Admin")) && !o.label.includes("Community Admin")) {
          return o.label;
        }
      });

  // .filter((data) => !removeRoles.includes(data.label));
  const {
    _id,
    address,
    birth_date,
    is_active,
    state_name,
    city_name,
    country_name,
    postal_code,
    chapterId_refferalType,
    city_id,
    state_id,
    country_id,
    email,
    first_name,
    gender,
    last_name,
    role,
    phone_code,
    postalcode_id,
    mobile_number,
    role_id,
    profile_picture,
    user_roles,
    referred_by,
    name,
    phone_no,
  } = location?.state?.userData;

  useEffect(() => {
    dispatch(GetAllCountry(true));
    dispatch(GetUserRole());
    dispatch(getChapterAll());
    dispatch(GetAllChapter("", "", "", "", "",true));
  }, [dispatch]);

  useEffect(() => {
    if (location?.state?.userData && allRolesData) {
      const rolename = allRolesData.find(
        (data) => data.value === role_id
      )?.label;
      if (rolename === "Zonal Chapter Director") {
        setRoleId(true);
      } else {
        setRoleId(false);
      }
    }
  }, [location?.state?.userData, allRolesData]);

  const [selectedImage, setSelectedImage] = useState(profile_picture);


  const formik = useFormik({
    initialValues: {
      first_name: first_name || name || "",
      last_name: last_name || "",
      email: email || "",
      mobile_number:
        phone_no || mobile_number
          ? formatPhoneNumber(phone_code || "+91", mobile_number || phone_no)
          : "+91",
      gender: gender || "",
      birth_date: birth_date || "",
      address: address || "",
      country: { _id: country_id || "", country_name: country_name || "" },
      state: { _id: state_id || "", state_name: state_name || "" },
      city: { _id: city_id || "", city_name: city_name || "" },
      pincode: { _id: postalcode_id || "", postal_code: postal_code || "" },
      role: user_roles || (role_id ? [role_id] : []),
      referred_by: referred_by || "",
      chapter: chapterId_refferalType || "",
      is_active: is_active ? "true" : "false" || "",
      password: "",
      confirm_password: "",
    },
    validationSchema: UserSchema,
    onSubmit: async (values, { resetForm }) => {
      const number = values.mobile_number.split(" ");
      const birthDate = new Date(values.birth_date);
      const formattedDate = birthDate.toISOString().split("T")[0];
      const body = {
        first_name: values.first_name,
        last_name: values.last_name,
        birth_date: formattedDate,
        address: values.address,
        city_id: values.city._id,
        state_id: values.state._id,
        postalcode_id: values.pincode._id,
        country_id: values.country._id,
        gender: values?.gender?.toLowerCase(),
        phone_code: number[0]?.replace("+", ""),
        mobile_number: number[1]?.replace("-", ""),
        email: values.email,
        user_roles: Array.isArray(values.role) ? values.role.filter(Boolean) : [values.role].filter(Boolean),
        referred_by: values.referred_by,
        password: values.password,
        confirm_password: values.confirm_password,
        chapter_id: values.chapter,
        is_active: values.is_active,
        profile_picture: selectedImage,
      };
      const formData = new FormData();

      if (location.state.formName === "Create User") {
        formData.append("first_name", body.first_name);
        formData.append("last_name", body.last_name);
        formData.append("birth_date", body.birth_date);
        formData.append("address", body.address);
        formData.append("city_id", body.city_id);
        formData.append("state_id", body.state_id);
        formData.append("postalcode_id", body.postalcode_id);
        formData.append("country_id", body.country_id);
        formData.append("gender", body.gender.toLowerCase());
        formData.append("phone_code", body.phone_code);
        formData.append("mobile_number", body.mobile_number);
        formData.append("email", body.email);
        formData.append("user_roles", JSON.stringify(body?.user_roles));
        formData.append("referred_by", body?.referred_by || "");
        formData.append("password", body.password);
        formData.append("confirm_password", body.confirm_password);
        formData.append("chapterId_refferalType", body.chapter_id);
        formData.append("profile_picture", selectedImage);
      } else {
        formData.append("first_name", body.first_name);
        formData.append("last_name", body.last_name);
        formData.append("birth_date", body.birth_date);
        formData.append("address", body.address);
        formData.append("city_id", body.city_id);
        formData.append("state_id", body.state_id);
        formData.append("postalcode_id", body.postalcode_id);
        formData.append("country_id", body.country_id);
        formData.append("gender", body.gender.toLowerCase());
        formData.append("phone_code", body.phone_code);
        formData.append("mobile_number", body.mobile_number);
        formData.append("email", body.email);
        formData.append("user_roles", JSON.stringify(body?.user_roles));
        // if (!roleId || !referredActive) {
        formData.append("referred_by", body?.referred_by || "");

        formData.append("is_active", body.is_active);
        formData.append("chapterId_refferalType", body.chapter_id);
        formData.append(
          "profile_picture",
          selectedImage ? selectedImage : profile_picture
        );
      }
      const resetInitForm = () => {
        resetForm();
        setSelectedImage(null);
      };

      if (location.state.formName === "Create User") {
        dispatch(
          CreateUserManage(formData, () => {
            resetForm();
            navigate("/admin-user");
            resetInitForm();
          })
        );
      } else {
        const res = await dispatch(UpdateUserManage(_id, formData));       
        navigate("/admin-user");
        dispatch(GetAllUser(1, "", "", "", "", "", "", "", "", "", "", ""));
      }
    },
  });

  const getRefferals = async (id) => {
    try {
      const res = await api.post(`${ApiURL}user/get-parent-role-users`, {
        roles: id,
        chapter_id: formik.values.chapter,
      });
      const allRefferedData =
        res &&
        res?.data?.map((el) => {
          return {
            value: el.user_id,
            label: el.user_name,
            ref_role: el.user_role,
          };
        });
      setReferredData(allRefferedData);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (role_id) {
      getRefferals([role_id]);
    }
  }, []);

  useEffect(() => {
    if (country_id) {
      dispatch(GetAllStates(country_id, true));
      dispatch(cleareAllStates());
      dispatch(cleareAllCities());
      dispatch(clearePostalCodes());
    }
  }, [formik?.values?.country]);

  useEffect(() => {
    if (country_id && state_id) {
      dispatch(GetAllCities(state_id, true));
      dispatch(cleareAllCities());
      dispatch(clearePostalCodes());
    }
  }, [formik?.values?.state]);

  useEffect(() => {
    if (country_id && state_id && city_id) {
      dispatch(GetAllPostalCodes(city_id, true));
      dispatch(clearePostalCodes());
    }
  }, [formik?.values?.city]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
          // height: "100vh",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/admin-user")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <form onSubmit={formik.handleSubmit}>
                  <Typography
                    textAlign="center"
                    fontWeight={900}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                  >
                    {location.state.formName}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Input
                        id="first_name"
                        name="first_name"
                        type="text"
                        maxLength={20}
                        label="First Name"
                        onChange={formik.handleChange}
                        value={formik.values.first_name}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.first_name &&
                          Boolean(formik.errors.first_name)
                        }
                        helperText={
                          formik.touched.first_name &&
                          formik.errors.first_name ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.first_name}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <Input
                        id="last_name"
                        name="last_name"
                        type="text"
                        label="Last Name"
                        maxLength={20}
                        onChange={formik.handleChange}
                        value={formik.values.last_name}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.last_name &&
                          Boolean(formik.errors.last_name)
                        }
                        helperText={
                          formik.touched.last_name &&
                          formik.errors.last_name ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.last_name}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        label="Email"
                        maxLength={50}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={
                          formik.touched.email && formik.errors.email ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.email}
                            </div>
                          ) : null
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "9px" }}>
                        <MuiPhoneNumber
                          fullWidth
                          defaultCountry={"in"}
                          size="small"
                          variant="outlined"
                          id="mobile_number"
                          name="mobile_number"
                          label="Mobile Number"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={(value) => {
                            formik.setFieldValue("mobile_number", value);
                          }}
                          value={formik.values.mobile_number || "+91"}
                          error={
                            formik.touched.mobile_number &&
                            formik.errors.mobile_number
                          }
                          helperText={
                            formik.touched.mobile_number &&
                            formik.errors.mobile_number ? (
                              <div className="err-msg-mobile err-msg-font">
                                {formik.errors.mobile_number}
                              </div>
                            ) : null
                          }
                          InputLabelProps={{ sx: { color: "black" } }}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <SelectBox
                        mt={1}
                        type="text"
                        id="gender"
                        name="gender"
                        label="Gender"
                        ClassName="range-select-filter"
                        value={formik.values?.gender?.toLowerCase()}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        options={genderOptions}
                        error={
                          formik.touched.gender && Boolean(formik.errors.gender)
                        }
                        touched={formik.touched.gender}
                      />
                      {formik.touched.gender && formik.errors.gender && (
                        <div className="err-msg-font">
                          {formik.errors.gender}
                        </div>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker", "DatePicker"]}
                        >
                          <DatePicker
                            id="birth_date"
                            sx={{
                              width: "100%",
                              border: "none !important",
                              // height:"40px !important",
                              "& .MuiOutlinedInput-input":
                                {
                                  padding: "10.3px 14px !important",
                                },
                                "& .MuiInputLabel-root":
                                {
                                  lineHeight: "1em !important",
                                  fontWeight: "600",
                                },
                            }}
                            name="birth_date"
                            fullWidth
                            label="Select Date of Birth"
                            value={
                              formik.values.birth_date
                                ? dayjs(new Date(formik.values.birth_date))
                                : null
                            }
                            format="DD/MM/YYYY"
                            views={["year", "month", "day"]}
                            openTo="year"
                            onChange={(newValue) =>
                              formik.setFieldValue("birth_date", newValue)
                            }
                            initialFocusedDate={new Date("2000-01-01")}
                            slotProps={{
                              textField: {
                                variant: "outlined",
                                error: Boolean(
                                  formik.touched.birth_date &&
                                    formik.errors.birth_date
                                ), //error handling
                                helperText: formik.touched.birth_date &&
                                  formik.errors.birth_date && (
                                    <div
                                      style={{ marginLeft: "-12px" }}
                                      className="err-msg-font"
                                    >
                                      {formik.errors.birth_date}
                                    </div>
                                  ),
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="address"
                        name="address"
                        type="text"
                        label="Address"
                        onChange={formik.handleChange}
                        value={formik.values.address}
                        onBlur={formik.handleBlur}
                        maxLength={500}
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                        helperText={
                          formik.touched.address && formik.errors.address ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.address}
                            </div>
                          ) : null
                        }
                        maxRows={2}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <Autocomplete
                          id="country"
                          name="country"
                          value={formik.values.country}
                          // classes={customStyles}
                          onChange={(e, value) => {
                            formik.setFieldValue("country", value);
                            dispatch(GetAllStates(value?._id, true));
                            dispatch(cleareAllStates());
                            formik.setFieldValue("state", "");
                            formik.setFieldValue("city", "");
                            formik.setFieldValue("pincode", "");
                          }}
                          options={allCountryData?.countryData || []}
                          autoHighlight
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.country &&
                            Boolean(formik.errors.country)
                          }
                          helperText={
                            formik.touched.country && formik.errors.country ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.country}
                              </div>
                            ) : null
                          }
                          getOptionLabel={(option) => option.country_name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="Country"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                            />
                          )}
                        />
                        {formik.touched.country && formik.errors.country && (
                          <div className="err-msg-font">
                            {formik.errors.country}
                          </div>
                        )}
                        {/* {formik.errors.country && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: "12px" }}
                          >
                            {formik.errors.country}
                          </Typography>
                        )} */}
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <Autocomplete
                          id="state"
                          name="state"
                          value={formik.values.state || null}
                          onChange={(e, value) => {
                            formik.setFieldValue("state", value);
                            dispatch(GetAllCities(value?._id, true));
                            dispatch(cleareAllCities());
                            formik.setFieldValue("city", "");
                            formik.setFieldValue("pincode", "");
                          }}
                          options={allStateData || []}
                          autoHighlight
                          getOptionLabel={(option) => option.state_name}
                          loading={loading}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="State"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                            />
                          )}
                        />
                        {formik.touched.state && formik.errors.state && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: "12px" }}
                          >
                            {formik.errors.state}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <Autocomplete
                          id="city"
                          name="city"
                          value={formik.values.city || null}
                          onChange={(e, value) => {
                            formik.setFieldValue("city", value);
                            dispatch(GetAllPostalCodes(value?._id, true));
                            dispatch(clearePostalCodes());
                            formik.setFieldValue("pincode", "");
                          }}
                          options={allCitiesData || []}
                          autoHighlight
                          getOptionLabel={(option) => option.city_name}
                          loading={loading}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="City"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                            />
                          )}
                        />
                        {formik.touched.city && formik.errors.city && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: "12px" }}
                          >
                            {formik.errors.city}
                          </Typography>
                        )}
                      </div>
                    </Grid>

                    <Grid item md={6} xs={12} sm={12}>
                      <div style={{ marginTop: "1rem" }}>
                        <Autocomplete
                          id="pincode"
                          name="Postal code"
                          value={formik.values.pincode || null}
                          onChange={(e, value) =>
                            formik.setFieldValue("pincode", value)
                          }
                          options={allPostalData || []}
                          autoHighlight
                          getOptionLabel={(option) => option.postal_code}
                          loading={loading}
                          renderInput={(params) => (
                            <TextField
                              sx={{ color: "#000" }}
                              {...params}
                              label="Postal code"
                              inputProps={{
                                ...params.inputProps,
                                style: { color: "#000" },
                              }}
                            />
                          )}
                        />
                        {formik.touched.pincode && formik.errors.pincode && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: "12px" }}
                          >
                            {formik.errors.pincode}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <SelectBox
                        id="chapter"
                        name="chapter"
                        label="Chapter"
                        ClassName="range-select-filter"
                        value={formik.values.chapter}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        options={allChapterData || []}
                        error={
                          formik.touched.chapter &&
                          Boolean(formik.errors.chapter)
                        }
                        touched={formik.touched.chapter}
                      />
                      {formik.touched.chapter && formik.errors.chapter && (
                        <div className="err-msg-font">
                          {formik.errors.chapter}
                        </div>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={location.state.formName === "Create User" ? 12 : 6}
                      xs={12}
                      sm={12}
                    >
                      <SelectBox
                        multiple
                        id="role"
                        name="role"
                        label="Role"
                        ClassName="range-select-filter"
                        value={formik.values.role}
                        onChange={(e) => {
                          const selectedOptions = e.target.value.filter(Boolean);
                          if (selectedOptions.length == 1) {
                            const rolename = allRolesData.find(
                              (data) => data.value == selectedOptions[0]
                            ).label;
                            if (rolename === "Zonal Chapter Director") {
                              setRoleId(true);
                              setReferredActive(false);
                            } else {
                              setReferredActive(true);
                              setRoleId(false);
                            }
                          } else if(selectedOptions.length > 1) {
                            setRoleId(false);
                            setReferredActive(true);
                          } else {
                            setRoleId(false);
                            setReferredActive(false);
                          }
                          getRefferals(e.target.value.filter(Boolean));
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        options={allRolesData || []}
                        error={
                          formik.touched.role && Boolean(formik.errors.role)
                        }
                        touched={formik.touched.role}
                      />
                      {formik.touched.role && formik.errors.role && (
                        <div className="err-msg-font">{formik.errors.role}</div>
                      )}
                    </Grid>
                    {location.state.formName !== "Create User" && (
                      <Grid item md={6} xs={12} sm={12}>
                        <SelectBox
                          id="is_active"
                          name="is_active"
                          label="Is Active"
                          ClassName="range-select-filter"
                          value={formik.values.is_active}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          options={isActiveOptions || []}
                          error={
                            formik.touched.is_active &&
                            Boolean(formik.errors.is_active)
                          }
                          touched={formik.touched.is_active}
                        />
                        {formik.touched.is_active &&
                          formik.errors.is_active && (
                            <div className="err-msg-font">
                              {formik.errors.is_active}
                            </div>
                          )}
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <SelectBox
                        id="referred_by"
                        name="referred_by"
                        disabled={!referredActive}
                        label="Referred by"
                        ClassName="range-select-filter"
                        value={formik.values.referred_by}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        options={referredData}
                        error={
                          referredActive &&
                          formik.touched.referred_by &&
                          Boolean(formik.errors.referred_by)
                        }
                        touched={referredActive && formik.touched.is_active}
                      />
                      {formik.touched.referred_by && formik.errors.referred_by && (
                        <div className="err-msg-font">{formik.errors.referred_by}</div>
                      )}
                    </Grid>
                  </Grid>

                  {location?.state?.formName !== "Update User" && (
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12} sm={12}>
                        <div style={{ marginTop: "3px" }}>
                          <FormControl
                            sx={{ marginTop: "10px", width: "100%" }}
                            variant="outlined"
                          >
                            <InputLabel
                              htmlFor="outlined-adornment-password"
                              sx={{
                                color: "#000",
                                bgcolor: "#fff",
                                lineHeight: "1",
                              }}
                            >
                              Password
                            </InputLabel>
                            <OutlinedInput
                              sx={{
                                "& .MuiInputBase-input": {
                                  padding: "12px 14px !important",
                                },

                                "& .MuiOutlinedInput-notchedOutline": {
                                  paddingTop: "10px!important",
                                  borderColor: "rgba(107, 114, 128, .5)",

                                  borderRadius: "7px!important",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "black",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "blue",
                                  },
                                paddingTop: "0px",
                                color: "#000",
                              }}
                              id="password"
                              name="password"
                              type={showPassword ? "text" : "password"}
                              label="Password"
                              onChange={formik.handleChange}
                              value={formik.values.password}
                              onBlur={formik.handleBlur}
                              inputProps={{ maxLength: 25 }}
                              error={
                                formik.touched.password &&
                                Boolean(formik.errors.password)
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    sx={{ color: "#000" }}
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            {Boolean(formik.errors.password) ? (
                              <p
                                style={{
                                  color: "rgb(255 0 0)",
                                  marginTop: "4px",
                                  marginBottom: "4px",
                                  fontSize: "14px",
                                }}
                              >
                                {formik.errors.password}
                              </p>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        </div>
                      </Grid>

                      <Grid item md={6} xs={12} sm={12}>
                        <div style={{ marginTop: "3px" }}>
                          <FormControl
                            sx={{ marginTop: "10px", width: "100%" }}
                            variant="outlined"
                          >
                            <InputLabel
                              htmlFor="outlined-adornment-password"
                              sx={{
                                color: "#000",
                                bgcolor: "#fff",
                                lineHeight: "1",
                              }}
                            >
                              Confirm Password
                            </InputLabel>
                            <OutlinedInput
                              sx={{
                                "& .MuiInputBase-input": {
                                  padding: "12px 14px !important",
                                },

                                "& .MuiOutlinedInput-notchedOutline": {
                                  paddingTop: "10px!important",
                                  borderColor: "rgba(107, 114, 128, .5)",

                                  borderRadius: "7px!important",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "black",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "blue",
                                  },
                                paddingTop: "0px",
                                color: "#000",
                              }}
                              id="confirm_password"
                              name="confirm_password"
                              type={showConfirmPassword ? "text" : "password"}
                              label="Confirm Password"
                              onChange={formik.handleChange}
                              value={formik.values.confirm_password}
                              onBlur={formik.handleBlur}
                              inputProps={{ maxLength: 25 }}
                              error={
                                formik.touched.confirm_password &&
                                Boolean(formik.errors.confirm_password)
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    sx={{ color: "#000" }}
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            {Boolean(formik.errors.confirm_password) ? (
                              <p
                                style={{
                                  color: "rgb(255 0 0)",
                                  marginTop: "4px",
                                  marginBottom: "4px",
                                  fontSize: "14px",
                                }}
                              >
                                {formik.errors.confirm_password}
                              </p>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container spacing={2}>
                    <Grid item md={3} xs={12} sm={12}>
                      <input
                        type="file"
                        accept="image/*"
                        id="image-upload"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                      <label htmlFor="image-upload">
                        <Button
                          component="span"
                          variant="contained"
                          color="inherit"
                          sx={{ marginTop: "1rem" }}
                        >
                          Upload Image
                        </Button>
                      </label>
                      {/* {selectedImage ? null : (
                        <div className="err-msg-font">
                          Please select an image to upload.
                        </div>
                      )} */}
                    </Grid>
                    <Grid item md={9} xs={12} sm={12}>
                      {selectedImageError1 ? (
                        <div className="err-msg-font">
                          {selectedImageError1}
                        </div>
                      ) : (
                        selectedImage && selectedImage !== null && selectedImage !== "null" ? (
                          <Grid item md={12} xs={12} sm={12}>
                            <Typography
                              variant="subtitle1"
                              sx={{ marginTop: "1rem" }}
                            >
                              Selected Image:
                              <br />
                              <a
                                target="_blank"
                                href={
                                  typeof selectedImage !== "object" &&
                                  typeof profile_picture === "string"
                                    ? profile_picture
                                    : URL.createObjectURL(selectedImage)
                                }
                              >
                                <img
                                  // key={i}
                                  style={{ width: 80 }}
                                  src={
                                    typeof selectedImage !== "object" &&
                                    typeof profile_picture === "string"
                                      ? profile_picture
                                      : URL.createObjectURL(selectedImage)
                                  }
                                  // alt={`Selected Image ${i + 1}`}
                                  loading="lazy"
                                  // onError={(e) => {
                                  //   console.error(`Error loading image ${i + 1}:`, e);
                                  // }}
                                />
                              </a>
                            </Typography>
                          </Grid>
                        )
                        : null
                      )}
                    </Grid>
                  </Grid>

                  <Button
                    sx={{ marginTop: 4 }}
                    type="submit"
                    variant="contained"
                    disabled={createLoading || updateLoading}
                  >
                    {createLoading || updateLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                  <Button
                    sx={{
                      marginLeft: 2,
                      marginTop: 4,
                      backgroundColor: "lightgrey",
                      "&:hover": {
                        backgroundColor: "lightgrey",
                        color: "black",
                      },
                      color: "black",
                    }}
                    type="submit"
                    variant="contained"
                    onClick={() => navigate("/admin-user")}
                  >
                    Cancel
                  </Button>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateUserForm;
