import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

export const GetAllPostalCodes = (id, is_active) => async (dispatch) => {
  try {
    dispatch({
      type: "POSTAL_LOADING",
      payload: true,
    });

    let url = `${ApiURL}postalcode/code-by-cityid/${id}`;
    if (is_active) {
      url = `${ApiURL}postalcode/code-by-cityid/${id}?is_active=${is_active}`;
    }

    const res = await axios.get(`${ApiURL}postalcode/code-by-cityid/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "POSTAL_LOADING",
        payload: false,
      });
      dispatch({
        type: "GET_ALL_POSTAL_SUCCESS",
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: "POSTAL_LOADING",
      payload: false,
    });
  }
};

export const clearePostalCodes = () => async (dispatch) => {
  dispatch({
    type: "GET_ALL_POSTAL_SUCCESS",
    payload: [],
  });
};

export const GetPostalCode =
  (page, postal_code = "", rowsPerPage) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "POSTAL_LOADING",
        payload: true,
      });

      const res = await axios.get(
        `${ApiURL}postalcode/all-codes?limit=${rowsPerPage}&page=${page}&postal_code=${postal_code}`
      );

      if (res.data.status === true) {
        dispatch({
          type: "POSTAL_LOADING",
          payload: false,
        });

        const responseData =
          res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "POSTAL_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "POSTAL_LOADING",
          payload: false,
        });
        dispatch({
          type: "POSTAL_SUCCESS",
          payload: [],
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "POSTAL_LOADING",
        payload: false,
      });
    }
  };

export const CreatePostal = (data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_POSTAL_LOADING",
      payload: true,
    });
    const res = await axios.post(`${ApiURL}postalcode/create-code`, data);

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_POSTAL_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "CREATE_POSTAL_LOADING",
      payload: false,
    });
  }
};

export const UpdatePostal = (id, data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_POSTAL_LOADING",
      payload: true,
    });
    const res = await axios.put(`${ApiURL}postalcode/update-code/${id}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_POSTAL_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_POSTAL_LOADING",
      payload: false,
    });
  }
};

export const DeletePostal = (id, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_POSTAL_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}postalcode/delete-code/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_POSTAL_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      handleClose();
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "DELETE_POSTAL_LOADING",
      payload: false,
    });
  }
};
