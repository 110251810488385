import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable.js";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";

import {
  CreateChapter,
  DeleteChapter,
  GetAllChapter,
  UpdateChapter,
  getChapterAll,
} from "../../redux/action/ChapterAction";
import { useSnackbar } from "notistack";
import DeleteDialog from "../dialog/deletedialog";
import { useNavigate } from "react-router-dom";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import {
  CreateBanner,
  DeleteBanner,
  GetAllBanner,
  GetAllBannerType,
  UpdateBanner,
} from "../../redux/action/BannerAction";
import ShowFirstImage from "../showFirstImage/ShowFirstImage";
import Loader from "../loader/Loader";
import useUserRolePermissions from "../../common/UserPermissions";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const initialValues = {
  banner_images: "",
  banner_type_id: "",
  chapter_id: "All",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
];

const BannerList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editImageValue, setEditImageValue] = useState();
  const role = useUserRolePermissions();

  const bannerData = useSelector(
    (state) => state?.BannerReducer?.banner?.bannerData
  );
  const bannerTypeData = useSelector(
    (state) => state?.BannerReducer?.bannerType
  );
  const chapterData = useSelector(
    (state) => state.ChapterReducer?.chapter?.chapterData
  );

  const { loading, banner, createLoading, updateLoading, deleteLoading } =
    useSelector((state) => state.BannerReducer);

  useEffect(() => {
    dispatch(GetAllBannerType());
    // dispatch(getChapterAll());
    dispatch(GetAllChapter("", "", "", "", "",true));
  }, []);

  const columns = [
    {
      id: "banner_image",
      numeric: true,
      disablePadding: false,
      label: "Banner Image",
    },

    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Type",
    },

    {
      id: "banner_type",
      numeric: false,
      disablePadding: true,
      label: "Banner type",
    },

    {
      id: "chapter_name",
      numeric: false,
      disablePadding: true,
      label: "Chapter Name",
    },

    !!getPermissions(permissions.banner, "is_edit") ||
    !!getPermissions(permissions.banner, "is_delete")
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1)
    dispatch(GetAllBanner(page, rowPerPage));
  };

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const tableTitle = "SubscriptionList";

  useEffect(() => {
    if (!open) {
      dispatch(GetAllBanner(page, rowsPerPage));
    }
  }, [page, open, dispatch]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPopupName("Create Banner");
    setOpen(true);
    setEditImageValue()
    setValues(initialValues);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({})
    setValues(initialValues);
    // resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let isImage = true;

    let fileTypeArray =
      fieldValues.banner_images && fieldValues.banner_images.split(".");
    let fileType = fileTypeArray && fileTypeArray[fileTypeArray.length - 1];
    if (fileTypeArray && fileTypeArray.length >= 1) {
      fileType = fileTypeArray && fileTypeArray[fileTypeArray.length - 1];
    }

    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
    ];
    if (popupName === "Edit Banner") {
      if ("banner_type_id" in fieldValues) {
        if (
          fieldValues.banner_type_id === "" ||
          fieldValues.banner_type_id === undefined ||
          fieldValues.banner_type_id === null ||
          fieldValues.banner_type_id?.trim() === ""
        ) {
          temp.banner_type_id = "Please enter banner type";
        } else {
          temp.banner_type_id = "";
        }
      }
      if ("banner_images" in fieldValues) {
        if (fileType && !allowedTypes.includes(fileType)) {
          temp.banner_images = "Please select valid image file";
        } else {
          temp.banner_images = "";
        }
      }
    } else {
      if ("banner_images" in fieldValues) {
        if (
          fieldValues.banner_images === "" ||
          fieldValues.banner_images === undefined ||
          fieldValues.banner_images === null ||
          fieldValues.banner_images?.trim() === ""
        ) {
          temp.banner_images = "This field is required.";
        } else if (fileType && !allowedTypes.includes(fileType)) {
          temp.banner_images = "Please select valid image file";
        } else {
          temp.banner_images = "";
        }
      }

      if ("banner_type_id" in fieldValues) {
        if (
          fieldValues.banner_type_id === "" ||
          fieldValues.banner_type_id === undefined ||
          fieldValues.banner_type_id === null
        ) {
          temp.banner_type_id = "Please select banner type";
        } else {
          temp.banner_type_id = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setFieldValues,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  const handleEditClick = async (data) => {
    setSelectedBanner(data);
    setErrors({});
    setPopupName("Edit Banner");
    if (data?.banner_images) {
      setEditImageValue(data?.banner_images);
    }
    setValues({
      ...values,
      banner_type_id: data?.banner_type || "",
      chapter_id: data?.chapter_id || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeleteBanner(DeleteId, handleCloseDelete)).then((res) => {
      if (res && res.data.status === true) {
        dispatch(GetAllBanner(page, rowsPerPage));
      }
    });
  };

  const fields = [
    {
      id: "banner_type_id",
      label: "Banner type",
      type: "select",
      options:
        bannerTypeData &&
        bannerTypeData?.map((el) => {
          return { value: el._id, label: el.banner_type };
        }),
      value: values.banner_type_id,
      name: "banner_type_id",
      errors: errors.banner_type_id,
    },

    {
      id: "banner_images",
      type: "file",
      labelAccess: "banner_images",
      value: values.banner_images,
      name: "banner_images",
      errors: errors.banner_images,
      src: values.file ? values.file : editImageValue && editImageValue[0],
    },

    {
      id: "chapter_id",
      label: "Chapter Name",
      type: "select",
      defaultValue: "All",
      options: chapterData
        ? [
            { value: "All", label: "All" },
            ...chapterData?.map((el) => {
              return { value: el._id, label: el.chapter_name };
            }),
          ].filter(Boolean)
        : [{ value: "All", label: "All" }],
      value: values.chapter_id || "All",
      name: "chapter_id",
      errors: errors.chapter_id,
    },
  ];

  const rows =
    bannerData && Array.isArray(bannerData)
      ? bannerData?.map((data) => {
          const selected = data === selectedBanner;
          return {
            _id: data._id,
            banner_image: <ShowFirstImage images={data?.banner_images} />,
            type: "App" || "-",
            banner_type: data.banner_type_name || "-",
            chapter_name: data.chapter_name || "All",

            // status: data.is_active ? "approved" : 'pending',
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {!!getPermissions(permissions.banner, "is_edit") && (
                  <IconButton
                    onClick={() => handleEditClick(data)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {!!getPermissions(permissions.banner, "is_delete") && (
                  <IconButton
                    onClick={() => handleClickOpenDelete(data)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }
    const formData = new FormData();
    let imagesArray = [];
    if (values.file !== undefined) {
      if (values.file instanceof FileList) {
        imagesArray = Array.from(values.file);
      } else {
        imagesArray = Array.from([values.file]);
      }
    } else {
      imagesArray = Array.from(editImageValue);
    }

    imagesArray.forEach((image, index) => {
      formData.append(`banner_images`, image);
    });
    formData.append("banner_type", values.banner_type_id);
    if (values.chapter_id && values.chapter_id !== "All") {
      formData.append("chapter_id", values.chapter_id);
    }

    if (validate()) {
      if (popupName === "Edit Banner") {
        dispatch(UpdateBanner(selectedBanner._id, formData, handleClose));
      } else {
        dispatch(CreateBanner(formData, handleClose));
      }
    }

    setValues(initialValues);
  };

  return (
    <Box>
      <PageHeader
        title="Banner Mobile"
        onAddClick={handleClickOpen}
        isShowAdd={!!getPermissions(permissions.banner, "is_add")}
      />

      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          key={rows._id}
          rowsPerPage={rowsPerPage}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={bannerData}
        />
      )}
      <FormDialog
        open={open}
        dialogTitle={popupName}
        fields={
          popupName === "Edit Banner"
            ? fields.filter((el, index) =>
                ["chapter_id", "banner_type_id", "banner_images"].includes(
                  el.name
                )
              )
            : fields.filter((el) => !["status"].includes(el.name))
        }
        loading={popupName === "Edit Banner" ? updateLoading : createLoading}
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
        // isAnyFieldNotEmpty={isAnyFieldNotEmpty}
      />

      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {banner?.currentPage} of {banner?.totalPages} Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={banner?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default BannerList;
