import React, { useEffect, useState } from "react";
import {
    TextField,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Box,
    Autocomplete,
    Input,
    TextareaAutosize,
} from "@mui/material";
import { CloseIcon } from "../../assets/icons/Icons";
import { makeStyles } from "@material-ui/core/styles";
import MyAutocomplete from "../../common/myAutoComplete";
import { useFormik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
    customCircularProgress: {
        width: '25px !important',
        height: '25px !important',
        color: '#fff !important',
    },
}));

const styles = {
    root: {
        borderBottom: '1px solid black',
    },
    icon: {
        color: 'black',
        fill: 'black',
    },
};

const customStyles = {
    option: {
        color: 'red', // Set the text color to red
    },
};

const schemaValidation = Yup.object().shape({
    note: Yup.string().trim().required("Please enter note"),
});

const NoteDialog = ({
    dialogTitle,
    dialogText,
    fields,
    submitText,
    open,
    noteData,
    handleClose,
    handleSubmit,
    onChange,
    loading,
    fullWidth = false
    // isAnyFieldNotEmpty,
}) => {
    const classes = styles;
    const useClasses = useStyles();

    const formik = useFormik({
        initialValues: {
            note: noteData || "",
        },
        validationSchema: schemaValidation,
        onSubmit: (values, { resetForm }) => {

            const body = {
                ...values,
            };
            handleSubmit(body)
        }
    });


    return (
        <Dialog open={open}
            fullWidth={fullWidth}
            onClose={false}
            disableBackdropClick={true} // Prevent closing when clicking outside
            disableEscapeKeyDown={true}>
            <div className="mui-dialog-box-popup" style={{ backgroundColor: '#fff', color: '#000' }}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{ color: '#000 !important' }}>{dialogTitle}</Typography>

                    <IconButton sx={{
                        color: "black", "&:hover": {
                            backgroundColor: "white",
                            color: "#153e80"
                        }, "&:focus": {
                            backgroundColor: "white",
                            color: "#153e80",
                            border: "none"
                        }
                    }}>
                        <CloseIcon onClick={handleClose} />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ minWidth: '400px' }}>
                    <DialogContentText>{dialogText}</DialogContentText>

                    <form onSubmit={formik.handleSubmit}>
                        <div style={{ marginTop: '5px' }}>
                            <TextField
                                id="note"
                                name="note"
                                variant="outlined"
                                type="text"
                                label="Note"
                                onChange={formik.handleChange}
                                value={formik.values.note}
                                onBlur={formik.handleBlur}
                                error={formik.touched.note && Boolean(formik.errors.note)}
                                helperText={formik.touched.note && <div style={{ marginLeft: '-12px', fontSize: '12px' }}>{formik.errors.note}</div>}
                                fullWidth
                                multiline
                                rows={5} // Adjust the number of rows as needed
                            />
                        </div>
                        <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
                            <Button
                                type='submit'
                                // disabled={!isAnyFieldNotEmpty}
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    border: "0",
                                    ":hover": { bgcolor: "#1976d2", border: "0" },
                                    backgroundColor: "#153e80",
                                    marginLeft: {
                                        xs: "0px",
                                        sm: "15px",
                                        md: "15px",
                                        lg: "15px",
                                    },
                                    color: '#fff',
                                    textTransform: "none",
                                    minWidth: '100px'
                                }}
                            >
                                {loading ? <CircularProgress className={useClasses.customCircularProgress} /> : submitText}
                            </Button>
                        </DialogActions>
                    </form>

                </DialogContent>

            </div>
        </Dialog>
    );
};

export default NoteDialog;
