import React, { useEffect, useRef, useState } from "react";
import { Pagination } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { GetAllEvent } from "../../../redux/eventAction";
import EventCard from "../../eventCard/EventCard";
import Loader from "../../loader/Loader";
import NotDataFound from "../../../pages/notFound/NotDataFound";
import { Head } from "../../../pages/commonDesign/Head";
import { Deals } from "../../Deals/Deals";
import Slider from "react-slick";

import arrow from '../../../GGC_LendingPage/svg/arrowLeft.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { GetEvent } from "../../../../redux/action/EventAction";

const useStyles = makeStyles((theme) => ({
  pagination: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
      padding: 0,
      margin: 0,
    },
    "& .MuiPaginationItem-root": {
      margin: "0 2px",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#153e80",
      border: "none",
      "&:hover": {
        color: "#153e80",
      },
    },
    "& .MuiPaginationItem-page:hover": {
      color: "#153e80",
    },
  },
}));


const EventDisplay = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [allGetEvent, setAllGetEvent] = useState({});
  const sliderRef = useRef();
//   const [currentIndex, setCurrentIndex] = useState(0);
  const currentPage = 1;
  const cardPerPage = 4;
  const { eventData } = useSelector((state) => state?.EventReducer);
  const dispatch = useDispatch();
  console.log("Event Data", eventData);

//   const getEvent = async () => {
//     try {
//       const res = await GetAllEvent(4, currentPage, "");
//       if (res.status) {
//         const data = res?.data;
//         if (data && Array.isArray(data.eventData)) {
//           setAllGetEvent(data);
//         console.log(data);
//         } else {
//           setError(true);
//         }
//       } else {
//         setError(true);
//       }
//     } catch (err) {
//       setError(true);
//     } finally {
//       setLoading(false);
//     }
//   };
 
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll:1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
    // afterChange: (index) => {
    //   setCurrentIndex(index);
    // },
  };

  useEffect(() => {
    const fetchEvent = async() => {
        try {
            await dispatch(GetEvent(currentPage, "", cardPerPage));
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false);
        }
    };
    fetchEvent();
    // getEvent();
  }, [currentPage, dispatch]);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <NotDataFound />;
  }

//   const handleCurrentPageChange = (_, val) => {
//     setCurrentPage(val);
//   };
  // Ensure we are using the correct totalPages
  const totalPages = allGetEvent?.totalPages || 1;

  return (
    <>
      <div style={{position: 'relative'}}>
        <div className="responsive">
          <Deals title="Events"  />
          
        <div style={{ marginTop: "15px", width: "100%" }}>
          <Slider {...settings} >
            {eventData?.eventData?.map((item) => (
                  <EventCard item={item} />
                ))}
            </Slider>
          </div>

          {/* {totalPages > 1 && (
            <Pagination
              count={totalPages}
              className={classes.pagination}
              shape="rounded"
              page={currentPage}
              onChange={handleCurrentPageChange}
            />
          )} */}
        </div>
      </div>
    </>
  );
};

export default EventDisplay;
