import React from "react";
import Faqs from "../../components/Faqs/Faqs";
import { Link } from "react-router-dom";
import nextArrow from "../../assets/GG_C_Events/whtRightArw.svg";
import faqBanner from "../../assets/GG_C_About/newFAQ.jpg";

const FaqPage = () => {
  return (
    <>
      <div id="site-wrapper" className="site-wrapper page-faqs py-3">
        {/* <div class="responsive" style={{ textAlign: "center" }}>
          <div class="h-100">
            <h1 style={{ fontSize: "40px", fontWeight: "bold" }}>
              Frequently Asked Questions
            </h1>
            <ul
              class="breadcrumb justify-content-center fadeInUp animated"
              data-animate="fadeInUp"
            >
              <li class="breadcrumb-item">
                <Link
                  style={{
                    color: "#252525",
                    fontSize: "20px",
                    letterSpacing: "0.5px",
                    textDecoration: "none",
                  }}
                  to="/"
                >
                  Home{" "}
                </Link>
              </li>
              <li
                class="breadcrumb-item"
                style={{
                  color: "#252525",
                  fontSize: "20px",
                  letterSpacing: "0.5px",
                }}
              >
                <span>FAQs</span>
              </li>
            </ul>
          </div>
        </div> */}

        <div className="w-full relative ha">
          <div className="w-full opacity-7" style={{ height: "280px" }}>
            <img
              src={faqBanner}
              alt="Banner"
              className="h-full w-full object-cover"
            />
          </div>

          <div className="h-full bg-000 opacity-5 w-full absolute t-0"></div>

          <div className="mb-2 BannerData">
            <h1 className="text-36 w-full font-weight-bold text-fff">
              Frequently Asked Questions
            </h1>

            <Link to="/" className="headLink font-weight-bold text-17">
              Home
            </Link>
            <span className="text-fff font-weight-bold text-17 mleft-1">
              {" "}
              <img src={nextArrow} />
            </span>
            <span className="text-fff mleft-1 font-weight-bold text-17">
              FAQs
            </span>
          </div>
        </div>

        {/* <div id="wrapper-content" className="wrapper-content py-11"> */}
        <div className="responsive" style={{ margin: "15px auto" }}>
          {/* <div id="section-accordion" className="faqs mb-11">
              <div className="container"> */}
          <div className="col-md-12 w-9 ma-0">
            <Faqs />
          </div>
          {/* </div>
            </div> */}

          {/*Join Us*/}
          <div class="text-center pb-6">
            <div class="mb-6">
              <h4 class="mb-1 mt-8">
                Join Us Today and Get More Profit From Your Business
              </h4>
              <p class="mb-0 font-size-md">
                The easiest way to get more interest in your place
              </p>
            </div>
            <Link
              to="/be-partner"
              class="font-weight-bold px-5 py-2 btnAll"
              style={{ fontSize: "21px", textDecoration: "none" }}
            >
              Get Started
            </Link>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default FaqPage;
