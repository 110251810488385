import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { base_Url } from "../../utils/common";
import Loader from "../../components/loader/Loader";
import moment from "moment";
import { DisplayHTMLString } from "../../components/htmlToString/HtmlToString";
import { api } from "../../config/api";
import Slider from "react-slick";

const BlogDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const [loading, setLoading] = useState(false);
  const [getBlogDetails, setBlogDetails] = useState([]);
  useEffect(() => {
    const getBlogDetailsById = async () => {
      setLoading(true);
      try {
        if (token) {
          await api
            .get(`${base_Url}blog/blog-by-id/${id}`)
            .then((res) => {
              if (res.status) {
                setBlogDetails(res.data);
                setLoading(false);
              }
            })
            .catch((err) => navigate("/blog"));
        } else {
          await api
            .get(`${base_Url}blog/blog-by-id/${id}`)
            .then((res) => {
              if (res.status) {
                setBlogDetails(res.data);
                setLoading(false);
              }
            })
            .catch((err) => navigate("/blog"));
        }
      } catch (error) {
        navigate("/blog");
        setLoading(false);
      }
    };
    getBlogDetailsById();
  }, []);
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    className: "hero-banner-arrow",
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: "0%",
        },
      },
    ],
    centerMode: true,
    centerPadding: "20%",
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <div id="wrapper-content1" className="wrapper-content">
      {/* <div className="container">
        <div className="page-container"> */}
      <div className="responsive">
        <div>
          <h2 className="font-weight-bold text-center">
            {getBlogDetails[0]?.title}
          </h2>
          <ul className="list-inline d-flex align-items-center justify-content-center flex-wrap pb-5">
            <li className="list-inline-item mr-1">
              <span className="text-gray">
                {moment(getBlogDetails[0]?.createdAt).format("MMMM Do YYYY")} by
              </span>
            </li>
            <li className="list-inline-item">
              <a className="">
                {getBlogDetails[0]?.user_name}
              </a>
            </li>
            <li className="list-inline-item">
              <span>/</span>
            </li>
            <li className="list-inline-item">
              <a className="d-inline-block mr-1">
                {getBlogDetails[0]?.blog_category_name}
              </a>
            </li>
          </ul>
        </div>

        <div className="w-full radius-1">
          {getBlogDetails[0]?.blog_images.map((link, index) => {
            // Check media type based on the link (assuming link includes file extensions)
            const fileType = link.substring(link.lastIndexOf(".") + 1);
            const isImage = fileType.match(
              /(JPEG|JPG|PNG|jpeg|jpg|png|svg|webp|avi|avif|avchd|flv|f4v|swf|mpeg-2)/i
            );

            // Render image or video based on media type
            return (
              <div
                key={index}
                className="detailImgContainer"
              >
                {isImage ? (
                  <img
                    className="h-full w-full radius-1" // objectFit: 'contain'
                    src={link}
                    alt={`Image ${index}`}
                  />
                ) : (
                  <video
                    style={{ height: "568.188px", width: "100%" }}
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    <source src={link} type={`video/${fileType}`} />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            );
          })}
        </div>

        <div className="mb-6 d-flex align-items-center justify-content-center flex-wrap">
          {/* <img
                src={`${getBlogDetails[0]?.blog_images[0]}`}
                alt="Single blog"
              /> */}
        </div>

        <div className="w-full">
          {DisplayHTMLString(getBlogDetails[0]?.description)}
          <div className="flex justify-center">
            <span
              style={{
                width: "250px",
                borderBottom: "1px solid rgb(155 155 155)",
                marginBottom: "20px",
                marginTop: "30px",
              }}
            ></span>
          </div>
        </div>
      </div>
      {/* </div>
      </div> */}
    </div>
  );
};

export default BlogDetails;
