import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

export const GetPartnerRequest =
  (
    page,
    email = "",
    status = "",
    start_date = "",
    end_date = "",
    rowsPerPage,
    postal_code,
    country_id,
    state_id,
    city_id,
    name,
    mobile_number,
    role_id
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "LOADING",
        payload: true,
      });

      const res = await axios.get(
        `${ApiURL}partner-request/get-all-request?limit=${rowsPerPage}&page=${page}&email=${email}&status=${status}&start_date=${start_date}&end_date=${end_date}&postal_code=${postal_code}&country_id=${country_id}&state_id=${state_id}&city_id=${city_id}&name=${name}&mobile_number=${mobile_number}&role_id=${role_id}`
      );

      if (res.data.status === true) {
        dispatch({
          type: "LOADING",
          payload: false,
        });
        const responseData =
          res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "PARTNER_REQUEST_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "LOADING",
          payload: false,
        });
        dispatch({
          type: "PARTNER_REQUEST_SUCCESS",
          payload: [],
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
  };


export const CreatePartnerReq = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_LOADING_PARTNER_REQ",
      payload: true,
    });
    const res = await axios.post(`${ApiURL}partner-request/create-request`, data);

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_PARTNER_REQ_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status)
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status)
    console.error(err);
  } finally {
    dispatch({
      type: "CREATE_LOADING_PARTNER_REQ",
      payload: false,
    });
  }
};

export const UpdatePartnerReq = (id, data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_LOADING_PARTNER_REQ",
      payload: true,
    });
    const res = await axios.put(`${ApiURL}partner-request/status-update/${id}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_PARTNER_REQ_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status)
      handleClose()
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status)
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_LOADING_PARTNER_REQ",
      payload: false,
    });
  }
};

export const UpdatePartnerNote = (id, data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING_PARTNER_REQ_NOTE",
      payload: true,
    });
    const res = await axios.put(`${ApiURL}partner-request/update/${id}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_PARTNER_REQ_NOTE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status)
      handleClose()
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status)
    console.error(err);
  } finally {
    dispatch({
      type: "LOADING_PARTNER_REQ_NOTE",
      payload: false,
    });
  }
};


export const DeletePartnerReq = (id, handleCloseDelete) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_PARTNER_REQ_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}partner-request/delete/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_PARTNER_REQ_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status)
      handleCloseDelete()
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status)
    console.error(err);
  } finally {
    dispatch({
      type: "DELETE_PARTNER_REQ_LOADING",
      payload: false,
    });
  }
};

//add payment details
export const addPayment = async (payload) => {
  try {
    const response = await axios.post(`${ApiURL}payment/register-payment`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//get paymentlist by id

export const getPaymentRequestId = async (id) => {
  try {
    const response = await axios.get(`${ApiURL}payment/payment-by-request-id/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};