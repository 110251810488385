import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import LogoHome from "../../../assets/images/logo-home.svg";
import { useNavigate } from "react-router-dom";

const pages = ["Home", "Blog", "About"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "10rem",
  },
  mainHead: {
    backgroundColor: "#306bcb !important",
    color: "#fff",
  },
  dropBtn: {
    color: "#fff !important",
    margin: "16px 0 !important",
  },

  [theme.breakpoints.down("xs")]: {
    // mainCompDetails: {
    //   display: "flex",
    //   flexGrow: 1,
    //   flexDirection: "column",
    //   alignItems: "center",
    // },
  },
}));

const UserHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCheckEligibility = () => {
    navigate("/be-member-stepper");
  };

  const handleMemberClick = () => {
    navigate("/be-member");
  };

  return (
    <AppBar position="static" className={classes.mainHead}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <img src={LogoHome} alt="GGC" className={classes.logo} />
          </Typography>

          <Box sx={{ justifyContent:"left", textAlign: "left", flexGrow: 1, display: { xs: "flex", md: "none" }, gap: "13px" }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "#fff !important" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Button sx={{textAlign:"left",justifyContent:"left !important"}}>{page}</Button>
                </MenuItem>
              ))}
              <MenuItem>
              <Button
                id="basic-button"
                // className={classes.dropBtn}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={() =>  navigate("/be-partner")}
              >
                Be a Partner
              </Button>
              </MenuItem>
              </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <img src={LogoHome} alt="GGC" className={classes.logo} />
          </Typography>
          <Box sx={{ width:"100%", flexGrow: 1, display: { xs: "none", md: "flex" }, gap: "13px" }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}

            <Button
              id="basic-button"
              className={classes.dropBtn}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleCheckEligibility}
            >
              Be a Member (stepper)
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>

            <Button
              id="basic-button"
              className={classes.dropBtn}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={() =>  navigate("/be-partner")}
            >
              Be a Partner
            </Button>
            <Button
              id="basic-button"
              className={classes.dropBtn}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={() =>  navigate("/be-member")}
            >
              Be a Member
            </Button>
          </Box>
          <Button
                id="basic-button"
                sx={{display:"flow",border:"1px solid #1f54a9"}}
                className={classes.dropBtn}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={() =>  navigate("/admin")}
              >
                Admin
              </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default UserHeader;
