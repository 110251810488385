import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReferral,
  getAllReferral,
  getMyReferralRequest,
} from "../../redux/action/ReferralAction";
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Pagination,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Input from "../../common/Input";
import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "../pageHeader/PageHeader";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EnhancedTable from "../table/EnhancedTable.js";
import Loader from "../loader/Loader";
import { getBusinessCategory } from "../../redux/action/BusinessCategoryAction";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../common/useForms";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import DeleteDialog from "../dialog/deletedialog";
import FormDialog from "../dialog/dialog";
import PropTypes from "prop-types";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import axios from "axios";
import { ApiURL, snackBar } from "../../redux/common";
import useUserRolePermissions from "../../common/UserPermissions";
import SelectBox from "../../common/SelectBox";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const initialValues = {
  amount: "",
};

const locationOption = [
  {
    label: "Within Chapter",
    value: 1,
  },
  {
    label: "City",
    value: 2,
  },
  {
    label: "State",
    value: 3,
  },
  {
    label: "Global",
    value: 4,
  },
];

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const initialFilterValues = {
  location: "",
};

const AdminMyRefferralList = () => {
  const userRoleData = useUserRolePermissions("Event");
  const { getMyReferralLoading, allMyReferral } = useSelector(
    (state) => state.ReferralReducer
  );
  const dispatch = useDispatch();
  const nav = useNavigate();
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false);
  const [DeleteId, SetDeleteId] = useState(null);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectUserID, setSelectUserID] = useState();
  const [loading, setLoading] = useState(false);
  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const [refferalId, setRefferalId] = useState(false);
  const [isFieldFilled, setIsFieldFilled] = useState()

  useEffect(() => {
    if (filter) {
      const anyFieldHasValue = Object.values(filterDatas).some(val => val !== "");
      setIsFieldFilled(anyFieldHasValue);
    }
  }, [filter, filterDatas])


  const isAddAllowed = getPermissions(permissions.referral, "is_add");

  useEffect(() => {
    dispatch(getMyReferralRequest(page, rowsPerPage, ""));
  }, [page, rowsPerPage]);

  const handleSelectUserID = (userId) => {
    setSelectUserID(userId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validate()) {
      let body = {};
      body = {
        user_id: selectUserID,
        amount: parseInt(values.amount),
      };
      try {
        await axios
          .put(`${ApiURL}referral/approve-referral/${refferalId}`, body)
          .then((res) => {
            if (res.data.status) {
              setLoading(false);
              setOpenConfirmModel(false);
              snackBar(res.data.message, res.data.status);
              dispatch(getMyReferralRequest(page, rowsPerPage));
            } else {
              setLoading(false);
              snackBar(res.data.message, res.data.status);
            }
          });
      } catch (error) {
        setLoading(false);
        snackBar(error, false);
      }
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("amount" in fieldValues) {
      if (
        fieldValues.amount === "" ||
        fieldValues.amount === undefined ||
        fieldValues.amount === null ||
        fieldValues.amount?.trim() === ""
      ) {
        temp.amount = "Please enter amount";
        setLoading(false);
      } else {
        temp.amount = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const columns = [
    {
      id: "business_category",
      numeric: false,
      disablePadding: true,
      label: "Business Category",
    },

    {
      id: "description",
      numeric: true,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "closing_date",
      numeric: true,
      disablePadding: false,
      label: "Closing Date",
    },
    // (isEditAllowed || isDeleteAllowed) ?
    !!getPermissions(permissions.referral, "is_delete") ||
    !!getPermissions(permissions.referral, "is_edit")
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const fields = [
    {
      id: "amount",
      label: "Amount",
      type: "number",
      value: values.amount,
      name: "amount",
      errors: errors.amount,
      maxLength:8
    },
  ];

  const tableTitle = "ReferralList";

  const handleBtnClick = () => {
    nav("/admin-my-referrals/create-referral", {
      state: { formName: "Create Referral", referralData: {} },
    });
  };
  const handleEditClick = (referral) => {
    nav(`/admin-my-referrals/update-referral`, {
      state: { formName: "Update Referral", referralData: referral },
    });
    setErrors({});
  };

  const handleClickOpenDelete = (refferal) => {
    setOpenDelete(true);
    SetDeleteId(refferal._id);
  };

  const handleOpenConfirmModel = (refferal_Id) => {
    setOpenConfirmModel(true);
    setRefferalId(refferal_Id);
  };
  const handleCloseConfirmModel = () => {
    setOpenConfirmModel(false);
    setValues({amount:''})
    setErrors({});
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    handleCloseDelete();
    await dispatch(deleteReferral(DeleteId));
    dispatch(getMyReferralRequest(page, rowsPerPage));
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleChangeRow = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const location = filterDatas.location;
    setPage(1);
    dispatch(getMyReferralRequest(page, rowsPerPage, location));
    closeFilterDrawer1();
  };

  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;

    if (name === "postalcode" && !numericRegex.test(value)) {
      return;
    }

    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(getMyReferralRequest(page, rowsPerPage, ""));
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };

  const handleIconClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div>
      <Box sx={{ backgroundColor: "#f1f5f9" }}>
        <div style={{ paddingTop: "7px", paddingLeft: "18px" }}>
          <ArrowCircleLeftOutlinedIcon
            fontSize="large"
            sx={{ cursor: "pointer", backgroundColor: "#f1f5f9" }}
            onClick={() => nav("/admin-referral")}
          />
        </div>
        <PageHeader
          title="My Referrals List"
          isShowAdd={false}
          onBtnClick={handleBtnClick}
          btnText={isAddAllowed ? "Create Referral" : ""}
          onMyRefferalClick={() => nav("/admin-my-referrals")}
          // onAddClick={handleClickOpen}
          onFilterClick={openFilterDrawer}
          // href={downLoadData}
        />
      </Box>
      {getMyReferralLoading ? (
        <Loader />
      ) : (
        // <EnhancedTable
        //     key={5}
        //     columns={columns}
        //     rows={rows}
        //     order={order}
        //     orderBy={orderBy}
        //     selected={selected}
        //     onSelectAllClick={handleSelectAllClick}
        //     onRequestSort={handleRequestSort}
        //     title={tableTitle}
        //     tableList={allMyReferral && allMyReferral?.referralData}
        // />
        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell />
                {columns.map((item, i) => {
                  return (
                    <>
                      <TableCell  
                      sx={{ textAlign: "left !important", 
                            color: "black !important",
                            fontWeight: "bold !important",
                            width: `${item.label === 'Action' && '12% !important'}`,
                          }}
                          >{item.label}</TableCell>
                    </>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {allMyReferral?.referralData?.length > 0 ? (
                allMyReferral?.referralData?.map((row, index) => {
                  const closingDate = new Date(row.closing_date);
                  const formattedDate = closingDate.toISOString().split("T")[0];
                  return (
                    <React.Fragment>
                      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() =>
                              setOpen(
                                open && open == index + 1 ? false : index + 1
                              )
                            }
                          >
                            {open === index + 1 ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>{row.business_category}</TableCell>
                        <TableCell sx={{
                          wordWrap: "break-word",
                          wordBreak: "break-all",
                          width: '100%'}}>
                            {row.description}
                        </TableCell>
                        <TableCell>{formattedDate}</TableCell>
                        <TableCell>
                          {row.interested_candidates.length > 0 &&
                          row?.is_approved ? (
                            <label
                              style={{
                                border: "1.5px solid green",
                                padding: "10px",
                                borderRadius: "10px",
                                background: "lightgreen",
                              }}
                            >
                              Approved
                            </label>
                          ) : (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              {!!getPermissions(
                                permissions.referral,
                                "is_edit"
                              ) && (
                                <IconButton
                                  onClick={() => handleEditClick(row)}
                                  sx={{
                                    color: "black",
                                    "&:hover": {
                                      backgroundColor: "white",
                                      color: "#153e80",
                                    },
                                    "&:focus": {
                                      backgroundColor: "white",
                                      color: "#153e80",
                                      border: "none",
                                    },
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              )}
                              {!!getPermissions(
                                permissions.referral,
                                "is_delete"
                              ) && (
                                <IconButton
                                  onClick={() => handleClickOpenDelete(row)}
                                  sx={{
                                    color: "black",
                                    "&:hover": {
                                      backgroundColor: "white",
                                      color: "#153e80",
                                    },
                                    "&:focus": {
                                      backgroundColor: "white",
                                      color: "#153e80",
                                      border: "none",
                                    },
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={open === index + 1}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box
                              style={{
                                marginLeft: "3em",
                                borderRadius: "19px",
                                border: "1px solid lightgray",
                                padding: "13px",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <Typography
                                style={{ marginTop: "10px" }}
                                gutterBottom
                                component="div"
                              >
                                {row?.interested_candidates?.length > 0 ? (
                                  <b>Interested Candidates</b>
                                ) : null}
                              </Typography>
                              <Table size="small" aria-label="purchases">
                                {row?.interested_candidates?.length > 0 ? (
                                  <>
                                    <TableHead>
                                      <TableRow>
                                        {row?.is_approved ? (
                                          <>
                                            <TableCell>#</TableCell>
                                            <TableCell>
                                              Approved to User
                                            </TableCell>
                                            <TableCell>
                                              Approved by User
                                            </TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Description</TableCell>
                                          </>
                                        ) : (
                                          <>
                                            <TableCell>#</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Contact</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell align="left">
                                              Action
                                            </TableCell>
                                          </>
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {row?.is_approved ? (
                                        <TableRow>
                                          <TableCell component="th" scope="row">
                                            <label
                                              style={{
                                                border: "1.5px solid green",
                                                padding: "10px",
                                                borderRadius: "10px",
                                                background: "lightgreen",
                                              }}
                                            >
                                              Approved
                                            </label>
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                            {row.approved_to_user}
                                          </TableCell>
                                          <TableCell>
                                            {row.approved_by_user}
                                          </TableCell>
                                          <TableCell>{row.amount}</TableCell>
                                          <TableCell sx={{wordWrap: "break-word",
                                              wordBreak: "break-all",
                                              width: '100%'}}>
                                            {row.description}
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        row?.interested_candidates?.map(
                                          (interested_candidates, i) => (
                                            <TableRow key={i}>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <RadioGroup
                                                  id="location"
                                                  name="location"
                                                  row
                                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                                  onChange={(e, value) => {
                                                    handleSelectUserID(
                                                      e.target.value
                                                    );
                                                    setSelectUserID(
                                                      e.target.value
                                                    );
                                                  }}
                                                >
                                                  <FormControlLabel
                                                    value={
                                                      interested_candidates._id
                                                    }
                                                    control={
                                                      <Radio
                                                        checked={
                                                          selectUserID ===
                                                          interested_candidates?._id
                                                        }
                                                      />
                                                    }
                                                  />
                                                </RadioGroup>
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {
                                                  interested_candidates.user_name
                                                }
                                              </TableCell>
                                              <TableCell>
                                                {
                                                  interested_candidates.mobile_number
                                                }
                                              </TableCell>
                                              <TableCell>
                                                {interested_candidates.email}
                                              </TableCell>
                                              <TableCell>
                                                <IconButton
                                                  sx={{
                                                    color: "black",
                                                    "&:hover": {
                                                      backgroundColor: "white",
                                                      color: "#153e80",
                                                    },
                                                    "&:focus": {
                                                      backgroundColor: "white",
                                                      color: "#153e80",
                                                      border: "none",
                                                    },
                                                  }}
                                                  onClick={() =>
                                                    handleIconClick(
                                                      `mailto:${interested_candidates.email}`
                                                    )
                                                  }
                                                >
                                                  <EmailIcon />
                                                </IconButton>
                                                <IconButton
                                                  sx={{
                                                    color: "black",
                                                    "&:hover": {
                                                      backgroundColor: "white",
                                                      color: "#153e80",
                                                    },
                                                    "&:focus": {
                                                      backgroundColor: "white",
                                                      color: "#153e80",
                                                      border: "none",
                                                    },
                                                  }}
                                                  onClick={() =>
                                                    handleIconClick(
                                                      `call:${interested_candidates.mobile_number}`
                                                    )
                                                  }
                                                >
                                                  <LocalPhoneIcon />
                                                </IconButton>
                                                <IconButton
                                                  sx={{
                                                    color: "black",
                                                    "&:hover": {
                                                      backgroundColor: "white",
                                                      color: "#153e80",
                                                    },
                                                    "&:focus": {
                                                      backgroundColor: "white",
                                                      color: "#153e80",
                                                      border: "none",
                                                    },
                                                  }}
                                                  onClick={() =>
                                                    handleIconClick(
                                                      `https://wa.me/${interested_candidates.mobile_number}`
                                                    )
                                                  }
                                                >
                                                  <WhatsAppIcon />
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )
                                      )}
                                    </TableBody>
                                  </>
                                ) : (
                                  <TableRow>
                                    <TableCell
                                      style={{ fontSize: "15px" }}
                                      align="left"
                                      component="th"
                                      scope="row"
                                    >
                                      <b>No any interested candidates</b>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Table>
                              {row.is_approved == false &&
                              row?.interested_candidates?.length > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <Button
                                    disabled={selectUserID ? false : true}
                                    variant="contained"
                                    onClick={() =>
                                      handleOpenConfirmModel(row._id)
                                    }
                                  >
                                    Approve
                                  </Button>
                                </div>
                              ) : null}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })
              ) : (
                <>
                  <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    <TableCell colSpan={5} align="center">
                      Data not found
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <FormDialog
        open={openConfirmModel}
        // dialogTitle={dialogTitle}
        fields={fields.filter((el) => ["amount"].includes(el.name))}
        loading={loading}
        submitText="Confirm"
        handleClose={handleCloseConfirmModel}
        handleSubmit={handleSubmit}
        onChange={(e) => {
          const regex = /^[0-9]*$/;
          if (regex.test(e.target.value)) {
            handleInputChange(e);
          }
        }}
      />

      <DeleteDialog
        open={openDelete}
        // loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            Showing {allMyReferral?.currentPage} of {allMyReferral?.totalPages}{" "}
            Pages
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={allMyReferral && allMyReferral?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
            <Box className={classes.mainFilterBox}>
              <Stack className={classes.stackFilter}>
                <Typography className={classes.typographyFilter}>
                  Filter
                </Typography>

                <CloseIcon
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              </Box>

              <div>
                <Box>
                  <SelectBox
                    name="location"
                    label="Chapter"
                    // disabled={isAnyFieldFilled && !filterDatas.status}
                    ClassName="status-select-filter"
                    value={filterDatas.location}
                    onChange={handleChange}
                    options={locationOption}
                  />
                </Box>
                <Stack className={classes.buttonFilter}>
                  <Button
                    disabled={!isFieldFilled}
                    color="secondary"
                    size="large"
                    variant="contained"
                    className="cancel-filter"
                    onClick={resetFormFilter}
                  >
                    Reset
                  </Button>
                  <Button
                    disabled={!isFieldFilled}
                    color="primary"
                    size="large"
                    variant="contained"
                    className="order-filter-button"
                    type="submit"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Filter
                  </Button>
                </Stack>
              </div>
            </Box>
          </form>
        </Drawer>
      </Box>
    </div>
  );
};

export default AdminMyRefferralList;
